import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React, { useEffect } from "react";
import { Card } from "../../../components/Card";
import Loading from "../../../components/Loading";
import {
  DataRoomFileQuery,
  DataRoomFileUploadStatus,
  FileType,
  useDataRoomFileVersionQuery,
  useFileVersionDownloadUrlQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { Button } from "../../../components/tailwind/Button";
import { useSelector } from "react-redux";
import { dataRoomFileSelectors } from "../../../store/dataRoomFile/selector";
import { AppState } from "../../../store";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/20/solid";
import { PdfViewerDocumentId } from "../../../components/PdfViewer";
import { Spinner } from "../../../components/icons/Spinner";

interface FileViewProps {
  file: DataRoomFileQuery["dataRoomFile"];
  pageIndex?: number;
  rectsOnPage?: number[][];
}

export function FileView(props: FileViewProps) {
  const selectedVersionId = useSelector((state: AppState) =>
    dataRoomFileSelectors.selectedFileVersionId(state, props.file.id)
  );

  return (
    <Card margin="l 0 0 0">
      {selectedVersionId !== props.file.latestVersion.id ? (
        <div className="w-full h-30 bg-orange-100 rounded-t-md px-3 flex justify-center py-2">
          <p className="text-sm text-orange-700">
            You are viewing an old version of this document
          </p>
        </div>
      ) : null}
      {props.file.deletedAt ? (
        <div className="">
          <div className="w-full h-96 bg-red-100 rounded-md px-3 flex flex-col items-center justify-center py-2">
            <ArchiveBoxXMarkIcon className="h-6 w-6 text-red-700" />
            <p className="text-sm font-semibold mt-1 text-red-700">
              This file has been deleted
            </p>
          </div>
        </div>
      ) : (
        <div className="rounded-md p-0.5">
          <FileViewWrapper
            file={props.file}
            pageIndex={props.pageIndex}
            rectsOnPage={props.rectsOnPage}
          />
        </div>
      )}
    </Card>
  );
}

const FileViewContentMemo = React.memo(
  ({ file }: FileViewProps) => {
    return <FileViewContent file={file} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.file.id !== nextProps.file.id ||
      prevProps.file.uploadStatus !== nextProps.file.uploadStatus
    );
  }
);

function FileViewWrapper(props: FileViewProps) {
  if (
    props.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (
    props.file.fileType === FileType.Pdf ||
    props.file.fileType === FileType.Docx ||
    props.file.fileType === FileType.Pptx
  ) {
    return (
      <FileViewPspdfkit
        file={props.file}
        pageIndex={props.pageIndex}
        rectsOnPage={props.rectsOnPage}
      />
    );
  }

  return <FileViewContent file={props.file} />;
}

function FileViewPspdfkit(props: FileViewProps) {
  const client = useGqlClient();

  const selectedVersionId = useSelector((state: AppState) =>
    dataRoomFileSelectors.selectedFileVersionId(state, props.file.id)
  );

  const dataRoomFileVersion = useDataRoomFileVersionQuery(client, {
    id: selectedVersionId,
  });

  if (
    dataRoomFileVersion.error ||
    (dataRoomFileVersion.data &&
      !dataRoomFileVersion.data.dataRoomFileVersion.pspdfkitDocumentId)
  ) {
    return <FileViewContent file={props.file} />;
  }

  if (dataRoomFileVersion.isLoading || !dataRoomFileVersion.data) {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  const drfv = dataRoomFileVersion.data.dataRoomFileVersion;

  return (
    <div style={{ height: 800, width: "100%" }}>
      <PdfViewerDocumentId
        documentId={drfv.pspdfkitDocumentId}
        token={drfv.pspdfkitToken}
        fileType={props.file.fileType}
        pageIndex={props.pageIndex}
        rectsOnPage={props.rectsOnPage}
      />
    </div>
  );
}

function FileViewContent(props: FileViewProps) {
  const client = useGqlClient();
  const [url, setUrl] = React.useState<string>("");
  const [fileDownloadError, setFileDownloadError] = React.useState<string>("");
  const selectedVersionId = useSelector((state: AppState) =>
    dataRoomFileSelectors.selectedFileVersionId(state, props.file.id)
  );
  const fileDownloadUrl = useFileVersionDownloadUrlQuery(
    client,
    {
      id: selectedVersionId,
    },
    {
      refetchOnWindowFocus(query) {
        if (query.isStaleByTime(1000 * 60 * 5)) {
          return true;
        }

        return false;
      },
    }
  );

  useEffect(() => {
    if (
      fileDownloadUrl.data &&
      fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl !== url
    ) {
      setFileDownloadError("");
      setUrl(fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl);
      return;
    }

    if (fileDownloadUrl.error) {
      setFileDownloadError("Unable to load file");
      return;
    }
  }, [
    fileDownloadUrl.data,
    fileDownloadUrl.isRefetching,
    fileDownloadUrl.isPending,
    fileDownloadUrl.error,
    url,
  ]);

  if (
    props.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (url === "") {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  if (fileDownloadError) {
    return (
      <div className="flex items-center justify-center">
        <p>Failed to load file</p>
        <Button
          variant="neutral"
          text="Retry"
          isLoading={fileDownloadUrl.isPending || fileDownloadUrl.isRefetching}
          loadingText="Retrying..."
          onClick={() => {
            fileDownloadUrl.refetch();
          }}
        />
      </div>
    );
  }

  return (
    <DocViewer
      key={url}
      config={{
        pdfVerticalScrollByDefault: true,
        header: {
          disableFileName: true,
          disableHeader: true,
        },
      }}
      prefetchMethod="GET"
      style={{ height: 800, width: "100%", overflowY: "scroll" }}
      documents={[
        {
          uri: url,
        },
      ]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}
