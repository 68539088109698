import { useQueryClient } from "@tanstack/react-query";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import {
  DealRoleRestricted,
  buyerAdmins,
  buyerRoleOptions,
  dealRoleOptions,
  sellSideAdmins,
  sellerRoleOptions,
} from "../../components/DealRoleRestricted";
import { H4 } from "../../components/Heading";
import {
  AddPeopleFromFirmQuery,
  DealFirmGroupType,
  DealRole,
  DealRunner,
  FirmQuery,
  useAddPeopleFromFirmQuery,
  useCreateDealAccountsMutation,
  useFirmQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import Loading from "../../components/Loading";
import { useState } from "react";
import { Button } from "../../components/tailwind/Button";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";

export function AddPeopleFromFirm(props: {
  open: boolean;
  onClose: () => void;
  dealFirmGroupId: string;
  dealId: string;
}) {
  const client = useGqlClient();
  const addPeopleFromFirm = useAddPeopleFromFirmQuery(client, {
    dealId: props.dealId,
    dealFirmGroupId: props.dealFirmGroupId,
  });

  if (addPeopleFromFirm.error) {
    return null;
  }

  if (addPeopleFromFirm.isPending || !addPeopleFromFirm.data) {
    return null;
  }

  if (
    !addPeopleFromFirm.data.dealFirmGroup ||
    !addPeopleFromFirm.data.deal.activeDealAccount.dealFirm
  ) {
    return null;
  }

  return (
    <AnimatedModal size="lg" open={props.open} onClose={props.onClose}>
      <DealRoleRestricted
        sellerRunDealRoles={sellSideAdmins}
        buyerRunDealRoles={buyerAdmins}
      >
        <div>
          <div className="flex items-center justify-between">
            <H4>
              Add people from your firm to{" "}
              {addPeopleFromFirm.data.deal.company.name}
            </H4>
            <CloseIcon onClose={props.onClose} />
          </div>
          <AddPeopleFromFirmContent
            dealFirm={addPeopleFromFirm.data.deal.activeDealAccount.dealFirm}
            onClose={props.onClose}
            dealFirmGroup={addPeopleFromFirm.data.dealFirmGroup}
            activeDealAccount={addPeopleFromFirm.data.deal.activeDealAccount}
            dealId={props.dealId}
          />
        </div>
      </DealRoleRestricted>
    </AnimatedModal>
  );
}

interface AccountToAdd {
  id: string;
  role: DealRole;
}

function AddPeopleFromFirmContent(props: {
  activeDealAccount: AddPeopleFromFirmQuery["deal"]["activeDealAccount"];
  dealFirmGroup: AddPeopleFromFirmQuery["dealFirmGroup"];
  dealFirm: AddPeopleFromFirmQuery["deal"]["activeDealAccount"]["dealFirm"];
  onClose: () => void;
  dealId: string;
}) {
  const account = useSelector(authSelectors.account);
  const dealRunner = useSelector(authSelectors.activeDealRunner);
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const [accountsToAdd, setAccountsToAdd] = useState<AccountToAdd[]>([]);

  const createDealAccounts = useCreateDealAccountsMutation(client);

  const firmQuery = useFirmQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  });

  if (firmQuery.error) {
    return <div>Error</div>;
  }

  if (firmQuery.isPending || !firmQuery.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const availableAccounts = firmQuery.data.firm.accounts.filter((acc) => {
    if (!props.dealFirm) {
      return false;
    }

    return !props.dealFirm.dealAccounts.some(
      (dealAcc) => dealAcc.account.id === acc.id
    );
  });

  const roleOptions = getRoleOptions(
    dealRunner,
    props.activeDealAccount.role,
    props.dealFirmGroup.type
  );

  return (
    <div>
      <div className="mt-3">
        {availableAccounts.length === 0 ? (
          <p className="text-gray-500">No accounts available to add</p>
        ) : null}
        {availableAccounts.map((acc) => {
          return (
            <div key={acc.id} className="py-2">
              <Account
                roleOptions={roleOptions}
                account={acc}
                onToggle={(role, isChecked) => {
                  if (isChecked) {
                    setAccountsToAdd((prev) => {
                      return [...prev, { id: acc.id, role }];
                    });
                  } else {
                    setAccountsToAdd((prev) => {
                      return prev.filter((a) => a.id !== acc.id);
                    });
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-8 flex justify-end">
        <Button
          variant="positive"
          text="Add"
          isDisabled={accountsToAdd.length === 0}
          isLoading={createDealAccounts.isPending}
          loadingText="Adding..."
          onClick={() => {
            if (!props.dealFirm) {
              return;
            }

            createDealAccounts.mutate(
              {
                input: {
                  dealFirmId: props.dealFirm.id,
                  dealId: props.dealId,
                  accounts: accountsToAdd.map((a) => {
                    return {
                      accountId: a.id,
                      role: a.role,
                      ndaSigned: true,
                    };
                  }),
                },
              },
              {
                onSuccess: () => {
                  if (props.dealFirm) {
                    queryClient.invalidateQueries({
                      queryKey: ["DealFirm", { id: props.dealFirm.id }],
                    });
                  }
                  queryClient.invalidateQueries({
                    queryKey: ["DealFirmGroup", { id: props.dealFirmGroup.id }],
                  });
                  props.onClose();
                },
              }
            );
          }}
        />
      </div>
    </div>
  );
}

function Account(props: {
  roleOptions: Option[];
  account: FirmQuery["firm"]["accounts"][0];
  onToggle(role: DealRole, isChecked: boolean): void;
}) {
  const [selectedRole, setSelectedRole] = useState<Option>(
    props.roleOptions[0]
  );
  return (
    <div className="flex justify-between">
      <div className="relative flex items-start">
        <div className="flex h-6 items-start">
          <input
            id="account_to_add_checkbox"
            aria-describedby="comments-description"
            name="account_to_add_checkbox"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onChange={(e) => {
              props.onToggle(
                selectedRole.value as DealRole,
                e.currentTarget.checked
              );
            }}
          />
        </div>
        <div className="ml-3 text-sm leading-tight">
          <label
            htmlFor="account_to_add_checkbox"
            className="font-medium text-gray-900"
          >
            {props.account.name}
          </label>
          <p id="account_to_add_email" className="text-gray-500">
            {props.account.email}
          </p>
        </div>
      </div>
      <Dropdown
        hideSelectedOptionDescription
        selectedOption={selectedRole}
        options={props.roleOptions}
        onSelect={(e) => {
          setSelectedRole(e);
        }}
      />
    </div>
  );
}

export function getRoleOptions(
  runner: DealRunner,
  role: DealRole,
  firmGroupType: DealFirmGroupType
): Option[] {
  if (runner === DealRunner.Buyer) {
    if (firmGroupType === DealFirmGroupType.Seller) {
      return sellerRoleOptions;
    }

    if (role === DealRole.BuyerAdmin) {
      return buyerRoleOptions;
    }

    return [...dealRoleOptions, ...buyerRoleOptions];
  }

  if (firmGroupType === DealFirmGroupType.Buyer) {
    return buyerRoleOptions;
  }

  if (role === DealRole.SellerAdmin) {
    return sellerRoleOptions;
  }

  if (role === DealRole.DealAdmin || role == DealRole.DealOwner) {
    return [...dealRoleOptions, ...sellerRoleOptions];
  }

  return [];
}
