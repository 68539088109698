import { NavLink, useParams } from "react-router-dom";
import {
  DataRoomFile,
  DealThreadMessageContentFile,
  DealThreadMessageContentQuote,
  QuestionQuery,
  useCreateCommentMutation,
  useCreateQuestionAnswerMutation,
  useQuestionQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { SparklesIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { H3, H4 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { useEffect, useRef, useState } from "react";
import { AddFilePill } from "../../components/AddFilePill";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { FilePill } from "../../components/FilePill";
import { Avatar } from "../../components/account/Avatar";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../components/tailwind/Button";
import { Pills } from "../../components/Pills";
import { ActivityItem } from "../../components/activity/ActivityItem";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Markdown from "react-markdown";
import {
  adminRoles,
  allRoles,
  DealRoleRestricted,
  sellerRoles,
} from "../../components/DealRoleRestricted";

export function Question() {
  const { id } = useParams<{ id: string }>();

  const client = useGqlClient();
  const { data, isLoading, error } = useQuestionQuery(client, { id });

  return (
    <div className="flex-1 mb-8">
      <div className="bg-white sticky right-0 z-20 top-0 px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            {data && data.question.forDealFirmGroup ? (
              <NavLink
                to={`/questions/group/${
                  data ? data.question.forDealFirmGroup.id : ""
                }`}
              >
                <h4 className="font-bold text-gray-400 hover:text-gray-700">
                  {!data || isLoading
                    ? "Loading..."
                    : data.question.forDealFirmGroup.name}
                </h4>
              </NavLink>
            ) : (
              <NavLink
                to={`/questions/group/${
                  data ? data.question.dealFirmGroup.id : ""
                }`}
              >
                <h4 className="font-bold text-gray-400 hover:text-gray-700">
                  {!data || isLoading
                    ? "Loading..."
                    : data.question.dealFirmGroup.name}
                </h4>
              </NavLink>
            )}
            <p className="font-light text-gray-400">/</p>
            <H4>{data ? data.question.title : "..."}</H4>
          </div>
        </div>
      </div>
      <QuestionInner isLoading={isLoading} error={error} data={data} />
    </div>
  );
}

function QuestionInner(props: {
  isLoading: boolean;
  data?: QuestionQuery;
  error: any;
}) {
  if (props.error) {
    return (
      <div className="flex-1 justify-center items-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.data) {
    return (
      <div className="flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  return <QuestionContent question={props.data.question} />;
}

function QuestionContent(props: { question: QuestionQuery["question"] }) {
  const [answer, setAnswer] = useState("");
  const [answerFiles, setAnswerFiles] = useState<
    DealThreadMessageContentFile[]
  >([]);
  const [answerQuotes, setAnswerQuotes] = useState<
    DealThreadMessageContentQuote[]
  >([]);
  const [files, setFiles] = useState<DataRoomFile[]>([]);
  const account = useSelector(authSelectors.account);

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const createQuestionAnswer = useCreateQuestionAnswerMutation(client);

  if (!account) {
    return null;
  }

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <H3>Question</H3>
          <div className="flex space-x-2 mt-2">
            {props.question.createdBy ? (
              <div className="mt-2">
                <Avatar account={props.question.createdBy} />
              </div>
            ) : null}
            <div className="flex-1">
              <Card padding="m">
                <p className=" text-gray-600 font-semibold">
                  <Markdown>{props.question.title}</Markdown>
                </p>
                <p className="whitespace-pre-wrap text-sm text-gray-600 mt-2">
                  <Markdown>{props.question.body}</Markdown>
                </p>
                {props.question.dataRoomFile ? (
                  <Pills>
                    <FilePill
                      id={props.question.dataRoomFile.id}
                      name={props.question.dataRoomFile.name}
                      type={props.question.dataRoomFile.fileType}
                    />
                  </Pills>
                ) : null}
              </Card>
            </div>
          </div>
          <div className="mt-6">
            <H3>Answer</H3>
            <div className="mt-6 flex gap-x-3 ">
              {props.question.answer ? (
                <div className="flex space-x-2 w-full">
                  {props.question.answer.answeredBy ? (
                    <div className="mt-2">
                      <Avatar account={props.question.answer.answeredBy} />
                    </div>
                  ) : null}
                  <div className="flex-1 w-full">
                    <Card padding="s m">
                      <div className="flex items-center justify-between">
                        <p className="font-semibold text-gray-700 text-sm">
                          Answered by {props.question.answer.answeredBy.name}
                        </p>
                        <p className="text-xs text-gray-500/80">
                          {formatDistanceToNow(
                            fromUnixTime(props.question.answer.createdAt),
                            {
                              addSuffix: true,
                            }
                          )}
                        </p>
                      </div>
                      <p className="whitespace-pre-wrap text-sm text-gray-700 mt-2">
                        <Markdown>{props.question.answer.answer}</Markdown>
                      </p>
                      <Pills margin="l 0 0 0">
                        {props.question.answer.answerFiles.map((file) => {
                          return (
                            <div
                              key={file.file.id}
                              className="flex items-center"
                            >
                              <FilePill
                                id={file.file.id}
                                name={file.file.name}
                                type={file.file.fileType}
                                pageIndex={file.pageIndex ?? undefined}
                                rectsOnPage={file.rectsOnPage}
                                checkFileAccessAccountId={
                                  props.question.createdBy.id
                                }
                                noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                                onRemove={() => {
                                  setFiles(
                                    files.filter((f) => f.id !== file.file.id)
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </Pills>
                    </Card>
                  </div>
                </div>
              ) : (
                <div className="flex space-x-2 w-full">
                  <Avatar account={account} />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (!answer) {
                        return;
                      }

                      createQuestionAnswer.mutate(
                        {
                          questionId: props.question.id,
                          answer,
                          dataRoomFileIds: files.map((f) => f.id),
                          answerFiles: answerFiles.map((f) => {
                            return {
                              dataRoomFileID: f.file.id,
                              pageIndex: f.pageIndex,
                              rectsOnPage: f.rectsOnPage,
                            };
                          }),
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries({
                              queryKey: ["Question", { id: props.question.id }],
                            });
                          },
                        }
                      );
                    }}
                    className="relative flex-auto"
                  >
                    <div className="overflow-hidden rounded-lg pb-12 focus-within:bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
                      <label htmlFor="comment" className="sr-only">
                        Write your answer
                      </label>
                      <textarea
                        rows={3}
                        name="comment"
                        id="comment"
                        className="p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Write your answer..."
                        defaultValue={""}
                        value={answer}
                        onChange={(e) => {
                          setAnswer(e.currentTarget.value);
                        }}
                      />
                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                      <div className="flex items-center space-x-2">
                        {answerFiles.map((file) => {
                          return (
                            <div
                              key={file.file.id}
                              className="flex items-center"
                            >
                              <FilePill
                                id={file.file.id}
                                name={file.file.name}
                                type={file.file.fileType}
                                pageIndex={file.pageIndex ?? undefined}
                                rectsOnPage={file.rectsOnPage}
                                checkFileAccessAccountId={
                                  props.question.createdBy.id
                                }
                                noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                                onRemove={() => {
                                  setFiles(
                                    files.filter((f) => f.id !== file.file.id)
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                        <AddFilePill
                          onFileSelected={(file) => {
                            if (files.find((f) => f.id === file.id)) {
                              return;
                            }

                            setFiles([...files, file]);
                          }}
                        />
                      </div>
                      <Button
                        isLoading={createQuestionAnswer.isPending}
                        loadingText="Answering..."
                        size="s"
                        variant="neutral"
                        type="submit"
                        text="Answer"
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          <DealRoleRestricted
            sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
            buyerRunDealRoles={[...allRoles]}
          >
            <SuggestedAnswer
              question={props.question}
              onSelected={(answer, files, quotes) => {
                setAnswer(answer);
                setAnswerFiles(files);
                setAnswerQuotes(quotes);
              }}
            />
          </DealRoleRestricted>
        </div>

        <div>
          <H3>Activity</H3>
          <Activity question={props.question} />
        </div>
      </div>
    </div>
  );
}

function Activity(props: { question: QuestionQuery["question"] }) {
  const account = useSelector(authSelectors.account);
  const [isFocused, setIsFocused] = useState(false);

  const queryClient = useQueryClient();
  const client = useGqlClient();

  const createComment = useCreateCommentMutation(client);

  const [files, setFiles] = useState<DataRoomFile[]>([]);
  const [comment, setComment] = useState("");

  const createCommentRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (createCommentRef.current) {
        createCommentRef.current.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function clearForm() {
    setComment("");
    setFiles([]);
  }

  function onSubmit() {
    if (!comment) {
      return;
    }

    createComment.mutate(
      {
        input: {
          questionId: props.question.id,
          comment,
          files: files.map((f) => f.id),
          dataRoomFileId: "",
          dataRoomFolderId: "",
          dataRoomId: "",
          dataRoomFileErrorId: "",
        },
      },
      {
        onSuccess: () => {
          clearForm();
          queryClient.invalidateQueries({
            queryKey: ["Question", { id: props.question.id }],
          });
        },
      }
    );
  }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setTimeout(() => {
      if (
        !document.activeElement ||
        !document.activeElement.closest("#comment_form")
      ) {
        setIsFocused(false);
      }
    }, 0);
  };

  if (!account) {
    return null;
  }

  return (
    <>
      <ul role="list">
        <TransitionGroup className="space-y-6 mt-2">
          {props.question.activity
            .map((activity, i) => {
              return (
                <CSSTransition
                  key={activity.id}
                  timeout={300} // This controls the duration of the animation
                  classNames="fade-slide-down"
                  onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
                >
                  <ActivityItem
                    key={activity.id}
                    activity={activity}
                    isFirst={i === props.question.activity.length - 1}
                    isLast={i === 0}
                  />
                </CSSTransition>
              );
            })
            .reverse()}
        </TransitionGroup>
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3 " onBlur={handleBlur}>
        <Avatar account={account} />
        <form
          id="comment_form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="relative flex-auto"
        >
          <div
            className={`overflow-hidden rounded-lg ${
              isFocused ? "pb-12" : "pb-1"
            } shadow-sm ring-1 ring-inset transition-all ease-in-out duration-300 ring-gray-300 focus-within:bg-white focus-within:ring-indigo-500 focus:ring-2`}
          >
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={isFocused ? 2 : 1}
              name="comment"
              id="comment"
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              value={comment}
              onChange={(e) => {
                setComment(e.currentTarget.value);
              }}
              defaultValue={""}
            />
          </div>

          {/* <div
            className={`ring-0  absolute top-0 right-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Dropdown
              options={[{ value: 1, label: "Visible to sell side" }]}
              defaultValue="Visible to sell side"
              dropdownType="text"
              onSelect={() => {}}
            />
          </div> */}

          <div
            className={`ring-0  absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Pills>
              {files.map((file) => {
                return (
                  <FilePill
                    id={file.id}
                    name={file.name}
                    type={file.fileType}
                    checkFileAccessAccountId={props.question.createdBy.id}
                    noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                    onRemove={() => {
                      setFiles(files.filter((f) => f.id !== file.id));
                    }}
                  />
                );
              })}
              <AddFilePill
                onFileSelected={(file) => {
                  if (files.find((f) => f.id === file.id)) {
                    return;
                  }

                  setFiles([...files, file]);
                }}
              />
            </Pills>
            <Button
              ref={createCommentRef}
              variant="neutral"
              size="s"
              text="Comment"
              isLoading={createComment.isPending}
              loadingText="Commenting..."
              type="submit"
            />
          </div>
        </form>
      </div>
    </>
  );
}

function SuggestedAnswer(props: {
  question: QuestionQuery["question"];
  onSelected: (
    answer: string,
    files: DealThreadMessageContentFile[],
    quotes: DealThreadMessageContentQuote[]
  ) => void;
}) {
  const question = props.question;
  const account = useSelector(authSelectors.account);

  if (question.answer) {
    return null;
  }

  if (!question.suggestedAnswer) {
    return null;
  }

  return (
    <>
      <div className="flex justify-center my-2">
        <div className="w-px h-8 bg-gray-200"></div>
      </div>
      <div className="ml-9 mt-2 opacity-50 hover:opacity-100 p-3 rounded-md shadow bg-white">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <SparklesIcon className="w-4 h-4 text-indigo-500" />
            <p className="text-indigo-500 font-semibold text-sm">
              Suggested Answer
            </p>
          </div>
          <XCircleIcon className="w-5 h-5 text-gray-400" />
        </div>
        <div className="w-full bg-gray-200 h-px my-3"></div>
        <div>
          <div className="flex gap-x-4">
            <div className="">
              <p className="text-sm ml-1 text-gray-600">
                <Markdown>{question.suggestedAnswer.answer}</Markdown>
              </p>
              {/* <div className="mt-2">
                {question.suggestedAnswer.quotes
                  ? question.suggestedAnswer.quotes.map((quote) => {
                      return (
                        <div
                          className="p-3 bg-white border my-1 border-gray-200 rounded-md"
                          key={quote.quote}
                        >
                          <p className="text-sm text-gray-600">{quote.quote}</p>
                        </div>
                      );
                    })
                  : null}
              </div> */}
              {question.suggestedAnswer.files.length > 0 ? (
                <Pills>
                  {question.suggestedAnswer.files.map((file) => {
                    return (
                      <FilePill
                        key={file.file.id}
                        id={file.file.id}
                        name={file.file.name}
                        type={file.file.fileType}
                        showDetailsCard={false}
                        pageIndex={file.pageIndex ? file.pageIndex : undefined}
                        rectsOnPage={
                          file.rectsOnPage ? file.rectsOnPage : undefined
                        }
                      />
                    );
                  })}
                </Pills>
              ) : null}

              <div className="mt-3 flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    if (!question.suggestedAnswer) {
                      return;
                    }
                    props.onSelected(
                      question.suggestedAnswer.answer,
                      question.suggestedAnswer
                        .files as DealThreadMessageContentFile[],
                      question.suggestedAnswer
                        .quotes as DealThreadMessageContentQuote[]
                    );
                  }}
                >
                  Use answer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
