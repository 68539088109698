import { useSelector } from "react-redux";
import {
  IgnoreErrorRulesQuery,
  useDeleteIgnoreErrorRuleMutation,
  useIgnoreErrorRulesQuery,
} from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import { authSelectors } from "../../../../store/auth/selector";
import Loading from "../../../../components/Loading";
import { H4 } from "../../../../components/Heading";
import { Card } from "../../../../components/Card";
import { Pills } from "../../../../components/Pills";
import { FilePill } from "../../../../components/FilePill";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { TripleDotMenu } from "../../../../components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { toasts } from "../../../../components/toasts/toasts";
import { classNames } from "../../../../utils/cn";
import { useQueryClient } from "@tanstack/react-query";

export function ErrorCheckingSettings() {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const ignoreErrorRules = useIgnoreErrorRulesQuery(client, {
    dealId: activeDealId ?? "",
  });

  if (ignoreErrorRules.error) {
    return (
      <div>
        <p className="font-semibold text-gray-800">Something went wrong</p>
      </div>
    );
  }

  if (ignoreErrorRules.isPending || !ignoreErrorRules.data) {
    return <Loading />;
  }

  const fileRules = ignoreErrorRules.data.ignoreErrorRules.filter(
    (rule) => !!rule.dataRoomFile
  );
  const keyRules = ignoreErrorRules.data.ignoreErrorRules.filter(
    (rule) => !!rule.key
  );
  return (
    <div className="w-full xl:w-1/2">
      <H4>Ignore rules</H4>
      <p className="mt-3 font-semibold text-gray-800">Files</p>
      <Card padding="m" margin="s 0 0 0">
        {fileRules.length === 0 ? (
          <div className="flex items-center justify-center flex-col p-4">
            <p className=" text-gray-800 text-sm">No ignored files</p>
          </div>
        ) : null}
        {fileRules.map((rule) => {
          if (!rule.dataRoomFile) return null;
          return (
            <IgnoreRule key={rule.id} rule={rule} dealId={activeDealId ?? ""} />
          );
        })}
      </Card>

      <p className="font-semibold text-gray-800 mt-4">Financial metrics</p>
      <Card padding="m" margin="s 0 0 0">
        {keyRules.length === 0 ? (
          <div className="flex items-center justify-center flex-col p-4">
            <p className=" text-gray-800 text-sm">
              No ignored financial metrics
            </p>
          </div>
        ) : null}
        {keyRules.map((rule) => {
          if (!rule.key) return null;
          return (
            <IgnoreRule key={rule.id} rule={rule} dealId={activeDealId ?? ""} />
          );
        })}
      </Card>
    </div>
  );
}

function IgnoreRule(props: {
  rule: IgnoreErrorRulesQuery["ignoreErrorRules"][0];
  dealId: string;
}) {
  const client = useGqlClient();
  const deleteRule = useDeleteIgnoreErrorRuleMutation(client);
  const queryClient = useQueryClient();

  const rule = props.rule;

  if (rule.dataRoomFile) {
    return (
      <div className="flex items-center justify-between py-2">
        {/* <div className="flex items-center gap-x-1.5">
          <FileIcon fileType={props.rule.dataRoomFile.fileType} />
          <p className="text-sm font-semibold text-gray-600">
            {props.rule.dataRoomFile.name}
          </p>
        </div> */}
        <FilePill
          id={rule.dataRoomFile.id}
          name={rule.dataRoomFile.name}
          type={rule.dataRoomFile.fileType}
        />
        <div className="flex items-center gap-x-1.5">
          <p className="text-xs text-gray-500/80">
            Created {formatDistanceToNowStrict(fromUnixTime(rule.createdAt))}
          </p>
          <TripleDotMenu>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={(e) => {
                    deleteRule.mutate(
                      {
                        id: rule.id,
                      },
                      {
                        onSuccess: () => {
                          toasts.success("Rule deleted");
                          queryClient.invalidateQueries({
                            queryKey: [
                              "IgnoreErrorRules",
                              { dealId: props.dealId },
                            ],
                          });
                        },
                        onError: () => {
                          toasts.error("Failed to delete rule");
                        },
                      }
                    );
                  }}
                  className={classNames(
                    active ? "bg-gray-50 text-red-700" : "",
                    "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                  )}
                >
                  Delete rule
                </div>
              )}
            </Menu.Item>
          </TripleDotMenu>
        </div>
      </div>
    );
  }

  if (props.rule.key) {
    return (
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-semibold text-gray-700">{rule.key}</p>
          <p className="text-sm text-gray-500/80">
            Ignored in{rule.keyFiles.length === 0 ? " all files" : ":"}
          </p>
          {rule.keyFiles.length > 0 ? (
            <Pills>
              {rule.keyFiles.map((file) => (
                <FilePill
                  key={file.id}
                  id={file.id}
                  name={file.name}
                  type={file.fileType}
                />
              ))}
            </Pills>
          ) : null}
        </div>
        <div className="flex items-center gap-x-1.5">
          <p className="text-xs text-gray-500/80">
            Created {formatDistanceToNowStrict(fromUnixTime(rule.createdAt))}
          </p>
          <TripleDotMenu>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={(e) => {
                    deleteRule.mutate(
                      {
                        id: rule.id,
                      },
                      {
                        onSuccess: () => {
                          toasts.success("Rule deleted");
                          queryClient.invalidateQueries({
                            queryKey: [
                              "IgnoreErrorRules",
                              { dealId: props.dealId },
                            ],
                          });
                        },
                        onError: () => {
                          toasts.error("Failed to delete rule");
                        },
                      }
                    );
                  }}
                  className={classNames(
                    active ? "bg-gray-50 text-red-700" : "",
                    "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                  )}
                >
                  Delete rule
                </div>
              )}
            </Menu.Item>
          </TripleDotMenu>
        </div>
      </div>
    );
  }

  return null;
}
