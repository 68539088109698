import Loading from "../components/Loading";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSession } from "@clerk/clerk-react";
import { authSelectors } from "../store/auth/selector";

export function AccountGate(props: { children: React.ReactNode }) {
  const { isSignedIn } = useSession();

  if (!isSignedIn) {
    return <Redirect to={"/login"} />;
  }

  return <InitAccount>{props.children}</InitAccount>;
}

function InitAccount(props: { children: React.ReactNode }) {
  const account = useSelector(authSelectors.account);

  if (!account) {
    return <Loading />;
  }

  return <>{props.children}</>;
}
