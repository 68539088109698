import { useState } from "react";
import { Button } from "./tailwind/Button";
import { CheckIcon, XCircleIcon } from "@heroicons/react/20/solid";

export function EditableText(props: {
  initialText: string;
  onSave: (text: string) => void;
  onCancel: () => void;
}) {
  const { initialText, onSave, onCancel } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [inputText, setInputText] = useState(initialText);

  const handleSave = () => {
    setText(inputText);
    onSave(inputText);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInputText(text);
    setIsEditing(false);
  };

  return (
    <div className="flex items-center space-x-2">
      {isEditing === false ? (
        <button
          className="cursor-text"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <p className="text-gray-700 text-sm font-semibold">{text}</p>
        </button>
      ) : (
        <div className="flex items-center space-x-2 py-2">
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className="border-b leading-none py-1 border-gray-200 rounded-md focus:outline-none focus:border-gray-500"
          />

          <Button
            onClick={handleCancel}
            variant="neutral"
            text=""
            icon={XCircleIcon}
            size="s"
          />
          <Button
            onClick={handleSave}
            variant="positive"
            text=""
            icon={CheckIcon}
            size="s"
          />
        </div>
      )}
    </div>
  );
}
