import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import {
  CreateTaskCommentInput,
  DataRoomFile,
  useCreateTaskCommentMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useEffect, useRef, useState } from "react";
import { Avatar } from "../../components/account/Avatar";
import { Button } from "../../components/tailwind/Button";

export function CommentInput(props: {
  input: CreateTaskCommentInput;
  onCommented: () => void;
}) {
  const account = useSelector(authSelectors.account);

  const client = useGqlClient();
  const createComment = useCreateTaskCommentMutation(client);

  const [comment, setComment] = useState(props.input.comment);
  const [files, setFiles] = useState<DataRoomFile[]>([]);

  const [isFocused, setIsFocused] = useState(false);

  function onSubmit() {
    if (comment.trim().length === 0) {
      return;
    }

    createComment.mutate(
      {
        input: {
          ...props.input,
          comment: comment,
        },
      },
      {
        onSuccess: () => {
          props.onCommented();
        },
      }
    );

    clearForm();
  }

  const createCommentRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (createCommentRef.current) {
        createCommentRef.current.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function clearForm() {
    setComment("");
    setFiles([]);
  }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setTimeout(() => {
      if (
        !document.activeElement ||
        !document.activeElement.closest("#comment_form")
      ) {
        setIsFocused(false);
      }
    }, 0);
  };

  if (!account) {
    return null;
  }

  return (
    <div className="mt-6 flex gap-x-3 " onBlur={handleBlur}>
      <Avatar account={account} />
      <form
        id="comment_form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        className="relative flex-auto"
      >
        <div
          className={`overflow-hidden rounded-lg ${
            isFocused ? "pb-12" : "pb-1"
          } shadow-sm ring-1 ring-inset transition-all ease-in-out duration-300 ring-gray-300 focus-within:bg-white focus-within:ring-indigo-500 focus:ring-2`}
        >
          <label htmlFor="comment" className="sr-only">
            Add your comment
          </label>
          <textarea
            rows={isFocused ? 2 : 1}
            name="comment"
            id="comment"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="Add your comment..."
            value={comment}
            onChange={(e) => {
              setComment(e.currentTarget.value);
            }}
            defaultValue={""}
          />
        </div>

        {/* <div
            className={`ring-0  absolute top-0 right-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Dropdown
              options={[{ value: 1, label: "Visible to sell side" }]}
              defaultValue="Visible to sell side"
              dropdownType="text"
              onSelect={() => {}}
            />
          </div> */}

        <div
          className={`ring-0  absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
            isFocused
              ? "opacity-100 visible duration-300 delay-100 "
              : " opacity-0 invisible duration-150 delay-0"
          }`}
        >
          <Button
            ref={createCommentRef}
            variant="neutral"
            size="s"
            text="Comment"
            isLoading={createComment.isPending}
            loadingText="Commenting..."
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
