import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { MOBILE_NAV_BREAKPOINT } from "../config";
import { useColorScheme } from "../hooks/useColorScheme";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";
import { useTheme } from "../hooks/useTheme";
import { useWindowSize } from "../hooks/useWindowSize";
import { authSelectors } from "../store/auth/selector";
import { WithMarginProp } from "../styles/withMargin";
import CompanyLogo from "./CompanyLogo";
import { actions } from "../store/auth/slice";
import useGqlClient from "../hooks/useGqlClient";
import {
  DealFirmGroupType,
  DealRole,
  DealStatus,
  useDealQuery,
} from "../graphql/generated";

import {
  ChartBarIcon,
  DocumentIcon,
  FolderIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import { H3 } from "./Heading";
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightIcon,
  Bars3Icon,
  ChevronUpDownIcon,
  CogIcon,
  HomeIcon,
  ListBulletIcon,
  LockClosedIcon,
  PlusIcon,
  Squares2X2Icon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
import Logo from "./Logo";
import { useQueryClient } from "@tanstack/react-query";
import {
  DealRoleRestricted,
  adminRoles,
  allAdmins,
  buyerAdmins,
  buyerRoles,
  sellSideAdmins,
  sellerRoles,
} from "./DealRoleRestricted";
import { usePostHog } from "posthog-js/react";
import { useAuth } from "@clerk/clerk-react";
import { Avatar } from "./account/Avatar";
import { classNames } from "../utils/cn";

interface MobileNavProps {
  menuToggle: () => void;
  showMenu: boolean;
}

const MobileHeader = (props: MobileNavProps) => {
  return (
    <header className="bg-blue-950 py-3 px-2 shadow-xl">
      <div className="flex justify-between items-center shadow-xl">
        <Logo width={105} height={35} fill="#fff" />
        <Bars3Icon className="text-white w-6 h-6" />
      </div>
    </header>
  );
};

export function Navigation() {
  const window = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const colorScheme = useColorScheme();
  const theme = useTheme();

  const { signOut } = useAuth();

  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dealQuery = useDealQuery(client, {
    id: activeDealId ? activeDealId : "",
  });

  const activeDeal = useSelector(authSelectors.activeDeal);
  const account = useSelector(authSelectors.account);
  const wrapperRef = useRef(null);
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const history = useHistory();

  const posthog = usePostHog();

  useOutsideAlerter(wrapperRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  return (
    <>
      {window.width !== undefined && window.width < MOBILE_NAV_BREAKPOINT && (
        <MobileHeader
          showMenu={showMenu}
          menuToggle={() => setShowMenu((s) => !s)}
        />
      )}
      <Nav colorScheme={colorScheme} showMenu={showMenu}>
        <div className="flex flex-col mt-3  mx-1.5">
          <div className="ml-4 mb-5">
            <Logo width={105} height={35} />
          </div>

          <H3 mode="dark" margin="m m m m">
            {account && account.firm ? account.firm.name : ""}
          </H3>
          <Link margin="xl 0 0 0" to="/home" exact>
            <div className="flex items-center space-x-3">
              <HomeIcon className="w-4 h-4" />
              <span className="text-sm font-semibold">Home</span>
            </div>
          </Link>
          <Link to="/tasks">
            <div className="flex items-center space-x-3">
              <ListBulletIcon className="w-4 h-4" />
              <span className="text-sm font-semibold">Tasks</span>
            </div>
          </Link>
          <Link to="/documents">
            <div className="flex items-center space-x-3">
              <DocumentIcon className="w-4 h-4" />
              <span className="text-sm font-semibold">Documents</span>
            </div>
          </Link>
          <Link size="s" to="/firm">
            <div className="flex items-center gap-x-3">
              <UsersIcon className="w-4 h-4 " />
              <span className="text-sm">Firm</span>
            </div>
          </Link>
          <div className=" my-4 h-px w-auto bg-gray-200/80"></div>

          {/* <Link margin="0 0 s 0" to="/marketplace" exact>
            <ShoppingCartIcon className="w-5 h-5 text-white" />
            <span className="text-sm">Marketplace</span>
          </Link>
 */}
          <div className="flex items-center justify-between">
            <H3 mode="dark" margin=" m m m">
              Deals
            </H3>
            {activeDeal ? (
              <NavLink
                className={`text-persian-50 font-semibold bg-persian-400/30 hover:bg-persian-500/40 px-2.5 border border-persian-400 hover:border-persian-500  py-1 rounded-full text-sm flex items-center`}
                to="/create-deal"
              >
                <PlusIcon className="w-4 h-4" />
                New deal
              </NavLink>
            ) : null}
          </div>
          {account && account.deals && account.deals.length > 0 ? (
            <div
              className="relative select-none z-[998] w-full cursor-pointer mb-4"
              ref={wrapperRef}
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
            >
              <div className="flex justify-between items-center hover:bg-persian-950/30  hover:shadow-sm shadow-white rounded-md p-3">
                <div className="flex items-center gap-3">
                  <div style={{ position: "relative" }}>
                    <CompanyLogo
                      logo={activeDeal ? activeDeal.company.logo : undefined}
                      withShadow={false}
                      borderSize={3}
                      size={38}
                      name={activeDeal ? activeDeal.company.name : ""}
                      bgColor={
                        activeDeal ? activeDeal.company.logoColor : "#000"
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="font-bold text-white text-sm truncate">
                      {activeDeal ? activeDeal.company.name : ""}
                    </p>
                    <p className="text-white font-semi text-xs">
                      {dealQuery.data &&
                      dealQuery.data.deal.activeDealAccount.dealFirmGroup &&
                      dealQuery.data.deal.activeDealAccount.dealFirmGroup
                        .type === DealFirmGroupType.Buyer
                        ? "Buy side"
                        : "Sell side"}
                    </p>
                  </div>
                </div>
                {/* <AccountSwitcherIcon colorPreset="secondary" /> */}
                <div className="flex items-center gap-x-2">
                  {activeDeal && activeDeal.status === DealStatus.Archived ? (
                    <div className="bg-persian-600 rounded-md shadow-sm px-1.5 py-1">
                      <p className="text-xs text-white font-semibold">
                        Archived
                      </p>
                    </div>
                  ) : null}
                  <ChevronUpDownIcon className="w-4 h-4 text-white" />
                </div>
              </div>

              {showDropdown ? (
                <div className="bg-white rounded-md absolute w-72 z-50 overflow-y-scroll scrollbar-none min-h-80 max-h-[90vh] mt-4 md:top-13 shadow-lg">
                  {account && account.deals
                    ? account.deals
                        .filter((deal) =>
                          activeDeal ? deal.id !== activeDeal.id : true
                        )
                        .map((deal) => {
                          return (
                            <div
                              className={`py-3 px-4 text-secondary-text mb-2 last:mb-0 rounded-md cursor-pointer active:bg-gray-100 hover:bg-gray-100`}
                              key={deal.id}
                              onClick={() => {
                                dispatch(
                                  actions.setActiveDealId({ id: deal.id })
                                );
                                queryClient.invalidateQueries();
                                history.push("/dashboard");
                                setShowDropdown(false);
                              }}
                            >
                              <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-col">
                                  <p className="font-semibold text-gray-500 text-sm">
                                    {deal.company.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                    : null}
                </div>
              ) : null}
            </div>
          ) : null}

          {activeDeal ? (
            <div className="overflow-y-scroll    max-h-96 xl:max-h-none no-scrollbar">
              {/* {location.pathname.startsWith("/finances") ? (
                <div style={{ marginLeft: 12 }}>
                  <Link margin="0 0 s 0" to="/finances/statements">
                    <TableCellsIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Statements</span>
                  </Link>
                  <Link margin="0 0 s 0" to="/finances/playground">
                    <BeakerIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Playground</span>
                  </Link>
                  <Link margin="0 0 s 0" to="/finances/quality-of-earnings">
                    <CalculatorIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Quality of Earnings</span>
                  </Link>
                  <Link margin="0 0 s 0" to="/finances/valuation">
                    <MapIcon className="w-5 h-5 white" />
                    <span className="text-sm">Valuation</span>
                  </Link>
                </div>
              ) : null} */}

              <Link margin="0 0 xs 0" to="/dashboard">
                <div className="flex items-center space-x-3">
                  <Squares2X2Icon className="w-4 h-4" />
                  <span className="text-sm font-semibold">Dashboard</span>
                </div>
              </Link>
              <DealRoleRestricted
                buyerRunDealRoles={[]}
                sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
              >
                <Link margin="0 0 xs 0" to="/buyers">
                  <div className="flex items-center space-x-3">
                    <UserGroupIcon className="w-4 h-4 " />
                    <span className="text-sm font-semibold">Buyers</span>
                  </div>
                </Link>
              </DealRoleRestricted>
              {/* {location.pathname.startsWith("/buyers") ? (
                <div style={{ marginLeft: 12 }}>
                  <Link margin="0 0 xs 0" to="/buyers/dd-checklists">
                    <div className="flex items-center space-x-3">
                      <ClipboardDocumentListIcon className="w-4 h-4 text-white" />
                      <span className="text-sm font-semibold">
                        DD Checklists
                      </span>
                    </div>
                  </Link>
                </div>
              ) : null} */}

              <Link margin="0 0 xs 0" to="/questions">
                <div className="flex items-center space-x-3">
                  <QuestionMarkCircleIcon className="w-4 h-4 " />
                  <span className="text-sm font-semibold">Questions</span>
                </div>
              </Link>

              <DealRoleRestricted
                sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
                buyerRunDealRoles={[...buyerRoles, ...adminRoles]}
              >
                <Link margin="0 0 xs 0" to="/toolkit">
                  <div className="flex items-center space-x-3">
                    <WrenchScrewdriverIcon className="w-4 h-4 " />
                    <span className="text-sm font-semibold">Toolkit</span>
                  </div>
                </Link>
              </DealRoleRestricted>
              {/* <Link margin="0 0 s 0" to="/due-diligence">
                <div className="flex items-center space-x-3">
                  <MagnifyingGlassCircleIcon className="w-5 h-5 text-white" />
                  <p className="text-sm ">Due Diligence Checklist</p>
                </div>
              </Link> */}
              <Link margin="0 0 xs 0" to="/dataroom/files" exact>
                <div className="flex items-center space-x-3 w-full">
                  <FolderIcon className="h-4 w-4 " />
                  <div className="flex items-center justify-between w-full">
                    <span className="text-sm font-semibold">Data Room</span>
                    <p className="text-xs">Shared documents</p>
                  </div>
                </div>
              </Link>
              {location.pathname.startsWith("/dataroom") ? (
                <div style={{ marginLeft: 12 }}>
                  <DealRoleRestricted
                    sellerRunDealRoles={sellSideAdmins}
                    buyerRunDealRoles={allAdmins}
                  >
                    <Link margin="0 0 xs 0" to="/dataroom/activity">
                      <ChartBarIcon className="w-4 h-4 " />
                      <span className="text-sm font-semibold">Activity</span>
                    </Link>
                  </DealRoleRestricted>
                </div>
              ) : null}
              <DealRoleRestricted
                sellerRunDealRoles={[
                  DealRole.BuyerAdmin,
                  DealRole.DealAdmin,
                  DealRole.SellerAdmin,
                  DealRole.DealOwner,
                ]}
                buyerRunDealRoles={[
                  DealRole.DealAdmin,
                  DealRole.DealOwner,
                  DealRole.BuyerAdmin,
                  DealRole.SellerAdmin,
                ]}
              >
                <Link margin="0 0 xs 0" to="/deal/access">
                  <div className="flex items-center space-x-3">
                    <LockClosedIcon className="w-4 h-4 " />
                    <span className="text-sm font-semibold">Access</span>
                  </div>
                </Link>
              </DealRoleRestricted>
              <DealRoleRestricted
                sellerRunDealRoles={sellSideAdmins}
                buyerRunDealRoles={buyerAdmins}
              >
                <Link margin="0 0 xs 0" to={`/deal/settings/exports`}>
                  <div className="flex items-center space-x-3">
                    <CogIcon className="w-4 h-4 " />
                    <span className="text-sm font-semibold">Settings</span>
                  </div>
                </Link>
              </DealRoleRestricted>
            </div>
          ) : (
            <div className="p-3 bg-persian-950 border-persian-50/30 mx-2 border rounded-md">
              <p className="text-white text-sm font-semibold">No deals</p>
              <p className="text-gray-200 text-sm">
                All your deals will appear here
              </p>

              <NavLink
                to="/create-deal"
                className={
                  "text-white font-semibold text-sm  mt-3 group flex items-center"
                }
              >
                Create my first deal
                <ArrowRightIcon className="text-white w-4 h-4 group-hover:ml-2 ml-0.5 transition-all" />
              </NavLink>
            </div>
          )}
        </div>
        <div className="mt-auto  mb-8">
          <>
            <div className="w-full h-px bg-gray-200/80 mb-4"></div>
            <Link size="s" to="/settings" mode="secondary">
              <div className="flex items-center gap-x-3">
                {account ? (
                  <Avatar size="xs" account={account} />
                ) : (
                  <UserCircleIcon className="w-4 h-4 " />
                )}
                <span className="">Account</span>
              </div>
            </Link>
            <Link
              mode="secondary"
              size="s"
              to="/login"
              onClick={async () => {
                await signOut();
                posthog?.reset();

                dispatch(actions.logout());
                queryClient.removeQueries();
                queryClient.invalidateQueries();
                queryClient.invalidateQueries({
                  queryKey: ["Account", {}],
                });
                history.push("/login");
              }}
            >
              <div className="flex items-center gap-x-3">
                <ArrowLeftOnRectangleIcon className="w-4 h-4 " />
                <span className="">Logout</span>
              </div>
            </Link>
          </>
        </div>
      </Nav>
    </>
  );
}

const Nav = ({
  showMenu,
  colorScheme,
  children,
}: {
  showMenu: boolean;
  colorScheme: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`  w-[300px] from-persian-950/90 to-persian-950/85 bg-gradient-to-br flex h-screen sticky top-0 z-30 select-none pt-2 flex-col  `}
    >
      {children}
    </div>
  );
};

// md:absolute md:left-0 md:rounded-md md:m-4 md:h-auto md:w-full md:box-border md:border-r-0 md:transition md:duration-200 md:ease-out md:shadow-md

const Link = ({
  children,
  size,
  onClick,
  mode,
  ...props
}: {
  children: React.ReactNode;
  size?: string;
  mode?: "secondary" | "primary";
  onClick?: () => void;
} & WithMarginProp & {
    to: string;
    exact?: boolean;
  }) => {
  if (mode && mode === "secondary") {
    return (
      <NavLink
        {...props}
        onClick={onClick}
        key={props.to}
        className={(isActive) => {
          return classNames(
            size === "s" ? "text-sm px-0" : "text-base",
            "gap-x-2 px-3 py-1.5 my-1.5 text-white font-semibold rounded-md flex items-center",
            isActive ? " text-persian-300" : "text-white hover:text-persian-300"
          );
        }}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <NavLink
      {...props}
      key={props.to}
      className={(isActive) => {
        return classNames(
          size === "s" ? "text-sm px-0" : "text-base",
          "gap-x-2 px-3 py-1.5 my-1.5 hover:bg-white font-semibold rounded-md flex items-center",
          isActive
            ? "bg-white text-persian-600/90"
            : "text-white hover:text-persian-600/90"
        );
      }}
    >
      {children}
    </NavLink>
  );
};
