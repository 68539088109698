import { NavLink, useHistory } from "react-router-dom";
import { H3, H4 } from "../../components/Heading";
import useGqlClient from "../../hooks/useGqlClient";
import { DealStatus, useAccountQuery } from "../../graphql/generated";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { actions } from "../../store/auth/slice";
import { DealCard } from ".";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";

export function Archived() {
  return (
    <div className="flex flex-col flex-1">
      <div className="bg-white sticky right-0 z-20 top-0 px-8 py-4 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/home">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Home
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            <H4>Archived deals</H4>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col h-full w-full p-8">
        <ArchivedContent />
      </div>
    </div>
  );
}

function ArchivedContent() {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const history = useHistory();
  const dispatch = useDispatch();

  if (accountQuery.error) {
    return (
      <div>
        <p className="text-gray-700 font-bold">Something went wrong</p>
      </div>
    );
  }

  if (accountQuery.isLoading || !accountQuery.data) {
    return <Loading />;
  }

  const archivedDeals = accountQuery.data.account.deals.filter(
    (d) => d.status === DealStatus.Archived
  );

  if (archivedDeals.length === 0) {
    return (
      <div className="flex-1 flex-col justify-center items-center">
        <Card padding="m">
          <div className="flex items-center justify-center flex-col">
            <ArchiveBoxIcon className="h-12 w-12 text-gray-400" />
            <p className="font-semibold text-gray-600">No archived deals yet</p>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="flex items-center justify-between">
          <H3>Archived deals</H3>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mt-2 gap-4">
          {archivedDeals
            .sort((a, b) => {
              return a.createdAt > b.createdAt ? -1 : 1;
            })
            .map((deal) => {
              return (
                <button
                  key={deal.id}
                  className="cursor-pointer text-left"
                  onClick={() => {
                    dispatch(actions.setActiveDealId({ id: deal.id }));
                    history.push("/dashboard");
                  }}
                >
                  <DealCard deal={deal} />
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
}
