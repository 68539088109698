import { useAuth, useSession } from "@clerk/clerk-react";
import Loading from "../components/Loading";
import useGqlClient from "../hooks/useGqlClient";
import { useCreateAccountMutation } from "../graphql/generated";
import { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../store/auth/slice";

export function OnboardingGate(props: { children: React.ReactNode }) {
  const { session, isSignedIn } = useSession();
  const client = useGqlClient();
  const { getToken } = useAuth();

  if (isSignedIn === false) {
    return <Redirect to={"/login"} />;
  }

  if (!session) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (session.user.unsafeMetadata.onboardingStatus === "completed") {
    return <>{props.children}</>;
  }

  return (
    <OnboardingStatus
      clerkUserId={session.user.id}
      onboardingStatus={
        session.user.unsafeMetadata.onboarding as OnboardingStatuses
      }
    />
  );
}

type OnboardingStatuses = "initial" | "completed" | "error";

function OnboardingStatus(props: {
  onboardingStatus: OnboardingStatuses;
  clerkUserId: string;
  token?: string;
}) {
  const client = useGqlClient();

  const { session, isSignedIn } = useSession();
  const dispatch = useDispatch();
  const history = useHistory();
  const createAccount = useCreateAccountMutation(client);

  useEffect(() => {
    function reload() {
      session?.reload();
      setTimeout(reload, 1000);
    }

    reload();
  }, [session]);

  useEffect(() => {
    if (createAccount.isPending) {
      return;
    }

    createAccount.mutate(
      {
        clerkUserId: props.clerkUserId,
      },
      {
        onSuccess: (data) => {
          dispatch(actions.refreshAccount({ account: data.createAccount }));
          history.push("/");
        },
      }
    );
  }, [
    isSignedIn,
    props.onboardingStatus,
    createAccount,
    history,
    dispatch,
    props.clerkUserId,
  ]);

  return (
    <div className="flex flex-1 w-full  justify-center items-center">
      <Loading />
    </div>
  );
}
