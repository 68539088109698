import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/src/lib/utils";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/src/components/ui/popover";
import { XCircleIcon } from "@heroicons/react/20/solid";

interface Props {
  value?: Date;
  onChange?: (date: Date) => void;
  pretext?: string;
  onRemove?: () => void;
}

export function DatePicker(props: Props) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="group relative">
          <Button
            type="button"
            variant={"outline"}
            size="sm"
            className={cn(
              "justify-start py-1 rounded-full text-left font-normal",
              !props.value && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {props.value ? (
              `${props.pretext ? props.pretext + " " : ""}${format(
                props.value,
                "dd MMM"
              )}`
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
          {props.value && props.onRemove ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (props.onRemove) props.onRemove();
              }}
              className="z-50 hidden group-hover:block absolute -top-1.5 -right-2.5 group"
            >
              <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
            </button>
          ) : null}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={props.value}
          onSelect={(d, sd, am, e) => {
            if (props.onChange && d) {
              props.onChange(d);
            }
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
