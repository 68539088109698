import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { useAuth, useSession } from "@clerk/clerk-react";

interface TokenContextType {
  token: string | null;
}

const TokenContext = createContext<TokenContextType | null>(null);

interface TokenProviderProps {
  children: ReactNode;
}

export function TokenProvider(props: TokenProviderProps) {
  const { getToken, isLoaded, isSignedIn } = useAuth();
  const { session } = useSession();
  const [token, setToken] = useState<string | null>(
    session?.lastActiveToken?.getRawString() || null
  );

  const fetchToken = useCallback(async () => {
    if (!isLoaded || !isSignedIn) {
      return;
    }

    try {
      const token = await getToken({
        skipCache: true,
      });
      setToken(token);
    } catch (error) {
      console.error("Failed to fetch token", error);
    }
  }, [getToken, isLoaded, isSignedIn]);

  useEffect(() => {
    fetchToken();

    const intervalId = setInterval(() => {
      fetchToken();
    }, 55000);

    return () => clearInterval(intervalId);
  }, [fetchToken]);

  return (
    <TokenContext.Provider value={{ token }}>
      {props.children}
    </TokenContext.Provider>
  );
}

export const useToken = (): TokenContextType => {
  const context = useContext(TokenContext);
  if (context === undefined) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context as TokenContextType;
};
