import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import { apiEndpoint } from "../utils/endpoints";

import { useAuth } from "@clerk/clerk-react";
import { GraphQLClient } from "graphql-request";
import { RequestMiddleware } from "graphql-request/build/esm/types";

export default function useGqlClient() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const { getToken } = useAuth();

  const requestMiddleware: RequestMiddleware = async (request) => {
    const token = await getToken();

    if (!token) {
      return request;
    }

    return {
      ...request,
      headers: {
        ...request.headers,
        authorization: `Bearer ${token}`,
        "x-deal-id": activeDealId ?? "",
      },
    };
  };

  const client = new GraphQLClient(apiEndpoint(), { requestMiddleware });

  return client;
}
