import { AppState } from "..";

function showSidebar(state: AppState, deepSearchId: string): boolean {
  if (state.deepSearch.showSidebar[deepSearchId] === undefined) {
    return true;
  }

  return state.deepSearch.showSidebar[deepSearchId];
}

export const deepSearchSelectors = {
  showSidebar,
};
