import { useSelector } from "react-redux";
import { H3 } from "../../components/Heading";
import {
  Account,
  Deal,
  TaskActivityType,
  TaskPriority,
  TasksFilters,
  TasksQuery,
  TaskStatus,
  useTasksQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import Loading from "../../components/Loading";
import { PlusIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "../../components/tailwind/Button";
import { NewTaskModal } from "./NewTaskModal";
import { useEffect, useState } from "react";
import { Card } from "../../components/Card";
import { NavLink } from "react-router-dom";
import {
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
  isBefore,
} from "date-fns";
import { Avatar } from "../../components/account/Avatar";
import {
  BriefcaseIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  PlayCircleIcon,
  UserCircleIcon,
  ChatBubbleLeftIcon,
} from "@heroicons/react/24/outline";
import CompanyLogo from "../../components/CompanyLogo";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { UseQueryResult } from "@tanstack/react-query";
import { TaskPriorityIcon } from "../../components/TaskPriorityPill";

export function Tasks() {
  const account = useSelector(authSelectors.account);
  const [openModal, setOpenModal] = useState<"new_task" | "">("");
  const [filters, setFilters] = useState<TasksFilters>({});
  const client = useGqlClient();

  const tasksQuery = useTasksQuery(client, {
    firmID: account && account.firm ? account.firm.id : "",
    filters: filters,
  });

  const [dealOptions, setDealOptions] = useState<Option[]>([]);
  const [accountOptions, setAccountOptions] = useState<Option[]>([]);

  const [selectedDeal, setSelectedDeal] = useState<Option | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Option | null>(null);

  useEffect(() => {
    if (!tasksQuery.data || tasksQuery.isLoading) {
      return;
    }

    const accountOptions: Option[] = tasksQuery.data.firm.accounts.map(
      (acc) => {
        return {
          label: acc.name,
          value: acc.id,
          icon: <Avatar account={acc} size="xs" />,
        };
      }
    );

    setAccountOptions([
      {
        label: "All accounts",
        value: null,
      },
      ...accountOptions,
    ]);

    if (account && account.deals.length > 0) {
      const dealOptions: Option[] = account.deals.map((deal) => {
        return {
          label: deal.company?.name ?? "",
          value: deal.id,
          icon: (
            <CompanyLogo
              logo={deal.company ? deal.company.logo : ""}
              withShadow={false}
              borderSize={3}
              size={20}
              name={deal.company ? deal.company.name : ""}
              bgColor={deal.company ? deal.company.logoColor : "#000"}
            />
          ),
        };
      });

      setDealOptions([
        {
          label: "All deals",
          value: null,
        },
        ...dealOptions,
      ]);
    }
  }, [tasksQuery.data, account, tasksQuery.isLoading]);

  return (
    <div className="flex flex-col flex-1">
      <div className="bg-white sticky top-0 z-20 px-8 py-3 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Tasks</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
          <div className="flex items-center gap-x-3">
            {account && account.deals.length > 0 ? (
              <Dropdown
                size="s"
                options={dealOptions}
                selectedOption={selectedDeal ?? undefined}
                defaultOption={{
                  label: "All deals",
                  value: null,
                }}
                onSelect={(o) => {
                  setSelectedDeal(o);
                  setFilters({ ...filters, dealID: o.value });
                }}
              />
            ) : null}
            {accountOptions.length > 0 ? (
              <Dropdown
                size="s"
                options={accountOptions}
                selectedOption={selectedAccount ?? undefined}
                defaultOption={{
                  label: "All accounts",
                  value: null,
                }}
                onSelect={(o) => {
                  setSelectedAccount(o);
                  setFilters({ ...filters, assignedTo: o.value });
                }}
              />
            ) : null}
            <Button
              text="New task"
              icon={PlusIcon}
              variant="positive"
              onClick={() => {
                setOpenModal("new_task");
              }}
              size="s"
            />
          </div>
        </div>
      </div>
      <div className="p-8 flex-1 flex flex-col">
        {account && account.firm ? (
          <TasksContent
            onNewTask={() => {
              setOpenModal("new_task");
            }}
            query={tasksQuery}
          />
        ) : null}
      </div>
      {account && account.firm ? (
        <NewTaskModal
          open={openModal === "new_task"}
          onClose={() => {
            setOpenModal("");
          }}
          firmID={account.firm.id}
        />
      ) : null}
    </div>
  );
}

function TasksContent(props: {
  onNewTask: () => void;
  query: UseQueryResult<TasksQuery, unknown>;
}) {
  const tasksQuery = props.query;

  if (tasksQuery.isLoading || !tasksQuery.data) {
    return <Loading />;
  }

  if (tasksQuery.error) {
    return <div>Error</div>;
  }

  const completedTasks = tasksQuery.data.tasks
    .filter((task) => task.status === TaskStatus.Completed)
    .sort((a, b) => {
      if (
        a.priority === TaskPriority.Urgent &&
        b.priority !== TaskPriority.Urgent
      ) {
        return -1;
      }

      if (
        a.priority !== TaskPriority.Urgent &&
        b.priority === TaskPriority.Urgent
      ) {
        return 1;
      }

      return 0;
    });

  const inProgressTasks = tasksQuery.data.tasks
    .filter((task) => task.status === TaskStatus.InProgress)
    .sort((a, b) => {
      if (
        a.priority === TaskPriority.Urgent &&
        b.priority !== TaskPriority.Urgent
      ) {
        return -1;
      }

      if (
        a.priority !== TaskPriority.Urgent &&
        b.priority === TaskPriority.Urgent
      ) {
        return 1;
      }

      return 0;
    });

  const pendingTasks = tasksQuery.data.tasks
    .filter((task) => task.status === TaskStatus.Todo)
    .sort((a, b) => {
      if (
        a.priority === TaskPriority.Urgent &&
        b.priority !== TaskPriority.Urgent
      ) {
        return -1;
      }

      if (
        a.priority !== TaskPriority.Urgent &&
        b.priority === TaskPriority.Urgent
      ) {
        return 1;
      }

      return 0;
    });

  return (
    <div className="md:w-2/3">
      <div className=" flex items-center gap-x-2">
        <div className=" rounded-full border-2 border-gray-500 w-4 h-4 ml-0.5 text-gray-500" />
        <H3>To do</H3>
      </div>
      <Card margin="s 0 0 0">
        {pendingTasks.length === 0 ? (
          <div className="flex items-center flex-col py-3 justify-center">
            <p className="text-gray-700 text-sm font-semibold">
              No tasks outstanding
            </p>
          </div>
        ) : null}
        {pendingTasks.map((task) => (
          <NavLink key={task.id} to={`/tasks/${task.id}`}>
            <div key={task.id} className="hover:bg-concrete-50 px-3 py-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <TaskPriorityIcon priority={task.priority ?? undefined} />
                  <div>
                    <p className="font-semibold text-sm text-gray-700">
                      {task.title}
                    </p>
                    <p className="text-xs text-gray-500/80">
                      Created by {task.createdBy.firstName}{" "}
                      {formatDistanceToNowStrict(fromUnixTime(task.createdAt), {
                        addSuffix: true,
                      })}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <>
                    {task.assignedTo ? (
                      <UserPillInfo account={task.assignedTo as Account} />
                    ) : (
                      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
                        <UserCircleIcon className="w-4 h-4 text-gray-400" />
                        <p className="ml-1 text-xs text-gray-500">
                          No assignee
                        </p>
                      </div>
                    )}
                  </>
                  <>
                    {task.deal ? (
                      <DealPillCustom deal={task.deal as Deal} />
                    ) : (
                      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-2  flex items-center  py-1">
                        <BriefcaseIcon className="w-4 h-4 text-gray-400" />
                        <p className="ml-1 text-xs text-gray-500">
                          No assigned deal
                        </p>
                      </div>
                    )}
                  </>
                  <>
                    <DueDatePill dueDate={task.dueDate ?? undefined} />
                  </>
                  <div className="border border-concrete-100 rounded-2xl px-2 flex items-center gap-x-1.5">
                    <ChatBubbleLeftIcon className=" w-4 h-4 text-gray-400" />
                    <p className="text-gray-500 text-sm">
                      {
                        task.activity.filter(
                          (a) => a.type === TaskActivityType.Comment
                        ).length
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        ))}
      </Card>

      <div className="mt-8 flex items-center gap-x-2">
        <PlayCircleIcon className="w-6 h-6 text-gray-500" />
        <H3>In progress</H3>
      </div>
      <Card margin="s 0 0 0">
        {inProgressTasks.length === 0 ? (
          <div className="flex items-center flex-col py-3 justify-center">
            <p className="text-gray-700 text-sm font-semibold">
              No tasks in progress
            </p>
          </div>
        ) : null}
        {inProgressTasks.map((task) => (
          <NavLink key={task.id} to={`/tasks/${task.id}`}>
            <div key={task.id} className="hover:bg-concrete-50 px-3 py-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <TaskPriorityIcon priority={task.priority ?? undefined} />
                  <div>
                    <p className="font-semibold text-sm text-gray-700">
                      {task.title}
                    </p>
                    <p className="text-sm text-xs text-gray-500/80">
                      Created by {task.createdBy.firstName}{" "}
                      {formatDistanceToNowStrict(fromUnixTime(task.createdAt), {
                        addSuffix: true,
                      })}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <>
                    {task.assignedTo ? (
                      <UserPillInfo account={task.assignedTo as Account} />
                    ) : (
                      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
                        <UserCircleIcon className="w-4 h-4 text-gray-400" />
                        <p className="ml-1 text-xs text-gray-500">
                          No assignee
                        </p>
                      </div>
                    )}
                  </>
                  <>
                    {task.deal ? (
                      <DealPillCustom deal={task.deal as Deal} />
                    ) : (
                      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
                        <BriefcaseIcon className="w-4 h-4 text-gray-400" />
                        <p className="ml-1 text-xs text-gray-500">
                          No assigned deal
                        </p>
                      </div>
                    )}
                  </>
                  <>
                    <DueDatePill dueDate={task.dueDate ?? undefined} />
                  </>
                  <div className="border border-concrete-100 rounded-2xl px-2 flex items-center gap-x-1.5">
                    <ChatBubbleLeftIcon className=" w-4 h-4 text-gray-400" />
                    <p className="text-gray-500 text-sm">
                      {
                        task.activity.filter(
                          (a) => a.type === TaskActivityType.Comment
                        ).length
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        ))}
      </Card>

      <div className="mt-8 flex items-center gap-x-2">
        <CheckCircleIcon className="w-6 h-6 text-green-600" />
        <H3>Completed</H3>
      </div>
      <Card margin="s 0 0 0">
        {completedTasks.length === 0 ? (
          <div className="flex items-center flex-col py-3 justify-center">
            <p className="text-gray-700 text-sm font-semibold">
              No completed tasks
            </p>
          </div>
        ) : null}
        {completedTasks.map((task) => (
          <NavLink key={task.id} to={`/tasks/${task.id}`}>
            <div key={task.id} className="hover:bg-concrete-50 px-3 py-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <TaskPriorityIcon priority={task.priority ?? undefined} />
                  <div>
                    <p className="font-semibold text-sm text-gray-700">
                      {task.title}
                    </p>
                    <p className="text-xs text-gray-500/80">
                      Created by {task.createdBy.firstName}{" "}
                      {formatDistanceToNowStrict(fromUnixTime(task.createdAt), {
                        addSuffix: true,
                      })}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <>
                    {task.assignedTo ? (
                      <UserPillInfo account={task.assignedTo as Account} />
                    ) : (
                      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
                        <UserCircleIcon className="w-4 h-4 text-gray-400" />
                        <p className="ml-1 text-xs text-gray-500">
                          No assignee
                        </p>
                      </div>
                    )}
                  </>
                  <>
                    {task.deal ? (
                      <DealPillCustom deal={task.deal as Deal} />
                    ) : (
                      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
                        <BriefcaseIcon className="w-4 h-4 text-gray-400" />
                        <p className="ml-1 text-xs text-gray-500">
                          No assigned deal
                        </p>
                      </div>
                    )}
                  </>
                  <>
                    <DueDatePill dueDate={task.dueDate ?? undefined} />
                  </>
                  <div className="border border-concrete-100 rounded-2xl px-2 flex items-center gap-x-1.5">
                    <ChatBubbleLeftIcon className=" w-4 h-4 text-gray-400" />
                    <p className="text-gray-500 text-sm">
                      {
                        task.activity.filter(
                          (a) => a.type === TaskActivityType.Comment
                        ).length
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        ))}
      </Card>
    </div>
  );
}

function DealPillCustom(props: { deal: Partial<Deal>; onRemove?: () => void }) {
  const deal = props.deal;
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="cursor-pointer relative flex items-center rounded-2xl border border-gray-100 px-2 py-1">
        <CompanyLogo
          logo={deal.company?.logo}
          withShadow={false}
          borderSize={3}
          size={16}
          name={deal.company ? deal.company.name : ""}
          bgColor={deal.company ? deal.company.logoColor : "#000"}
        />
        <p className="text-xs ml-2 truncate">
          {props.deal.company ? props.deal.company.name : ""}
        </p>
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}

function DueDatePill(props: { dueDate?: number }) {
  if (!props.dueDate) {
    return (
      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
        <CalendarDaysIcon className="w-4 h-4 text-gray-400" />
        <p className="ml-1 text-xs text-gray-500">No due date</p>
      </div>
    );
  }

  const dd = fromUnixTime(props.dueDate);

  if (isBefore(dd, new Date())) {
    return (
      <div className="relative cursor-pointer border border-orange-200 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
        <CalendarDaysIcon className="w-4 h-4 text-orange-400" />
        <p className="ml-1 text-xs text-orange-600">
          Due {format(dd, "dd MMM")}
        </p>
      </div>
    );
  }

  return (
    <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
      <CalendarDaysIcon className="w-4 h-4 text-gray-400" />
      <p className="ml-1 text-xs text-gray-500">Due {format(dd, "dd MMM")}</p>
    </div>
  );
}

function UserPillInfo(props: {
  account: Pick<Account, "id" | "name" | "avatar" | "avatarColor">;
  onRemove?: () => void;
}) {
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="cursor-pointer   relative flex items-center rounded-2xl border border-concrete-100  px-2 py-1">
        <Avatar account={props.account} size="xs" />
        <p className="text-xs ml-2 mt-0.5 truncate">{props.account.name}</p>
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}
