import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DealThread, DealThreadMessage } from "../../graphql/generated";

export type AssistantState = {
  deals: {
    [dealId: string]: DealThread[];
  };
  dealThreads: {
    [dealThreadId: string]: Partial<DealThreadMessage>[];
  };
  activeDealThreadId?: string;
};

const initialState: AssistantState = {
  deals: {},
  dealThreads: {},
};

export const { actions, reducer } = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    setActiveDealThreadId: (state, action: PayloadAction<string>) => {
      state.activeDealThreadId = action.payload;
    },
    updateDealThreadMessageStatus: (
      state,
      action: PayloadAction<{
        dealThreadId: string;
        messageId: string;
        status: DealThreadMessage["status"];
      }>
    ) => {
      const message = state.dealThreads[action.payload.dealThreadId].find(
        (msg) => msg.id === action.payload.messageId
      );

      if (message) {
        message.status = action.payload.status;
      }
    },
    addDealThreadMessage: (
      state,
      action: PayloadAction<{
        dealThreadId: string;
        message: Partial<DealThreadMessage>;
      }>
    ) => {
      if (!state.dealThreads[action.payload.dealThreadId]) {
        state.dealThreads[action.payload.dealThreadId] = [];
      }

      if (
        state.dealThreads[action.payload.dealThreadId].find(
          (msg) => msg.id === action.payload.message.id
        )
      ) {
        state.dealThreads[action.payload.dealThreadId] = state.dealThreads[
          action.payload.dealThreadId
        ].map((msg) =>
          msg.id === action.payload.message.id
            ? { ...msg, ...action.payload.message }
            : msg
        );

        return;
      }

      state.dealThreads[action.payload.dealThreadId].push(
        action.payload.message
      );
    },
    setDeals: (
      state,
      action: PayloadAction<{ dealId: string; threads: DealThread[] }>
    ) => {
      state.deals[action.payload.dealId] = action.payload.threads;
    },
    setDealThreads: (
      state,
      action: PayloadAction<{
        dealThreadId: string;
        messages: DealThreadMessage[];
      }>
    ) => {
      state.dealThreads[action.payload.dealThreadId] = action.payload.messages;
    },
  },
});
