import {
  AllFoldersQuery,
  DataRoomFolder,
  useAllFoldersQuery,
} from "../graphql/generated";
import { FolderIcon } from "@heroicons/react/20/solid";
import { Combobox, Popover, Transition } from "@headlessui/react";
import { useRef, useState } from "react";
import useGqlClient from "../hooks/useGqlClient";
import Loading from "./Loading";
import { classNames } from "../utils/cn";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";

export function AddFolderPill(props: {
  onFolderSelected: (folder: DataRoomFolder) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="relative cursor-pointer  hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1"
      >
        <FolderIcon className="h-5 w-5 text-gray-600/80" />
        <p className="text-xs select-none text-gray-600 ml-1 truncate">
          Select folder
        </p>
        <Transition
          enter="ease-in-out duration-50"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Popover.Panel className="z-20 absolute mt-5 w-96 -translate-x-1/3 transform px-4">
            <PopOut
              onFolderSelected={(folder) => {
                props.onFolderSelected(folder);
                buttonRef.current?.click();
              }}
            />
          </Popover.Panel>
        </Transition>
      </Popover.Button>
    </Popover>
  );
}

const PopOut = ({
  onFolderSelected,
}: {
  onFolderSelected: (folder: DataRoomFolder) => void;
}) => {
  // Adjust the style below based on the exact positioning you need

  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");

  return (
    <div className="border rounded-md ml-10">
      <Combobox
        onChange={(f: DataRoomFolder) => {
          onFolderSelected(f);
        }}
      >
        <div className="">
          <div className="relative w-full cursor-default overflow-hidden rounded-t-md bg-white text-left border-b border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder="Search folders..."
              autoFocus
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>

          <div className="bg-white rounded-b-md">
            <SearchResults searchTerm={query} />
          </div>
        </div>
      </Combobox>
    </div>
  );
};

interface SearchResultsProps {
  searchTerm: string;
}

function SearchResults(props: SearchResultsProps) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const allFolders = useAllFoldersQuery(client, {
    dealId: activeDealId ?? "",
  });

  if (allFolders.isError) {
    return (
      <div className=" bg-white flex justify-center h-10 items-center">
        <p className="text-gray-500 text-xs">Something went wrong</p>
      </div>
    );
  }

  if (allFolders.isLoading || !allFolders.data) {
    return (
      <div className="flex bg-white justify-center h-10 items-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Combobox.Options
        static
        className="max-h-80 scroll-py-2 divide-y  divide-gray-100 overflow-y-auto"
      >
        <li>
          <ul className="text-sm text-gray-700">
            {allFolders.data.deal.dataRoom.allFolders
              .filter((f) =>
                f.name.toLowerCase().includes(props.searchTerm.toLowerCase())
              )
              .map((folder) => {
                return (
                  <FolderContent
                    folder={folder}
                    searchTerm={props.searchTerm}
                    key={folder.id}
                  />
                );
              })}
          </ul>
        </li>
      </Combobox.Options>
    </>
  );
}

function FolderContent(props: {
  folder: AllFoldersQuery["deal"]["dataRoom"]["allFolders"][0];
  searchTerm: string;
}) {
  const folder = props.folder;
  return (
    <Combobox.Option
      key={folder.id}
      value={folder}
      className={({ active }) =>
        classNames(
          "cursor-pointer select-none last:rounded-b-md px-3 py-2",
          active ? "bg-gray-200/70" : ""
        )
      }
    >
      {({ active }) => (
        <div className="">
          <div className="flex items-center">
            <FolderIcon className="h-5 w-5 text-blue-700/70" />
            <p className="ml-1 font-bold leading-tight mt-0.5 text-sm text-left">
              {folder.name === "" ? "Home" : folder.name}
            </p>
          </div>
        </div>
      )}
    </Combobox.Option>
  );
}
