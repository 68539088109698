import { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { H1, H4 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import {
  DataRoomFileFragmentFragment,
  DataRoomFileUploadStatus,
  DealActivityType,
  FileDownloadUrlDocument,
  FileDownloadUrlQuery,
  FileDownloadUrlQueryVariables,
  useDataRoomFileQuery,
  useTrackActivityMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";

import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FileView } from "./FileContent";
import { Tabs } from "./Tabs";
import { FileIcon } from "../../../components/FileIcon";
import {
  DealRoleRestricted,
  adminRoles,
  allRoles,
  sellerRoles,
} from "../../../components/DealRoleRestricted";
import { NewVersionUploader } from "../../../components/data_room/file_uploaders/NewVersionUploader";
import { useDispatch } from "react-redux";
import { actions } from "../../../store/dataRoomFile/slice";
import { saveAs } from "file-saver";
import { Button } from "../../../components/tailwind/Button";
import { toasts } from "../../../components/toasts/toasts";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function DataRoomFile() {
  const { id } = useParams<{ id: string }>();
  const client = useGqlClient();
  const dispatch = useDispatch();

  const query = useQuery();

  const [showFileUploader, setShowFileUploader] = useState(false);

  const trackActivity = useTrackActivityMutation(client);

  const dataRoomFileQuery = useDataRoomFileQuery(
    client,
    {
      id,
    },
    {
      queryKey: ["DataRoomFileView", { id }],
      queryHash: `["DataRoomFileView", {id: ${id}}]`,
      gcTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!dataRoomFileQuery.data) {
      return;
    }

    if (
      dataRoomFileQuery.data.dataRoomFile.uploadStatus ===
      DataRoomFileUploadStatus.Ready
    ) {
      return;
    }

    const intervalId = setInterval(() => {
      dataRoomFileQuery.refetch();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    dataRoomFileQuery,
    dataRoomFileQuery.isLoading,
    dataRoomFileQuery.data,
    dataRoomFileQuery.refetch,
  ]);

  useEffect(() => {
    if (!dataRoomFileQuery.data) {
      return;
    }
    trackActivity.mutate({
      type: DealActivityType.FileViewed,
      fileId: id,
      dataRoomId: "",
      folderId: dataRoomFileQuery.data.dataRoomFile.folderId,
    });

    dispatch(
      actions.setSelectedFileVersionId({
        fileId: id,
        versionId: dataRoomFileQuery.data.dataRoomFile.latestVersion.id,
      })
    );
  }, [id, dataRoomFileQuery.data]);

  if (dataRoomFileQuery.isPending) {
    return <Loading />;
  }

  if (dataRoomFileQuery.error || !dataRoomFileQuery.data) {
    return (
      <div className="w-full">
        <H1>Something went wrong</H1>
      </div>
    );
  }

  const pageIndex = query.get("pageIndex") ?? undefined;
  const rectsOnPage = query.get("rectsOnPage") ?? undefined;

  return (
    <div className="w-full">
      <div className="bg-white sticky top-0 z-20 px-8 py-3 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <Breadcrumbs
              folders={dataRoomFileQuery.data.dataRoomFile.parentFolders}
            />
            <FileIcon
              fileType={dataRoomFileQuery.data.dataRoomFile.fileType}
              size="s"
            />
            <H4>{dataRoomFileQuery.data.dataRoomFile.name}</H4>
          </div>
          {dataRoomFileQuery.data &&
          !dataRoomFileQuery.data.dataRoomFile.deletedAt ? (
            <div className="flex gap-x-2">
              <DealRoleRestricted
                sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
                buyerRunDealRoles={allRoles}
              >
                <Button
                  text="New Version"
                  icon={PlusIcon}
                  size="s"
                  variant="neutral"
                  onClick={() => {
                    setShowFileUploader(true);
                  }}
                />
              </DealRoleRestricted>
              <Button
                text=""
                variant="neutral"
                size="s"
                onClick={() => {
                  client
                    .request<
                      FileDownloadUrlQuery,
                      FileDownloadUrlQueryVariables
                    >(FileDownloadUrlDocument, {
                      id: dataRoomFileQuery.data.dataRoomFile.id,
                    })
                    .then((res) => {
                      const downloadUrl = res.fileDownloadUrl.downloadUrl;
                      fetch(downloadUrl).then(async (res) => {
                        const blob = await res.blob();
                        saveAs(
                          blob,
                          `${dataRoomFileQuery.data.dataRoomFile.name}`
                        );
                      });
                      trackActivity.mutate({
                        type: DealActivityType.FileDownloaded,
                        fileId: id,
                        dataRoomId: "",
                        folderId: dataRoomFileQuery.data.dataRoomFile.folderId,
                      });
                    })
                    .catch((err) => {
                      toasts.error("Failed to download file");
                    });
                }}
                icon={ArrowDownTrayIcon}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="p-8 pt-4 grid grid-cols-1 xl:grid-cols-2 gap-6">
        <div>
          <FileView
            file={dataRoomFileQuery.data.dataRoomFile}
            pageIndex={pageIndex ? parseInt(pageIndex) : undefined}
            rectsOnPage={rectsOnPage ? JSON.parse(rectsOnPage) : undefined}
          />
        </div>

        <div className="mt-4">
          <Tabs
            dataRoomFile={dataRoomFileQuery.data.dataRoomFile}
            type="seller"
          />
        </div>
      </div>

      <NewVersionUploader
        open={showFileUploader}
        fileName={dataRoomFileQuery.data.dataRoomFile.name}
        dataRoomFileId={dataRoomFileQuery.data.dataRoomFile.id}
        onClose={() => {
          setShowFileUploader(false);
        }}
      />
    </div>
  );
}

interface BreadcrumbProps {
  folders: DataRoomFileFragmentFragment["parentFolders"];
}

export function Breadcrumbs(props: BreadcrumbProps) {
  const [parentFolders, setParentFolders] = useState<
    DataRoomFileFragmentFragment["parentFolders"]
  >(props.folders);

  const crumbs: {
    name: string;
    link: string;
  }[] = [
    {
      name: "Home",
      link: `/dataroom/files`,
    },
    ...parentFolders
      .slice(0, parentFolders.length - 1)
      .map((folder) => {
        return {
          name: folder.name,
          link: `/dataroom/files/folder/${folder.id}`,
        };
      })
      .reverse(),
  ];

  return (
    <div className="flex mb-1 items-center gap-x-2">
      {crumbs.map((folder, index) => {
        return (
          <>
            <NavLink to={folder.link} key={folder.link}>
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                {folder.name}
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
          </>
        );
      })}
    </div>
  );
}
