import { classNames } from "../utils/cn";

const bgColors: { [key: string]: string } = {
  green: "bg-green-400/10",
  orange: "bg-orange-400/10",
  gray: "bg-gray-400/10",
};

const textColors = {
  green: "text-green-400",
  orange: "text-orange-400",
  gray: "text-gray-400",
};

export function Pulse(props: {
  color: "green" | "orange" | "gray";
  animate?: boolean;
}) {
  return (
    <div
      className={classNames(
        "flex-none rounded-full p-1",
        bgColors[props.color],
        textColors[props.color],
        props.animate ? "animate-pulse" : ""
      )}
    >
      <div className="h-2 w-2 rounded-full bg-current" />
    </div>
  );
}
