import { XCircleIcon } from "@heroicons/react/20/solid";
import { Account } from "../graphql/generated";
import { Avatar } from "./account/Avatar";

export function UserPill(props: {
  account: Pick<Account, "id" | "name" | "avatar" | "avatarColor">;
  onRemove?: () => void;
}) {
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="cursor-pointer  bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
        <Avatar account={props.account} />
        <p className="text-xs ml-2 mt-0.5 truncate">{props.account.name}</p>
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}
