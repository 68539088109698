import {
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { Card } from "../../components/Card";
import { H4 } from "../../components/Heading";
import { PageContent } from "../../components/PageContent";
import { NavLink } from "react-router-dom";

export function Toolkit() {
  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white sticky z-20 top-0 px-8 py-4 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H4>Toolkit</H4>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <PageContent>
        <ToolkitContent />
      </PageContent>
    </div>
  );
}

const tools = [
  {
    name: "Error checking",
    description: "Check for financial discrepancies across documents",
    icon: ExclamationCircleIcon,
    link: "/toolkit/error-checking",
  },
  {
    name: "Document deep dive",
    description:
      "Summarise and extract key information from a document, view the granular history including changes and activity across versions",
    icon: DocumentTextIcon,
    link: "/toolkit/deep-dive",
  },
  {
    name: "Document search and comparison",
    description:
      "Search for key terms, clauses etc. across documents and compare them side by side",
    icon: DocumentMagnifyingGlassIcon,
    link: "/toolkit/document-search-and-comparison",
  },
];

function ToolkitContent() {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
      {tools.map((tool) => {
        return (
          <NavLink to={tool.link}>
            <Card key={tool.name} padding="m">
              <div className="group cursor-pointer">
                <div className="flex items-center gap-x-1.5">
                  <tool.icon className="h-5 w-5 text-gray-500 group-hover:text-gray-700" />
                  <p className="font-semibold text-sm text-gray-500 group-hover:text-gray-700">
                    {tool.name}
                  </p>
                </div>
                <p className="mt-3 text-xs text-gray-500">{tool.description}</p>
              </div>
            </Card>
          </NavLink>
        );
      })}
    </div>
  );
}
