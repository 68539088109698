import { H3, H4 } from "../../components/Heading";
import useGqlClient from "../../hooks/useGqlClient";
import {
  AccessQuery,
  AccessType,
  DataRoomPermission,
  DealAccessStatus,
  DealFirmGroup,
  DealFirmGroupQuery,
  DealFirmGroupType,
  DealFirmQuery,
  DealFirmType,
  DealRole,
  FileAccessInput,
  FolderAccessInput,
  useAccessQuery,
  useCreateDataFileVersionMutation,
  useCreateDealFirmGroupMutation,
  useCreateDealFirmMutation,
  useCreateDealInviteMutation,
  useDealFirmGroupQuery,
  useDeleteDealInviteMutation,
  DealFirm as GqlDealFirm,
  useUpdateDealFirmGroupMutation,
  useUpdateDealFirmMutation,
  DealInviteStatus,
  DealRunner,
  useS3FileUploadCompleteMutation,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { Card } from "../../components/Card";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import {
  CheckCircleIcon,
  DocumentPlusIcon,
  InformationCircleIcon,
  LockClosedIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { classNames } from "../../utils/cn";
import { Button } from "../../components/tailwind/Button";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { TextInput } from "../../components/tailwind/TextInput";
import { TextArea } from "../../components/tailwind/TextArea";
import {
  formatDistanceToNow,
  formatDistanceToNowStrict,
  fromUnixTime,
} from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { Menu, RadioGroup } from "@headlessui/react";
import { LocalFileIcon } from "../../components/icons/LocalFileIcon";
import { formatFileSize } from "../../utils/formatFileSize";
import { TripleDotMenu } from "../../components/TripleDotMenu";
import { EditDataRoomPermissions } from "./EditDataRoomPermissions";
import { EditDealFirmGroup } from "./EditDealFirmGroup";
import { DealAccountDetails } from "./DealAccount";
import { RemoveGroup } from "./RemoveGroup";
import { RemoveDealFirm } from "./RemoveDealFirm";
import {
  DealRoleRestricted,
  adminRoles,
  buyerAdmins,
  sellSideAdmins,
  sellerRoles,
} from "../../components/DealRoleRestricted";
import { AddPeopleFromFirm, getRoleOptions } from "./AddPeopleFromFirm";
import { InviteRequests } from "./InviteRequests";
import { Pills } from "../../components/Pills";
import { FilePill } from "../../components/FilePill";
import { formatDealRole } from "../../utils/enums";
import { toasts } from "../../components/toasts/toasts";

export function Access() {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const accessQuery = useAccessQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });

  const [openModal, setOpenModal] = useState<
    "" | "firm" | "buyer_group" | "seller_group" | "invite"
  >("");

  const history = useHistory();

  const { groupId } = useParams<{ groupId: string }>();

  const location = useLocation();

  useEffect(() => {
    if (
      !location.pathname.replaceAll("/", "").endsWith("access") &&
      !location.pathname.replaceAll("/", "").endsWith("group")
    ) {
      return;
    }

    if (groupId) {
      history.push(`/deal/access/group/${groupId}`);
      return;
    }

    if (accessQuery.data) {
      if (!accessQuery.data.deal.activeDealAccount.dealFirmGroup) {
        if (
          [...sellerRoles, ...adminRoles].includes(
            accessQuery.data.deal.activeDealAccount.role
          )
        ) {
          history.push(
            `/deal/access/group/${accessQuery.data.deal.sellers[0].id}`
          );
        } else {
          history.push(
            `/deal/access/group/${accessQuery.data.deal.buyers[0].id}`
          );
        }

        return;
      }

      for (const b of accessQuery.data.deal.buyers) {
        if (b.id === accessQuery.data.deal.activeDealAccount.dealFirmGroup.id) {
          history.push(`/deal/access/group/${b.id}`);
          return;
        }
      }

      for (const s of accessQuery.data.deal.sellers) {
        if (s.id === accessQuery.data.deal.activeDealAccount.dealFirmGroup.id) {
          history.push(`/deal/access/group/${s.id}`);
          return;
        }
      }
    }
  }, [accessQuery.data, location.pathname, groupId, history]);

  if (accessQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (accessQuery.isPending || !accessQuery.data) {
    return <Loading />;
  }

  return (
    <div className="flex-1">
      <div className="bg-white sticky top-0 z-20 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex border-b border-gray-200 px-8 py-3 items-center justify-between">
          <div>
            <H3>Access</H3>
          </div>
        </div>
        <DealRoleRestricted
          sellerRunDealRoles={sellSideAdmins}
          buyerRunDealRoles={buyerAdmins}
        >
          <div className="px-8">
            <Tabs />
          </div>
        </DealRoleRestricted>
      </div>
      <div className="p-8">
        <Switch>
          <Route path={`/deal/access/group`}>
            <div className={`flex flex-col xl:flex-row  xl:gap-x-7`}>
              <DealRoleRestricted
                sellerRunDealRoles={sellSideAdmins}
                buyerRunDealRoles={buyerAdmins}
              >
                <div className="xl:w-80 w-full flex flex-col xl:gap-y-4">
                  <div
                    className={`${
                      accessQuery.data.deal.runner === DealRunner.Seller
                        ? "order-1"
                        : "order-2"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <h2 className="font-semibold text-lg text-gray-900">
                        Seller groups
                      </h2>
                      <button
                        onClick={() => {
                          setOpenModal("seller_group");
                        }}
                        className="text-indigo-500 font-semibold text-sm hover:text-indigo-600"
                      >
                        <div className="flex items-center">
                          <PlusIcon className="w-5 h-5 " />
                          <p>Add</p>
                        </div>
                      </button>
                    </div>
                    <div className="mt-2">
                      <Card padding="m">
                        {accessQuery.data.deal.sellers.length === 0 ? (
                          <div>
                            <p className="text-sm text-gray-700 ">
                              No seller groups
                            </p>
                          </div>
                        ) : null}
                        {accessQuery.data.deal.sellers.map((seller, i) => {
                          return (
                            <NavLink
                              key={seller.id}
                              to={`/deal/access/group/${seller.id}`}
                              className={(isActive) => {
                                return classNames(
                                  "px-3 py-1.5 my-1 hover:bg-gray-200 font-semibold rounded-md text-gray-700 flex items-center"
                                );
                              }}
                              activeClassName="bg-gray-200 text-gray-500"
                            >
                              {seller.name}
                            </NavLink>
                          );
                        })}
                      </Card>
                    </div>
                  </div>
                  <div
                    className={`${
                      accessQuery.data.deal.runner === DealRunner.Seller
                        ? "order-2"
                        : "order-1"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <h2 className="font-semibold text-lg text-gray-900">
                        Buyer groups
                      </h2>
                      <button
                        onClick={() => {
                          setOpenModal("buyer_group");
                        }}
                        className="text-indigo-500 font-semibold text-sm hover:text-indigo-600"
                      >
                        <div className="flex items-center">
                          <PlusIcon className="w-5 h-5 " />
                          <p>Add</p>
                        </div>
                      </button>
                    </div>
                    <div className="mt-2">
                      <Card padding="m">
                        {accessQuery.data.deal.buyers.length === 0 ? (
                          <div>
                            <p>No buyers</p>
                          </div>
                        ) : null}
                        {accessQuery.data.deal.buyers.map((buyer, i) => {
                          return (
                            <NavLink
                              key={buyer.id}
                              to={`/deal/access/group/${buyer.id}`}
                              className={(isActive) => {
                                return classNames(
                                  "px-3 my-1 py-1.5  hover:bg-gray-200 font-semibold rounded-md text-gray-700 flex justify-between items-center"
                                );
                              }}
                              activeClassName="bg-gray-200 text-gray-500"
                            >
                              {buyer.name}
                              {buyer.dealAccessStatus !==
                              DealAccessStatus.Granted ? (
                                <LockClosedIcon className="w-4 h-4 ml-1 text-gray-500" />
                              ) : null}
                            </NavLink>
                          );
                        })}
                      </Card>
                    </div>
                  </div>
                </div>
              </DealRoleRestricted>
              <div className="xl:flex-1">
                <Switch>
                  <Route path={`/deal/access/group/:groupId`}>
                    <Group
                      permissions={accessQuery.data.deal.dataRoom.permissions}
                      activeDealAccount={
                        accessQuery.data.deal.activeDealAccount
                      }
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          </Route>
          <Route path={`/deal/access/requests`}>
            <div className="flex-1">
              <InviteRequests />
            </div>
          </Route>
        </Switch>
      </div>
      <CreateGroupModal
        permissions={accessQuery.data.deal.dataRoom.permissions}
        dealId={activeDealId ? activeDealId : ""}
        type={
          openModal === "buyer_group"
            ? DealFirmGroupType.Buyer
            : DealFirmGroupType.Seller
        }
        open={openModal === "buyer_group" || openModal === "seller_group"}
        onClose={() => {
          setOpenModal("");
        }}
        onCreate={(id) => {
          setOpenModal("");
          history.push(`/deal/access/group/${id}`);
          accessQuery.refetch();
        }}
      />
    </div>
  );
}

function isAccountInGroup(
  accountId: string | undefined,
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"]
): boolean {
  if (!accountId) {
    return false;
  }
  return dealFirmGroup.dealFirms.some((df) =>
    df.dealAccounts.some((da) => da.account.id === accountId)
  );
}

function Group(props: {
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  activeDealAccount: AccessQuery["deal"]["activeDealAccount"];
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();

  const deleteDealInvite = useDeleteDealInviteMutation(client);

  const [selectedDataRoomPermission, setSelectedDataRoomPermission] = useState<
    AccessQuery["deal"]["dataRoom"]["permissions"][0]
  >(props.permissions[0]);

  const { groupId } = useParams<{ groupId: string }>();

  const activeDealId = useSelector(authSelectors.activeDealId);

  const account = useSelector(authSelectors.account);

  const [selectedFirmId, setSelectedFirmId] = useState("");

  const { data, isLoading, error, refetch } = useDealFirmGroupQuery(client, {
    id: groupId,
  });

  useEffect(() => {
    if (data) {
      setSelectedDataRoomPermission(data.dealFirmGroup.dataRoomPermission);
    }
  }, [data]);

  const [openModal, setOpenModal] = useState<
    "" | "firm" | "invite" | "edit" | "remove" | "add_people"
  >("");

  useEffect(() => {
    if (data) {
      if (data.dealFirmGroup.dealFirms.length > 0) {
        setSelectedFirmId(data.dealFirmGroup.dealFirms[0].id);
      } else {
        setSelectedFirmId("");
      }
    }
  }, [data, groupId]);

  if (error) {
    return <div>Something went wrong</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div>
      <Card padding="s 0" margin="s 0 0 0">
        <div>
          <div className="px-3 flex items-center justify-between">
            <div>
              <H3>{data.dealFirmGroup.name}</H3>
              <p className="text-gray-500/80 text-xs leading-tight">
                Created{" "}
                {formatDistanceToNow(
                  fromUnixTime(data.dealFirmGroup.createdAt),
                  {
                    addSuffix: true,
                  }
                )}
              </p>
            </div>

            <DealRoleRestricted
              sellerRunDealRoles={sellSideAdmins}
              buyerRunDealRoles={buyerAdmins}
            >
              <TripleDotMenu>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={(e) => {
                        setOpenModal("edit");
                      }}
                      className={classNames(
                        active ? "bg-gray-50 text-indigo-700" : "",
                        "block px-3 py-1 text-sm leading-6 text-indigo-600 cursor-pointer"
                      )}
                    >
                      Edit
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={(e) => {
                        setOpenModal("remove");
                      }}
                      className={classNames(
                        active ? "bg-gray-50 text-red-700" : "",
                        "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                      )}
                    >
                      Remove
                    </div>
                  )}
                </Menu.Item>
              </TripleDotMenu>
            </DealRoleRestricted>
          </div>
          <DealRoleRestricted
            sellerRunDealRoles={sellSideAdmins}
            buyerRunDealRoles={buyerAdmins}
          >
            <div
              className={classNames(
                "mt-3 flex flex-col gap-y-4 xl:gap-x-4 xl:flex-row px-3",
                data.dealFirmGroup.dealFirms.reduce(
                  (acc, curr) => acc + curr.dealAccounts.length,
                  0
                ) === 0
                  ? "opacity-50 hover:opacity-100"
                  : ""
              )}
            >
              <div>
                <p className="block text-sm font-medium text-gray-900">
                  Deal access
                </p>
                <div className="mt-1">
                  <DealAccess dealFirmGroup={data.dealFirmGroup} />
                </div>
              </div>
              <div
                className={classNames(
                  data.dealFirmGroup.dealFirms.reduce(
                    (acc, curr) => acc + curr.dealAccounts.length,
                    0
                  ) > 0 &&
                    data.dealFirmGroup.dealAccessStatus !==
                      DealAccessStatus.Granted
                    ? "opacity-50 hover:opacity-100"
                    : ""
                )}
              >
                <div className="flex items-center justify-between">
                  <p className="text-sm font-mediumtext-gray-900">
                    Data Room permission level
                  </p>
                  <button
                    onClick={() => {
                      setOpenModal("edit");
                    }}
                    className="font-medium hover:text-indigo-800 text-indigo-500 text-sm"
                  >
                    Edit
                  </button>
                </div>
                <div className="mt-1 flex gap-x-3">
                  {props.permissions.map((permission) => {
                    return (
                      <div
                        key={permission.id}
                        className={classNames(
                          "p-2 rounded-md border-2 relative group",
                          data.dealFirmGroup.overrideDataRoomAccess
                            ? "border-gray-300 opacity-60"
                            : "",
                          !data.dealFirmGroup.overrideDataRoomAccess &&
                            permission.id === selectedDataRoomPermission.id
                            ? "border-indigo-500"
                            : "",
                          !data.dealFirmGroup.overrideDataRoomAccess &&
                            permission.accessLevel <
                              selectedDataRoomPermission.accessLevel
                            ? "border-indigo-500 opacity-60"
                            : "",
                          !data.dealFirmGroup.overrideDataRoomAccess &&
                            permission.accessLevel >
                              selectedDataRoomPermission.accessLevel
                            ? "opacity-50 border-gray-300 hover:opacity-100"
                            : ""
                        )}
                      >
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-semibold text-gray-800">
                            {permission.name}
                          </p>
                          {permission.id === selectedDataRoomPermission.id ||
                          permission.accessLevel <
                            selectedDataRoomPermission.accessLevel ? (
                            <CheckCircleIcon
                              className={`w-4 h-4 ${
                                data.dealFirmGroup.overrideDataRoomAccess
                                  ? "text-gray-500"
                                  : "text-indigo-500"
                              }`}
                            />
                          ) : (
                            <CheckCircleIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-50" />
                          )}
                        </div>
                        <p className="leading-tight mt-1 text-xs text-gray-500/80">
                          {permission.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
                {data.dealFirmGroup.overrideDataRoomAccess ? (
                  <div className="mt-1 flex items-center">
                    <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                    <p className="text-sm text-gray-800 mt-0.5 ml-1 font-semibold">
                      Restricted Data Room access
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </DealRoleRestricted>
        </div>
        <div className="mt-8 px-3 flex flex-col xl:flex-row gap-8">
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <H4>People</H4>
              <DealRoleRestricted
                sellerRunDealRoles={sellSideAdmins}
                buyerRunDealRoles={buyerAdmins}
              >
                {isAccountInGroup(account?.id, data.dealFirmGroup) ? (
                  <button
                    onClick={() => {
                      setOpenModal("add_people");
                    }}
                    className="text-indigo-500 font-semibold text-sm hover:text-indigo-600"
                  >
                    <div className="flex items-center">
                      <PlusIcon className="w-5 h-5 " />
                      <p className="mt-0.5">Add from your firm</p>
                    </div>
                  </button>
                ) : null}
              </DealRoleRestricted>
            </div>
            {data.dealFirmGroup.dealFirms.map((dealFirm) => {
              return (
                <DealFirm
                  key={dealFirm.id}
                  activeDealAccount={props.activeDealAccount}
                  dealFirm={dealFirm}
                  firmGroup={data.dealFirmGroup}
                  permissions={props.permissions}
                  dealId={activeDealId ? activeDealId : ""}
                />
              );
            })}
          </div>
          {data.dealFirmGroup.invites.length > 0 ||
          data.dealFirmGroup.dealFirms.reduce(
            (acc, curr) => acc + curr.dealAccounts.length,
            0
          ) > 0 ? (
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <H4>Invites</H4>
                <button
                  onClick={() => {
                    setOpenModal("invite");
                  }}
                  className="text-indigo-500 font-semibold text-sm hover:text-indigo-600"
                >
                  <div className="flex items-center">
                    <PlusIcon className="w-5 h-5 " />
                    <p>Invite</p>
                  </div>
                </button>
              </div>
              {data.dealFirmGroup.invites.length === 0 ? (
                <p className="text-gray-500/80 text-sm">No invites</p>
              ) : null}
              {data.dealFirmGroup.invites.map((invite) => {
                return (
                  <div
                    key={invite.id}
                    className="flex items-center justify-between"
                  >
                    <div>
                      <p className="font-semibold text-sm text-gray-600">
                        {invite.email}
                      </p>
                      <p className="text-sm text-gray-500/80 leading-tight">
                        {invite.firm.name}
                        {` \u2022 `}
                        {invite.status === DealInviteStatus.Approved
                          ? "Invite sent"
                          : "Awaiting deal admin approval"}
                      </p>
                    </div>
                    <div className="flex items-center gap-x-1.5">
                      <p className="text-sm text-gray-400">
                        {formatDealRole(invite.role)}
                      </p>
                      <TripleDotMenu>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={(e) => {
                                deleteDealInvite.mutate(
                                  {
                                    id: invite.id,
                                  },
                                  {
                                    onSuccess: () => {
                                      refetch();
                                      toasts.success("Invite deleted");
                                    },
                                    onError: () => {
                                      toasts.error("Failed to delete invite");
                                    },
                                  }
                                );
                              }}
                              className={classNames(
                                active ? "bg-gray-50 text-red-700" : "",
                                "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                              )}
                            >
                              Delete invite
                            </div>
                          )}
                        </Menu.Item>
                      </TripleDotMenu>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </Card>
      <NewFirmModal
        dealFirmGroup={data.dealFirmGroup}
        permissions={props.permissions}
        dealFirmGroupId={groupId}
        open={openModal === "firm"}
        onClose={() => {
          setOpenModal("");
        }}
        onFirmCreated={() => {
          setOpenModal("");
          refetch();
        }}
      />
      <EditDealFirmGroup
        key={data.dealFirmGroup.id}
        permissions={props.permissions}
        open={openModal === "edit"}
        onClose={() => {
          setOpenModal("");
          refetch();
          queryClient.invalidateQueries({
            queryKey: [
              "Access",
              {
                dealId: activeDealId ? activeDealId : "",
              },
            ],
          });
        }}
        dealFirmGroup={data.dealFirmGroup}
      />

      <DealInviteModal
        dealFirmGroup={data.dealFirmGroup}
        open={openModal === "invite"}
        dealId={activeDealId ?? ""}
        type={data.dealFirmGroup.type}
        onClose={() => {
          setOpenModal("");
        }}
        activeDealAccount={props.activeDealAccount}
        onInviteSent={() => {
          setOpenModal("");
        }}
      />
      <AddPeopleFromFirm
        dealId={activeDealId ?? ""}
        dealFirmGroupId={data.dealFirmGroup.id}
        open={openModal === "add_people"}
        onClose={() => {
          setOpenModal("");
        }}
      />
      <RemoveGroup
        dealFirmGroup={data.dealFirmGroup as DealFirmGroup}
        open={openModal === "remove"}
        onClose={() => {
          setOpenModal("");
        }}
      />
    </div>
  );
}

function DealAccess(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const updateDealFirmGroup = useUpdateDealFirmGroupMutation(client);

  if (
    [DealAccessStatus.Blocked, DealAccessStatus.PendingManualApproval].includes(
      props.dealFirmGroup.dealAccessStatus
    )
  ) {
    return (
      <div className="rounded-md border max-w-xs border-gray-500 px-3 py-2">
        <div className="flex items-center gap-x-1">
          <LockClosedIcon className="w-4 h-4 text-gray-500" />
          <p className="mt-0.5 text-sm font-semibold text-gray-800">
            Access blocked
          </p>
        </div>
        <div>
          {props.dealFirmGroup.dealAccessStatus ===
          DealAccessStatus.PendingManualApproval ? (
            <p className="text-sm text-gray-500/80">
              You need to manually grant{" "}
              <span className="font-medium">{props.dealFirmGroup.name}</span>{" "}
              access to the deal
            </p>
          ) : (
            <p className="text-sm text-gray-500/80">
              You blocked{" "}
              <span className="font-medium">{props.dealFirmGroup.name}</span>{" "}
              from access the deal
            </p>
          )}
        </div>
        <div>
          <div className="flex justify-end mt-2">
            <Button
              text="Grant access"
              variant="positive"
              size="s"
              isLoading={updateDealFirmGroup.isPending}
              loadingText="Granting..."
              onClick={() => {
                updateDealFirmGroup.mutate(
                  {
                    input: {
                      id: props.dealFirmGroup.id,
                      dealAccessStatus: DealAccessStatus.Granted,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ["Access", { dealId: activeDealId ?? "" }],
                      });
                      queryClient.invalidateQueries({
                        queryKey: [
                          "DealFirmGroup",
                          { id: props.dealFirmGroup.id },
                        ],
                      });
                    },
                  }
                );
              }}
            />
          </div>
          {updateDealFirmGroup.error ? (
            <p className="text-right text-red-600 text-sm mt-1">
              Something went wrong.
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  if (props.dealFirmGroup.dealAccessStatus === DealAccessStatus.PendingNda) {
    return (
      <div className="rounded-md border max-w-xs border-gray-500 px-3 py-2">
        <div className="flex items-center gap-x-1">
          <LockClosedIcon className="w-4 h-4 text-gray-500" />
          <p className="text-sm font-semibold text-gray-800">Access blocked</p>
        </div>
        <div>
          <p className="text-sm text-gray-500/80">
            Waiting for{" "}
            <span className="font-medium">{props.dealFirmGroup.name}</span> to
            accept the NDA
          </p>
        </div>
        <div>
          {props.dealFirmGroup.ndaDataRoomFile ? (
            <Pills>
              <FilePill
                id={props.dealFirmGroup.ndaDataRoomFile.id}
                name={props.dealFirmGroup.ndaDataRoomFile.name}
                type={props.dealFirmGroup.ndaDataRoomFile.fileType}
              />
            </Pills>
          ) : null}
        </div>
        <div>
          <div className="flex justify-end mt-2">
            <Button
              text="Grant access"
              variant="positive"
              size="s"
              isLoading={updateDealFirmGroup.isPending}
              loadingText="Granting..."
              onClick={() => {
                updateDealFirmGroup.mutate(
                  {
                    input: {
                      id: props.dealFirmGroup.id,
                      dealAccessStatus: DealAccessStatus.Granted,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ["Access", { dealId: activeDealId ?? "" }],
                      });
                      queryClient.invalidateQueries({
                        queryKey: [
                          "DealFirmGroup",
                          { id: props.dealFirmGroup.id },
                        ],
                      });
                    },
                  }
                );
              }}
            />
          </div>
          {updateDealFirmGroup.error ? (
            <p className="text-right text-red-600 text-sm mt-1">
              Something went wrong.
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="rounded-md border max-w-xs border-gray-500 px-3 py-2">
      <div className="flex items-center gap-x-1">
        <CheckCircleIcon className="w-4 h-4 text-green-500" />
        <p className="text-sm font-semibold text-green-800">Access granted</p>
      </div>
      <div>
        <p className="text-xs text-gray-500/80">
          <span className="font-medium">{props.dealFirmGroup.name}</span> has
          access to the deal
        </p>
      </div>
      {props.dealFirmGroup.ndaDataRoomFile ? (
        <div>
          <Pills>
            <FilePill
              id={props.dealFirmGroup.ndaDataRoomFile.id}
              name={props.dealFirmGroup.ndaDataRoomFile.name}
              type={props.dealFirmGroup.ndaDataRoomFile.fileType}
            />
          </Pills>
          {props.dealFirmGroup.ndaSignedAt &&
          props.dealFirmGroup.ndaSignedBy ? (
            <p className="text-xs mt-1 text-gray-500/80">
              Signed by{" "}
              <span className="font-medium">
                {props.dealFirmGroup.ndaSignedBy.name}
              </span>{" "}
              {formatDistanceToNowStrict(
                fromUnixTime(props.dealFirmGroup.ndaSignedAt),
                {
                  addSuffix: true,
                }
              )}
            </p>
          ) : null}
        </div>
      ) : null}
      {props.dealFirmGroup.type === DealFirmGroupType.Buyer ? (
        <div className="mt-2">
          <div className="flex justify-end">
            <Button
              text="Block"
              variant="neutral"
              size="s"
              isLoading={updateDealFirmGroup.isPending}
              loadingText="Blocking..."
              onClick={() => {
                updateDealFirmGroup.mutate(
                  {
                    input: {
                      id: props.dealFirmGroup.id,
                      dealAccessStatus: DealAccessStatus.Blocked,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ["Access", { dealId: activeDealId ?? "" }],
                      });
                      queryClient.invalidateQueries({
                        queryKey: [
                          "DealFirmGroup",
                          { id: props.dealFirmGroup.id },
                        ],
                      });
                    },
                  }
                );
              }}
            />
          </div>
          {updateDealFirmGroup.error ? (
            <p className="text-red-600 text-sm mt-1">
              Something went wrong. Please try again.
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

function DealFirm(props: {
  firmGroup: DealFirmGroupQuery["dealFirmGroup"];
  dealFirm: DealFirmGroupQuery["dealFirmGroup"]["dealFirms"][0];
  dealId: string;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  activeDealAccount: AccessQuery["deal"]["activeDealAccount"];
}) {
  const [openModal, setOpenModal] = useState<
    "" | "invite" | "edit" | "edit_access" | "edit_account" | "remove"
  >("");

  if (
    props.dealFirm.type === DealFirmType.Extra &&
    props.dealFirm.dealAccounts.length === 0
  ) {
    return null;
  }

  return (
    <div className="mb-6 grid grid-cols-1 xl:grid-cols-1">
      <div className="">
        <div className="flex items-center justify-between">
          <p className="font-semibold text-gray-600 text-sm">
            {props.dealFirm.type === DealFirmType.Extra
              ? props.dealFirm.name
              : ""}
          </p>
          {props.dealFirm.type === DealFirmType.Extra ? (
            <div className="flex items-center">
              <DealRoleRestricted
                sellerRunDealRoles={sellSideAdmins}
                buyerRunDealRoles={buyerAdmins}
              >
                <TripleDotMenu>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          setOpenModal("edit_access");
                        }}
                        className={classNames(
                          active ? "bg-gray-50 text-indigo-700" : "",
                          "block px-3 py-1 text-sm leading-6 text-indigo-600 cursor-pointer"
                        )}
                      >
                        Edit
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          setOpenModal("remove");
                        }}
                        className={classNames(
                          active ? "bg-gray-50 text-red-700" : "",
                          "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                        )}
                      >
                        Remove
                      </div>
                    )}
                  </Menu.Item>
                </TripleDotMenu>
              </DealRoleRestricted>
            </div>
          ) : null}
        </div>
        <div>
          {props.dealFirm.dealAccounts.length === 0 ? (
            <>
              {props.dealFirm.type === DealFirmType.Original &&
              props.dealFirm.invites.length === 0 ? (
                <div className="text-center">
                  <div className="rounded-md px-2 py-3 border border-gray-200">
                    <p className="font-semibold text-gray-600">
                      There's nobody here!
                    </p>
                    <p className="text-sm text-gray-500 leading-tight">
                      Invite someone to{" "}
                      <span className="font-medium">
                        {props.firmGroup.name}
                      </span>
                    </p>
                    <div className="mt-6">
                      <Button
                        onClick={() => {
                          setOpenModal("invite");
                        }}
                        variant="positive"
                        text="Invite someone"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <p className="text-gray-500 mt-2 text-sm">No people here yet</p>
              )}
            </>
          ) : null}
          <div className="flex mt-1 flex-wrap xl:gap-3">
            {props.dealFirm.dealAccounts.map((dealAccount) => {
              return (
                <DealAccountDetails
                  key={dealAccount.id}
                  dealAccount={dealAccount}
                  permissions={props.permissions}
                  firmGroup={props.firmGroup}
                  dealFirmId={props.dealFirm.id}
                />
              );
            })}
          </div>
        </div>
      </div>

      <DealInviteModal
        dealFirmGroup={props.firmGroup}
        open={openModal === "invite"}
        dealId={props.dealId}
        type={props.firmGroup.type}
        onClose={() => {
          setOpenModal("");
        }}
        activeDealAccount={props.activeDealAccount}
        onInviteSent={() => {
          setOpenModal("");
        }}
      />

      <EditSubgroupModal
        dealFirmGroup={props.firmGroup}
        open={openModal === "edit_access"}
        onClose={() => {
          setOpenModal("");
        }}
        dealFirm={props.dealFirm}
        permissions={props.permissions}
        firmGroupType={props.firmGroup.type}
        dealFirmGroupId={props.firmGroup.id}
      />

      <RemoveDealFirm
        dealFirm={props.dealFirm as GqlDealFirm}
        dealFirmGroupId={props.firmGroup.id}
        open={openModal === "remove"}
        onClose={() => {
          setOpenModal("");
        }}
      />
    </div>
  );
}

const tabs = ["Data Room access"];

function EditSubgroupModal(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  dealFirm: DealFirmQuery["dealFirm"];
  dealFirmGroupId: string;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  open: boolean;
  onClose: () => void;
  firmGroupType: DealFirmGroupType;
}) {
  const [name, setName] = useState(props.dealFirm.name);
  const [nameError, setNameError] = useState("");

  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const client = useGqlClient();
  const updateDealFirm = useUpdateDealFirmMutation(client);

  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(
    props.dealFirm.overrideDataRoomAccess
  );
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  return (
    <AnimatedModal
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      size="xxl"
    >
      <div>
        <div className="flex items-center justify-between">
          <H4 margin="0">Edit {props.dealFirm.name}</H4>
          <CloseIcon onClose={props.onClose} />
        </div>
        <div>
          <div className="">
            <div className="mt-3 w-2/3">
              <TextInput
                margin="0"
                label="Name"
                value={name}
                onChange={(e) => {
                  setNameError("");
                  setName(e.currentTarget.value);
                }}
                error={nameError}
              />
            </div>
            <div className="mt-3 border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => {
                      setSelectedTab(tab);
                    }}
                    className={classNames(
                      tab === selectedTab
                        ? "border-gray-600 text-gray-700"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab === selectedTab ? "page" : undefined}
                  >
                    {tab}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <EditDataRoomPermissions
          firmGroupId={props.dealFirmGroup.id}
          initialDataRoomPermission={props.dealFirmGroup.dataRoomPermission}
          firmGroupType={props.firmGroupType}
          showPermissions={true}
          initialGranularAccess={props.dealFirm.granularAccess}
          dataRoomPermissions={props.permissions}
          overrideDataRoomAccess={overrideDataRoomAccess}
          onOverrideDataRoomAccessChange={(checked) => {
            setOverrideDataRoomAccess(checked);
          }}
          granularAccess={granularAccess}
          onGranularAccessChange={(granularAccess) => {
            setGranularAccess(granularAccess);
          }}
        />
        <div className="w-full h-px bg-gray-200 my-4"></div>
        <div className="flex justify-end mt-5">
          <Button
            text="Save"
            variant="positive"
            isLoading={updateDealFirm.isPending}
            loadingText="Saving..."
            onClick={() => {
              if (!name) {
                setNameError("Name is required");
                return;
              }

              updateDealFirm.mutate(
                {
                  input: {
                    id: props.dealFirm.id,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                    name,
                    overrideDataRoomAccess,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["DealFirm", { id: props.dealFirm.id }],
                    });
                    props.onClose();
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}

export interface GranularAccess {
  folders: {
    [key: string]: {
      accessType: AccessType;
      parentFolders: string[];
    };
  };

  files: {
    [key: string]: {
      accessType: AccessType;
      dataRoomPermission: DataRoomPermission;
      folderId: string;
    };
  };
}

export function accessTypeToOption(accessType: AccessType): Option {
  switch (accessType) {
    case AccessType.NoAccess:
      return { label: "No access", value: AccessType.NoAccess };
    case AccessType.View:
      return { label: "View", value: AccessType.View };
    case AccessType.Download:
      return { label: "Download", value: AccessType.Download };
    case AccessType.Write:
      return { label: "Edit", value: AccessType.Write };
  }
}

function NewFirmModal(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  dealFirmGroupId: string;
  onFirmCreated: () => void;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <AnimatedModal
      open={props.open}
      size="xxl"
      onClose={() => {
        props.onClose();
      }}
    >
      <NewFirmModalContent
        dealFirmGroup={props.dealFirmGroup}
        permissions={props.permissions}
        dealFirmGroupId={props.dealFirmGroupId}
        onFirmCreated={props.onFirmCreated}
        open={props.open}
        onClose={props.onClose}
      />
    </AnimatedModal>
  );
}

function NewFirmModalContent(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  dealFirmGroupId: string;
  onFirmCreated: () => void;
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const [dragActive, setDragActive] = useState(false);

  const dealFirmGroupQuery = useDealFirmGroupQuery(client, {
    id: props.dealFirmGroupId,
  });

  const createDealFirm = useCreateDealFirmMutation(client);
  const createDataRoomFileVersion = useCreateDataFileVersionMutation(client);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [error, setError] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const [preNdaDataRoomAccess, setPreNdaDataRoomAccess] = useState(false);

  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(false);
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  const handleDrag = function (e: any) {
    setFileError("");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length > 1) {
        setError("You can only upload one file at a time");
        return;
      }

      setFile(e.dataTransfer.files[0]);
    }
  };

  function clearForm() {
    setName("");
    setFile(null);
    setPreNdaDataRoomAccess(false);
  }

  if (dealFirmGroupQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (dealFirmGroupQuery.isPending || !dealFirmGroupQuery.data) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-lg font-semibold">New sub group</p>
          <p className="text-sm text-gray-500 font-light">
            Add a new sub group to {dealFirmGroupQuery.data.dealFirmGroup.name}
          </p>
        </div>
        <CloseIcon onClose={props.onClose} />
      </div>
      <div className="w-full xl:w-2/3">
        <TextInput
          placeholder="Enter the name of the firm..."
          margin="m 0"
          label="Name"
          value={name}
          onChange={(e) => {
            setNameError("");
            setName(e.currentTarget.value);
          }}
          error={nameError}
        />
      </div>

      <DealRoleRestricted
        sellerRunDealRoles={buyerAdmins}
        buyerRunDealRoles={sellSideAdmins}
      >
        <div className="w-full xl:w-2/3">
          <div className="mt-3 flex items-center justify-between">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              NDA
            </label>
            <span className="text-sm text-gray-500">Optional</span>
          </div>
          {file ? (
            <div
              className={classNames(
                "relative px-2 flex-wrap py-3 gap-x-2 border rounded-md border-gray-300 flex items-center"
              )}
            >
              <LocalFileIcon size="s" fileType={file.type} />
              <div>
                <p className="font-semibold text-xs text-gray-700 truncate">
                  {file.name}
                </p>
                <p className="text-sm text-gray-500 leading-none">
                  {formatFileSize(file.size)}
                </p>
              </div>
              <div className="absolute -top-2 -right-2">
                <CloseIcon
                  onClose={() => {
                    setFile(null);
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div
                className={classNames(
                  "p-2 py-4 gap-x-2 border-2 rounded-md border-dashed border-gray-300 flex items-center justify-center",
                  dragActive ? "border-blue-500 shadow" : "",
                  fileError ? "border-red-500" : ""
                )}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              >
                <p className="text-sm text-gray-500 font-medium">
                  Drop file here or
                </p>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1 text-blue-500 border px-2 py-1 rounded-full font-semibold hover:border-blue-400 hover:shadow text-sm"
                    onClick={() => {
                      setFileError("");
                      if (inputRef.current) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    <DocumentPlusIcon className="w-5 h-5" />
                    <p>Upload</p>
                  </button>
                  <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files) {
                        if (e.target.files.length > 1) {
                          setError("You can only upload one file at a time");
                          return;
                        }

                        setFile(e.target.files[0]);
                      }
                    }}
                    ref={inputRef}
                    type="file"
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              {fileError ? (
                <p className="text-red-500 font-medium mt-2 text-sm">
                  {fileError}
                </p>
              ) : null}
            </>
          )}
        </div>
      </DealRoleRestricted>

      <EditDataRoomPermissions
        firmGroupId={props.dealFirmGroup.id}
        showPermissions={true}
        initialDataRoomPermission={props.dealFirmGroup.dataRoomPermission}
        firmGroupType={props.dealFirmGroup.type}
        initialGranularAccess={{
          files: [],
          folders: [],
        }}
        dataRoomPermissions={props.permissions}
        overrideDataRoomAccess={overrideDataRoomAccess}
        onOverrideDataRoomAccessChange={(checked) => {
          setOverrideDataRoomAccess(checked);
        }}
        granularAccess={granularAccess}
        onGranularAccessChange={(granularAccess) => {
          setGranularAccess(granularAccess);
        }}
      />

      <div className="flex justify-end mt-8 gap-x-3">
        <Button
          onClick={() => {
            if (
              dealFirmGroupQuery.data.dealFirmGroup.type ===
              DealFirmGroupType.Buyer
            ) {
              if (!name) {
                if (!name) {
                  setNameError("Name is required");
                }

                if (!file) {
                  setFileError("NDA is required");
                }
                return;
              }
            } else {
              if (!name) {
                setNameError("Name is required");
                return;
              }
            }

            setError("");

            createDealFirm.mutate(
              {
                input: {
                  name,
                  dealFirmGroupId: props.dealFirmGroupId,
                  dataRoomAccessPreNda: preNdaDataRoomAccess,
                  createNdaFile: file !== null,
                  overrideDataRoomAccess: overrideDataRoomAccess,
                  filePermissions: granularAccessToFileInput(granularAccess),
                  folderPermissions:
                    granularAccessToFolderInput(granularAccess),
                },
              },
              {
                onSuccess: (data) => {
                  if (
                    dealFirmGroupQuery.data.dealFirmGroup.type ===
                      DealFirmGroupType.Buyer &&
                    data.createDealFirm.ndaDataRoomFile &&
                    file
                  ) {
                    createDataRoomFileVersion.mutate(
                      {
                        dataRoomFileID: data.createDealFirm.ndaDataRoomFile.id,
                        fileName: file.name,
                        fileType: file.type,
                        summary: "Initial version",
                      },
                      {
                        onSuccess: (data) => {
                          setUploadingFile(true);
                          fetch(data.createDataRoomFileVersion.url, {
                            method: "PUT",
                            body: file,
                          })
                            .catch((e) => {
                              setUploadingFile(false);
                              setError("Error uploading file");
                            })
                            .then((res) => {
                              setUploadingFile(false);

                              clearForm();
                              props.onFirmCreated();
                            });
                        },
                      }
                    );
                    return;
                  }

                  clearForm();
                  props.onFirmCreated();
                },
                onError: (e) => {
                  setError("Failed to create firm");
                },
              }
            );
          }}
          variant="positive"
          text="Create"
          isLoading={createDealFirm.isPending || uploadingFile}
          loadingText="Creating..."
        />
      </div>
      {error ? (
        <div className="mt-4">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      ) : null}
    </div>
  );
}

function DealInviteModal(props: {
  open: boolean;
  onClose: () => void;
  dealId: string;
  onInviteSent: () => void;
  type: DealFirmGroupType;
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  activeDealAccount: AccessQuery["deal"]["activeDealAccount"];
}) {
  return (
    <AnimatedModal
      open={props.open}
      size="xxl"
      onClose={() => {
        props.onClose();
      }}
    >
      <DealInviteModalContent
        dealFirmGroup={props.dealFirmGroup}
        dealId={props.dealId}
        onInviteSent={props.onInviteSent}
        open={props.open}
        onClose={props.onClose}
        type={props.type}
        activeDealAccount={props.activeDealAccount}
      />
    </AnimatedModal>
  );
}

function DealInviteModalContent(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  dealId: string;
  onInviteSent: () => void;
  open: boolean;
  onClose: () => void;
  type: DealFirmGroupType;
  activeDealAccount: AccessQuery["deal"]["activeDealAccount"];
}) {
  const dealRunner = useSelector(authSelectors.activeDealRunner);
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const [message, setMessage] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteEmailError, setInviteEmailError] = useState("");

  const createDealInvite = useCreateDealInviteMutation(client);

  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(false);
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  const roleOptions = getRoleOptions(
    dealRunner,
    props.activeDealAccount.role,
    props.type
  );
  const [inviteRole, setInviteRole] = useState<Option>(roleOptions[0]);

  const [selectedDealFirm, setSelectedDealFirm] = useState<Option>({
    value: "",
    label: "",
  });

  useEffect(() => {
    if (props.dealFirmGroup.dealFirms.length === 0) {
      return;
    }

    const dealFirm = props.dealFirmGroup.dealFirms.find(
      (dealFirm) => dealFirm.type === DealFirmType.Original
    );

    if (dealFirm) {
      setSelectedDealFirm({
        value: dealFirm.id,
        label: "No grouping",
      });
      return;
    }

    setSelectedDealFirm({
      value: props.dealFirmGroup.dealFirms[0].id,
      label: props.dealFirmGroup.dealFirms[0].name,
    });
  }, [props.dealFirmGroup.id]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-lg font-semibold">Invite</p>
          <p className="text-sm text-gray-500 font-light">
            Invite someone to the deal
          </p>
        </div>
        <CloseIcon onClose={props.onClose} />
      </div>
      <div className="mt-4 gap-x-2">
        <div className="w-full xl:w-2/3">
          <TextInput
            label="Email"
            inputSize="s"
            type="email"
            placeholder="john.smith@liquidacquire.com"
            value={inviteEmail}
            error={inviteEmailError}
            onChange={(e) => {
              setInviteEmailError("");
              setInviteEmail(e.currentTarget.value);
            }}
          />

          <p className="mt-3 block text-sm font-medium leading-6 text-gray-900">
            Deal role
          </p>
          <Dropdown
            size="s"
            onSelect={(o) => {
              setInviteRole(o);
            }}
            options={roleOptions}
            selectedOption={inviteRole}
          />

          <p className="mt-3 block text-sm font-medium leading-6 text-gray-900">
            Group as
          </p>
          <Dropdown
            size="s"
            onSelect={(o) => {
              setSelectedDealFirm(o);
            }}
            options={props.dealFirmGroup.dealFirms.map((dealFirm) => {
              return {
                value: dealFirm.id,
                label:
                  dealFirm.type === DealFirmType.Original
                    ? "No grouping"
                    : dealFirm.name,
              };
            })}
            selectedOption={selectedDealFirm}
          />
          <div className="flex items-center justify-between mt-4">
            <p className="block text-sm font-medium leading-6 text-gray-900">
              Message
            </p>
            <span className="text-sm text-gray-400">Optional</span>
          </div>
          <TextArea
            rows={2}
            name="message"
            value={message}
            onChange={(e) => {
              setMessage(e.currentTarget.value);
            }}
            placeholder="Anything they might need to know?"
          />
        </div>
        <EditDataRoomPermissions
          firmGroupId={props.dealFirmGroup.id}
          initialDataRoomPermission={props.dealFirmGroup.dataRoomPermission}
          firmGroupType={props.dealFirmGroup.type}
          showPermissions={false}
          initialGranularAccess={{
            files: [],
            folders: [],
          }}
          dataRoomPermissions={[]}
          overrideDataRoomAccess={overrideDataRoomAccess}
          onOverrideDataRoomAccessChange={(checked) => {
            setOverrideDataRoomAccess(checked);
          }}
          granularAccess={granularAccess}
          onGranularAccessChange={(granularAccess) => {
            setGranularAccess(granularAccess);
          }}
        />
        <div className="mt-8 flex justify-end">
          <Button
            text="Invite"
            variant="positive"
            isLoading={createDealInvite.isPending}
            onClick={() => {
              if (!inviteEmail) {
                setInviteEmailError("Email is required");
                return;
              }

              createDealInvite.mutate(
                {
                  input: {
                    dealFirmGroupID: props.dealFirmGroup.id,
                    dealFirmID:
                      selectedDealFirm.value ??
                      props.dealFirmGroup.dealFirms[0].id,
                    email: inviteEmail,
                    role: inviteRole.value as DealRole,
                    message,
                    dealID: props.dealId,
                    overrideDataRoomAccess: overrideDataRoomAccess,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: [
                        "DealFirmGroup",
                        { id: props.dealFirmGroup.id },
                      ],
                    });
                    setInviteEmail("");
                    props.onInviteSent();
                  },
                }
              );

              setInviteEmailError("");
            }}
          />
        </div>
      </div>
    </div>
  );
}

function CreateGroupModal(props: {
  dealId: string;
  type: DealFirmGroupType;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  onCreate: (id: string) => void;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <AnimatedModal
      open={props.open}
      size="xxl"
      onClose={() => {
        props.onClose();
      }}
    >
      <CreateGroupModalContent
        dealId={props.dealId}
        type={props.type}
        onCreate={props.onCreate}
        open={props.open}
        onClose={props.onClose}
        permissions={props.permissions}
      />
    </AnimatedModal>
  );
}

const dealAccessOptions = [
  {
    name: "Manually grant access",
    description:
      "You control when the group gets access. Use when running your own NDA process.",
    value: DealAccessStatus.PendingManualApproval,
  },
  {
    name: "Automatically grant access*",
    description:
      "Upload an NDA and the group gets access immediately after accepting.",
    value: DealAccessStatus.PendingNda,
  },
  {
    name: "No NDA",
    description: "The group can access without signing an NDA.",
    value: DealAccessStatus.Granted,
  },
];

function CreateGroupModalContent(props: {
  dealId: string;
  type: DealFirmGroupType;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  onCreate: (id: string) => void;
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [error, setError] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  const createDealFirmGroup = useCreateDealFirmGroupMutation(client);
  const createDataRoomFileVersion = useCreateDataFileVersionMutation(client);

  const fileUploadComplete = useS3FileUploadCompleteMutation(client);

  const [dealAccessOption, setDealAccessOption] = useState(
    dealAccessOptions[0]
  );

  const [selectedDataRoomPermission, setSelectedDataRoomPermission] = useState(
    props.permissions[0]
  );
  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(false);
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  const handleDrag = function (e: any) {
    setFileError("");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length > 1) {
        setError("You can only upload one file at a time");
        return;
      }

      setFile(e.dataTransfer.files[0]);
    }
  };

  function clearForm() {
    setName("");
    setFile(null);
    setOverrideDataRoomAccess(false);
    setDealAccessOption(dealAccessOptions[0]);
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-lg font-semibold">New group</p>
          <p className="text-sm text-gray-500 font-light">
            Create a{" "}
            {props.type === DealFirmGroupType.Buyer ? "buyer" : "seller"} group
          </p>
        </div>
        <CloseIcon
          onClose={() => {
            clearForm();
            props.onClose();
          }}
        />
      </div>
      <div className=" mt-4 gap-x-2">
        <div className="w-full xl:w-2/3">
          <TextInput
            label="Name"
            inputSize="s"
            placeholder="Enter the name of the group..."
            value={name}
            error={nameError}
            onChange={(e) => {
              setNameError("");
              setName(e.currentTarget.value);
            }}
          />
        </div>

        <div className="mt-4">
          <p className="font-semibold text-gray-600">Deal Access</p>
          <p className="text-sm text-gray-500 leading-tight">
            Control when this group can access the deal
          </p>
        </div>
        <RadioGroup
          className="mt-2"
          value={dealAccessOption}
          onChange={setDealAccessOption}
        >
          <RadioGroup.Label className="sr-only">
            Privacy setting
          </RadioGroup.Label>
          <div className="-space-y-px rounded-md bg-white">
            {dealAccessOptions.map((dao, settingIdx) => (
              <RadioGroup.Option
                key={dao.name}
                value={dao}
                className={({ checked }) =>
                  classNames(
                    settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                    settingIdx === dealAccessOptions.length - 1
                      ? "rounded-bl-md rounded-br-md"
                      : "",
                    checked
                      ? "z-10 border-indigo-200 bg-indigo-50"
                      : "border-gray-200",
                    "relative flex cursor-pointer border p-4 focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span
                      className={classNames(
                        checked
                          ? "bg-indigo-600 border-transparent"
                          : "bg-white border-gray-300",
                        active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                        "mt-1 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="ml-3 flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-indigo-900" : "text-gray-900",
                          "block text-sm font-medium"
                        )}
                      >
                        {dao.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={classNames(
                          checked ? "text-indigo-700" : "text-gray-500",
                          "block text-sm"
                        )}
                      >
                        {dao.description}
                      </RadioGroup.Description>
                    </span>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        <p className="text-gray-500/80 mt-2 text-sm">
          * Liquid does not currently support e-signatures. The NDA can't be
          officially signed via Liquid
        </p>

        {dealAccessOption.value === DealAccessStatus.PendingNda ? (
          <div className="w-full xl:w-2/3">
            <div className="mt-3 flex items-center justify-between">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                NDA
              </label>
            </div>
            {file ? (
              <div
                className={classNames(
                  "relative px-2 flex-wrap py-3 gap-x-2 border rounded-md border-gray-300 flex items-center"
                )}
              >
                <LocalFileIcon fileType={file.type} />
                <div>
                  <p className="font-semibold text-sm text-gray-700 truncate">
                    {file.name}
                  </p>
                  <p className="text-xs text-gray-500 leading-none">
                    {formatFileSize(file.size)}
                  </p>
                </div>
                <div className="absolute -top-2 -right-2">
                  <CloseIcon
                    onClose={() => {
                      setFile(null);
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  className={classNames(
                    "p-2 py-4 gap-x-2 border-2 rounded-md border-dashed border-gray-300 flex items-center justify-center",
                    dragActive ? "border-blue-500 shadow" : "",
                    fileError ? "border-red-500" : ""
                  )}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <p className="text-sm text-gray-500 font-medium">
                    Drop file here or
                  </p>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1 text-blue-500 border px-2 py-1 rounded-full font-semibold hover:border-blue-400 hover:shadow text-sm"
                      onClick={() => {
                        setFileError("");
                        if (inputRef.current) {
                          inputRef.current.click();
                        }
                      }}
                    >
                      <DocumentPlusIcon className="w-5 h-5" />
                      <p>Upload</p>
                    </button>
                    <input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                          if (e.target.files.length > 1) {
                            setError("You can only upload one file at a time");
                            return;
                          }

                          setFile(e.target.files[0]);
                        }
                      }}
                      ref={inputRef}
                      type="file"
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                {fileError ? (
                  <p className="text-red-500 font-medium mt-2 text-sm">
                    {fileError}
                  </p>
                ) : null}
              </>
            )}
          </div>
        ) : null}

        <div className="mt-4">
          <p className="font-semibold text-gray-600">Data Room access</p>
          <p className="font-semibold text-gray-600 text-sm">
            Permission level
          </p>
          <p className="text-sm text-gray-500 leading-tight">
            This is set at the group level
          </p>
          <div className="mt-2 flex gap-x-3">
            {props.permissions.map((permission) => {
              return (
                <button
                  key={permission.id}
                  onClick={() => {
                    setSelectedDataRoomPermission(permission);
                  }}
                  className={classNames(
                    "p-2 rounded-md border-2 relative  group",
                    overrideDataRoomAccess ? "border-gray-300 opacity-60" : "",
                    !overrideDataRoomAccess &&
                      permission.id === selectedDataRoomPermission.id
                      ? "border-indigo-500"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel <
                        selectedDataRoomPermission.accessLevel
                      ? "border-indigo-500 opacity-60"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel >
                        selectedDataRoomPermission.accessLevel
                      ? "opacity-50 "
                      : ""
                  )}
                >
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-semibold text-gray-800">
                      {permission.name}
                    </p>
                    {permission.id === selectedDataRoomPermission.id ||
                    permission.accessLevel <
                      selectedDataRoomPermission.accessLevel ? (
                      <CheckCircleIcon
                        className={`w-4 h-4 ${
                          overrideDataRoomAccess
                            ? "text-gray-500"
                            : "text-indigo-500"
                        }`}
                      />
                    ) : null}
                  </div>
                  <p className="leading-tight mt-1 text-sm text-gray-500">
                    {permission.description}
                  </p>
                </button>
              );
            })}
          </div>
        </div>

        <EditDataRoomPermissions
          firmGroupId=""
          showPermissions={false}
          initialDataRoomPermission={props.permissions[0]}
          firmGroupType={props.type}
          initialGranularAccess={{
            files: [],
            folders: [],
          }}
          dataRoomPermissions={props.permissions}
          overrideDataRoomAccess={overrideDataRoomAccess}
          onOverrideDataRoomAccessChange={(checked) => {
            setOverrideDataRoomAccess(checked);
          }}
          granularAccess={granularAccess}
          onGranularAccessChange={(granularAccess) => {
            setGranularAccess(granularAccess);
          }}
        />

        <div className="mt-8 flex justify-end">
          <Button
            text="Create"
            variant="positive"
            isLoading={
              createDealFirmGroup.isPending ||
              createDataRoomFileVersion.isPending ||
              uploadingFile
            }
            onClick={() => {
              if (!name) {
                setNameError("Name is required");
                return;
              }

              if (
                dealAccessOption.value === DealAccessStatus.PendingNda &&
                !file
              ) {
                setFileError("NDA is required");
                return;
              }

              createDealFirmGroup.mutate(
                {
                  input: {
                    dealId: props.dealId,
                    name,
                    type: props.type,
                    overrideDataRoomAccess: overrideDataRoomAccess,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                    dealAccessStatus: dealAccessOption.value,
                    dataRoomPermissionId: selectedDataRoomPermission.id,
                    createNdaFile:
                      dealAccessOption.value === DealAccessStatus.PendingNda,
                  },
                },
                {
                  onSuccess: (data) => {
                    const createFirmGroupData = data;
                    if (data.createDealFirmGroup.ndaDataRoomFile && file) {
                      createDataRoomFileVersion.mutate(
                        {
                          dataRoomFileID:
                            data.createDealFirmGroup.ndaDataRoomFile.id,
                          fileName: file.name,
                          fileType: file.type,
                          summary: "Initial version",
                        },
                        {
                          onSuccess: (d) => {
                            setUploadingFile(true);
                            fetch(d.createDataRoomFileVersion.url, {
                              method: "PUT",
                              body: file,
                            })
                              .catch((e) => {
                                setUploadingFile(false);
                                setError("Error uploading file");
                              })
                              .then((res) => {
                                fileUploadComplete.mutate(
                                  {
                                    dataRoomFileID:
                                      data.createDealFirmGroup.ndaDataRoomFile!
                                        .id,
                                    dataRoomFileVersionID:
                                      d.createDataRoomFileVersion
                                        .dataRoomFileVersionID,
                                  },
                                  {
                                    onSuccess: () => {
                                      setUploadingFile(false);

                                      clearForm();
                                      props.onCreate(
                                        createFirmGroupData.createDealFirmGroup
                                          .id
                                      );
                                    },
                                  }
                                );
                              });
                          },
                        }
                      );
                    }

                    props.onCreate(data.createDealFirmGroup.id);
                    clearForm();
                  },
                  onError: () => {
                    setError("Failed to create group");
                  },
                }
              );

              setNameError("");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function granularAccessToFileInput(
  granularAccess: GranularAccess
): Array<FileAccessInput> {
  // recurse through all child folders and append to array
  let files: FileAccessInput[] = [];

  Object.keys(granularAccess.files).forEach((fileId) => {
    files.push({
      fileId,
      type: granularAccess.files[fileId].accessType,
    });
  });

  return files;
}

export function granularAccessToFolderInput(
  granularAccess: GranularAccess
): Array<FolderAccessInput> {
  let folders: FolderAccessInput[] = [];

  Object.keys(granularAccess.folders).forEach((folderId) => {
    folders.push({
      folderId,
      type: granularAccess.folders[folderId].accessType,
    });
  });

  return folders;
}

const navTabs = [
  {
    name: "Groups",
    link: "/deal/access/group",
  },
  {
    name: "Requests",
    link: "/deal/access/requests",
  },
];

function Tabs() {
  const history = useHistory();
  return (
    <div className="flex">
      <div className="-my-px sm:flex sm:space-x-8">
        {navTabs.map((tab) => (
          <NavLink
            key={tab.name}
            to={tab.link}
            className={(isActive) =>
              classNames(
                isActive
                  ? ""
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
              )
            }
            activeClassName={classNames("border-indigo-500 text-indigo-600")}
          >
            {tab.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
}
