import { useSelector } from "react-redux";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import {
  DealInviteStatus,
  DealQuery,
  useApproveDealInviteMutation,
  useDealQuery,
  useDeleteDealInviteMutation,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { H3, H4 } from "../../components/Heading";
import { Card } from "../../components/Card";
import { Button } from "../../components/tailwind/Button";
import { useQueryClient } from "@tanstack/react-query";

export function InviteRequests() {
  const activeDealId = useSelector(authSelectors.activeDealId);

  const client = useGqlClient();
  const dealQuery = useDealQuery(client, {
    id: activeDealId ? activeDealId : "",
  });

  if (dealQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (dealQuery.isPending || !dealQuery.data) {
    return <Loading />;
  }

  const groups: { [key: string]: DealQuery["deal"]["invites"] } = {};

  dealQuery.data.deal.invites.forEach((invite) => {
    if (invite.status !== DealInviteStatus.Requested) {
      return;
    }

    if (!groups[invite.firm.dealFirmGroup.name]) {
      groups[invite.firm.dealFirmGroup.name] = [];
    }

    groups[invite.firm.dealFirmGroup.name].push(invite);
  });

  return (
    <div>
      <div>
        <H3>Requests</H3>
        <p className="text-gray-500/80 leading-tight">
          Buyers requesting people to be added to the deal
        </p>
      </div>
      <div className="flex mt-3">
        {dealQuery.data.deal.invites.filter(
          (invite) => invite.status === DealInviteStatus.Requested
        ).length === 0 ? (
          <p>No requests</p>
        ) : null}
        {Object.keys(groups).map((groupName) => {
          return (
            <div key={groupName}>
              <H4>{groupName}</H4>
              {groups[groupName].map((invite) => {
                return <InviteRequest invite={invite} key={invite.id} />;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function InviteRequest(props: { invite: DealQuery["deal"]["invites"][0] }) {
  const invite = props.invite;
  const client = useGqlClient();
  const approveInvite = useApproveDealInviteMutation(client);
  const deleteDealInvite = useDeleteDealInviteMutation(client);
  const queryClient = useQueryClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  return (
    <Card key={invite.id} padding="m">
      <div>
        <p className="text-sm text-gray-500">
          {invite.invitedBy.name}{" "}
          <span className="font-medium">({invite.invitedBy.email})</span> wants
          to add{" "}
        </p>
        <p className="text-gray-700 font-semibold mt-1">{invite.email}</p>
      </div>
      <div className="mt-3 flex justify-end gap-x-4">
        <button
          className="text-red-600 hover:text-red-700 font-medium"
          onClick={() => {
            deleteDealInvite.mutate(
              {
                id: invite.id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: [
                      "Deal",
                      { id: activeDealId ? activeDealId : "" },
                    ],
                  });
                },
              }
            );
          }}
        >
          Decline
        </button>
        <Button
          size="s"
          variant="positive"
          text="Approve"
          isLoading={approveInvite.isPending}
          loadingText="Approve..."
          onClick={() => {
            approveInvite.mutate(
              {
                id: invite.id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: [
                      "Deal",
                      { id: activeDealId ? activeDealId : "" },
                    ],
                  });
                },
              }
            );
          }}
        />
      </div>
    </Card>
  );
}
