import { add, fromUnixTime, getUnixTime, sub } from "date-fns";
import { H3 } from "../../components/Heading";
import React, { useEffect, useRef, useState } from "react";

import { CanvasRenderer } from "echarts/renderers";
import { init, getInstanceByDom, use } from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  LegendComponent,
  GridComponent,
  TooltipComponent,
} from "echarts/components";
import type { ComposeOption, SetOptionOpts } from "echarts/core";
import type { GridComponentOption } from "echarts/components";
import { CSSProperties } from "styled-components";

import { useTheme } from "../../hooks/useTheme";
import { BarSeriesOption } from "echarts";
import { Card } from "../../components/Card";
import {
  DealActivityFragmentFragment,
  DataRoomActivityMetrics,
  useDataRoomActivityMetricsQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import Loading from "../../components/Loading";
import { useHistory, useLocation } from "react-router-dom";
import { ActivityItem } from "../../components/activity/ActivityItem";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Activity() {
  const client = useGqlClient();
  const activeDeal = useSelector(authSelectors.activeDeal);

  const activeDealId = useSelector(authSelectors.activeDealId);

  const [startDate, setStartDate] = useState<number>(
    getUnixTime(
      activeDeal
        ? fromUnixTime(activeDeal.createdAt)
        : sub(new Date(), { days: 120 })
    )
  );
  const [endDate, setEndDate] = useState<number>(
    getUnixTime(add(new Date(), { days: 0 }))
  );

  const [accountId, setAccountId] = useState<string | null>(null);
  const [fileId, setFileId] = useState<string | null>(null);

  const [selectedAccount, setSelectedAccount] = useState<Option>({
    value: null,
    label: "Everyone",
    description: "",
  });

  const [selectedFile, setSelectedFile] = useState<Option>({
    value: null,
    label: "All files",
    description: "",
  });

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const accountId = query.get("accountId");

    if (accountId) {
      setAccountId(accountId);
    }

    const fileId = query.get("fileId");
    if (fileId) {
      setFileId(fileId);
    }
  }, [query]);

  const { data, isLoading, error } = useDataRoomActivityMetricsQuery(client, {
    dealId: activeDealId ? activeDealId : "",
    input: {
      startDate,
      endDate,
      accountID: accountId,
      dataRoomFileID: fileId,
    },
  });

  useEffect(() => {
    if (accountId === null) {
      setSelectedAccount({
        value: null,
        label: "Everyone",
        description: "",
      });
      return;
    }

    if (data) {
      const account = data.deal.dealAccounts.find(
        (da) => da.account.id === accountId
      );

      if (account) {
        setSelectedAccount({
          value: account.account.id,
          label: account.account.name,
          description: "",
        });
      }
    }
  }, [accountId, data]);

  useEffect(() => {
    if (fileId === null) {
      setSelectedFile({
        value: null,
        label: "All files",
        description: "",
      });
      return;
    }

    if (data) {
      const file = data.deal.dataRoom.allFiles.find((f) => f.id === fileId);

      if (file) {
        setSelectedFile({
          value: file.id,
          label: file.name,
          description: "",
        });
      }
    }
  }, [fileId, data]);

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className="flex-1">
      <div className="bg-white px-8 py-6 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Activity</H3>
            <p className="text-sm text-gray-500 leading-none">
              Filter by files/people to see their activity
            </p>
          </div>
          <div className="flex space-x-2 items-center">
            <Dropdown
              options={[
                { label: "Everyone", value: null, description: "" },
                ...data.deal.dealAccounts.map((ca) => {
                  return {
                    label: ca.account.name,
                    value: ca.account.id,
                    description: "",
                  };
                }),
              ]}
              selectedOption={selectedAccount}
              onSelect={(option) => {
                if (option.value === null) {
                  const params = query;
                  params.delete("accountId");

                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });

                  setAccountId(null);

                  return;
                }

                const params = query;
                params.set("accountId", option.value);
                history.replace({
                  pathname: location.pathname,
                  search: params.toString(),
                });
              }}
            />
            <Dropdown
              options={[
                { label: "All files", value: null, description: "" },
                ...data.deal.dataRoom.allFiles.map((f) => {
                  return {
                    label: f.name,
                    value: f.id,
                    description: "",
                  };
                }),
              ]}
              selectedOption={selectedFile}
              onSelect={(option) => {
                if (option.value === null) {
                  const params = query;
                  params.delete("fileId");

                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });

                  setFileId(null);

                  return;
                }

                const params = query;
                params.set("fileId", option.value);
                history.replace({
                  pathname: location.pathname,
                  search: params.toString(),
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-8">
        <Card margin="l 0 0 0">
          <Chart data={data ? data.dataRoomActivityMetrics.metrics : []} />
        </Card>

        <div className="grid grid-cols-1 xl:grid-cols-2">
          <div>
            <H3 margin="xxl 0 0 0">Activity</H3>
            <ActivityItems activity={data.dataRoomActivityMetrics.activity} />
          </div>
        </div>
      </div>
    </div>
  );
}

export interface ChartData {
  label: string;
  lineColor: "primary" | "secondary";
  data: number[][];
}

interface Props {
  data: DataRoomActivityMetrics[];
  name?: string;
}

const WrappedCharts = (props: Props) => {
  const theme = useTheme();

  if (props.data.length === 0) {
    return null;
  }

  const option: ReactEChartsProps["option"] = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "1%",
      top: "15%",
      right: "5%",
      bottom: "5%",
      containLabel: true,
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      data: props.data.map((d) => d.key),
      axisTick: {
        show: false,
      },

      axisLabel: {
        fontSize: 12,
        color: theme.color.typography.secondary,
        fontWeight: "bold",
        padding: 4,
      },
    },
    yAxis: {
      type: "value",
      show: false,
      axisLabel: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#788AA5",
      },
      splitLine: {
        show: false,
      },
    },
    legend: {
      data: ["Views", "Downloads"],
      top: 0,
      icon: "circle",
    },
    series: [
      {
        data: props.data.map((d) => d.views),
        label: {
          show: false,
        },
        name: "Views",
        type: "bar",
        color: "#6f07fa",
      },
      {
        data: props.data.map((d) => d.downloads),
        label: {
          show: false,
        },
        name: "Downloads",
        type: "bar",
        color: "#dcdcdc",
      },
    ],
  };
  return <ReactECharts option={option} />;
};

const Chart = React.memo(WrappedCharts);

// Register the required components
use([
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  CanvasRenderer, // If you only need to use the canvas rendering mode, the bundle will not include the SVGRenderer module, which is not needed.
]);

// Combine an Option type with only required components and charts via ComposeOption
export type EChartsOption = ComposeOption<
  BarSeriesOption | GridComponentOption
>;

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
}

export const ReactECharts = ({
  option,
  style,
  settings,
  theme,
}: ReactEChartsProps): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    if (chartRef.current !== null) {
      init(chartRef.current, theme);
    }
  }, [theme]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  return (
    <div>
      <div
        ref={chartRef}
        style={{ width: "100%", height: "220px", ...style }}
      />
    </div>
  );
};

interface ActivityItemsProps {
  activity: DealActivityFragmentFragment[];
}

function ActivityItems(props: ActivityItemsProps) {
  return (
    <div className="mt-3">
      <ul role="list" className="space-y-6">
        {props.activity.map((activity, i) => {
          return (
            <ActivityItem
              key={activity.id}
              activity={activity}
              isFirst={i === 0}
              isLast={i === props.activity.length - 1}
            />
          );
        })}
      </ul>
    </div>
  );
}
