import { useQueryClient } from "@tanstack/react-query";
import useGqlClient from "../../hooks/useGqlClient";
import { H3 } from "../../components/Heading";
import { useDealQuery } from "../../graphql/generated";
import { authSelectors } from "../../store/auth/selector";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { Avatar } from "../../components/account/Avatar";
import { NavLink } from "react-router-dom";
import { classNames } from "../../utils/cn";
import { DealProgress } from "../../components/DealProgress";

export function Buyers() {
  const activeDealId = useSelector(authSelectors.activeDealId);

  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white sticky top-0 z-20 px-8 py-5 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Buyers</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <div className="p-8 flex-1 flex">
        {activeDealId ? <BuyersContent dealId={activeDealId} /> : null}
      </div>
    </div>
  );
}

function BuyersContent(props: { dealId: string }) {
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const dealQuery = useDealQuery(client, {
    id: props.dealId,
  });

  if (dealQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (dealQuery.isPending || !dealQuery.data) {
    return <Loading />;
  }

  if (dealQuery.data.deal.buyers.length === 0) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <div className="text-center">
          <p className="font-semibold text-gray-700">No buyers</p>
          <p className="text-gray-500/80 text-sm">
            Buyers added to the deal will appear here
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full xl:w-1/2">
      <Card>
        {dealQuery.data.deal.buyers.map((buyer, i) => {
          const dealAccounts = buyer.dealFirms.flatMap(
            (dealFirm) => dealFirm.dealAccounts
          );

          return (
            <NavLink key={buyer.id} to={`/buyers/${buyer.id}`}>
              <div
                className={classNames(
                  "p-3 hover:bg-gray-50",
                  i === 0 ? "rounded-t-md" : "",
                  i === dealQuery.data.deal.buyers.length - 1
                    ? "rounded-b-md"
                    : ""
                )}
              >
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-gray-700">{buyer.name}</p>
                  <div className="-space-x-0.5">
                    {dealAccounts.length === 0 ? (
                      <p className="text-gray-500 text-xs">No accounts</p>
                    ) : null}
                    {dealAccounts.map((dealAccount) => {
                      return <Avatar account={dealAccount.account} size="m" />;
                    })}
                  </div>
                </div>
                <div className="mt-3 w-2/3 flex space-x-0.5">
                  <DealProgress
                    mode="compact"
                    dealStages={dealQuery.data.deal.dealStages}
                    currentDealStageStatus={buyer.currentDealStageStatus}
                    currentDealStage={buyer.currentDealStage}
                  />
                </div>
              </div>
            </NavLink>
          );
        })}
      </Card>
    </div>
  );
}
