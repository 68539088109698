import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "./auth/slice";
import { reducer as assistantReducer } from "./assistant/slice";
import { reducer as dataRoomFileReducer } from "./dataRoomFile/slice";
import { reducer as deepDiveReducer } from "./deep-dive/slice";
import { reducer as presenceReducer } from "./presence/slice";
import { reducer as deepSearchReducer } from "./deep-search/slice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: [
    "accessToken",
    "isLoggedIn",
    "account",
    "activeDealId",
    "dealsLastSeen",
  ],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  assistant: assistantReducer,
  dataRoomFile: dataRoomFileReducer,
  presence: presenceReducer,
  deepDive: deepDiveReducer,
  deepSearch: deepSearchReducer,
});

export default rootReducer;
