import {
  format,
  formatDistance,
  formatISO,
  fromUnixTime,
  secondsToMilliseconds,
} from "date-fns";
import { Link } from "react-router-dom";
import {
  DealActivityFragmentFragment,
  DealActivityType,
} from "../../graphql/generated";
import { formatActivityType } from "../../utils/enums";
import { classNames } from "../../utils/cn";
import { FilePill } from "../FilePill";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  EyeIcon,
  FolderIcon,
  NoSymbolIcon,
} from "@heroicons/react/20/solid";
import { Avatar } from "../account/Avatar";
import { Pills } from "../Pills";
import {
  DocumentPlusIcon,
  ExclamationTriangleIcon,
  FolderPlusIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export type ActivityItemMode = "compact" | "verbose";

interface ActivityItemProps {
  activity: DealActivityFragmentFragment;
  isFirst: boolean;
  isLast: boolean;
  mode?: ActivityItemMode;
}

export function ActivityItem(props: ActivityItemProps) {
  if (props.activity.type === DealActivityType.FileCreated) {
    if (!props.activity.file) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <DocumentPlusIcon className="w-5 h-5 text-gray-500/60" />
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="flex items-center  gap-x-2 -py-0.5">
              <p className=" text-sm leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                created a new file
              </p>
              <FilePill
                id={props.activity.file.id}
                name={props.activity.file.name}
                type={props.activity.file.fileType}
              />
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt)
              )}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.FileDeleted) {
    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <TrashIcon className="w-5 h-5 text-gray-500/60" />
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="flex items-center  gap-x-2 -py-0.5">
              <p className=" text-sm leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                deleted {props.activity.file ? "" : "a file"}
              </p>
              {props.activity.file ? (
                <FilePill
                  id={props.activity.file.id}
                  name={props.activity.file.name}
                  type={props.activity.file.fileType}
                />
              ) : null}
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt)
              )}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.FolderCreated) {
    if (!props.activity.folder) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <FolderPlusIcon className="w-5 h-5 text-gray-500/90" />
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="flex items-center  gap-x-2 -py-0.5">
              <p className=" text-sm leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                created a new folder
              </p>

              <Link to={`/dataroom/files/folder/${props.activity.folder.id}`}>
                <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
                  <FolderIcon
                    className={classNames(
                      props.activity.folder.dealFirmGroup
                        ? "text-orange-800/70"
                        : "text-blue-700/70",
                      "w-4 h-4"
                    )}
                  />
                  <p className="text-xs ml-1 truncate">
                    {props.activity.folder.name}
                  </p>
                </div>
              </Link>
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt)
              )}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.FileVersionCreated) {
    if (!props.activity.file) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="flex items-center  gap-x-2 -py-0.5">
              <p className=" text-sm leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                uploaded a new version of
              </p>
              <FilePill
                id={props.activity.file.id}
                name={props.activity.file.name}
                type={props.activity.file.fileType}
              />
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt)
              )}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.FileDownloaded) {
    if (!props.activity.file) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <ArrowDownTrayIcon className="w-4 h-4 text-gray-500/80" />
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="flex items-center  gap-x-2 ">
              <p className=" text-sm leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                downloaded
              </p>
              <FilePill
                id={props.activity.file.id}
                name={props.activity.file.name}
                type={props.activity.file.fileType}
              />
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt)
              )}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.QuestionAsked) {
    if (!props.activity.question) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          {props.mode === "verbose" ? (
            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
              <QuestionMarkCircleIcon className="w-5 h-5 text-gray-500/90" />
            </div>
          ) : (
            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            </div>
          )}

          {props.mode === "verbose" ? (
            <Link
              className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 bg-white/50"
              to={`/questions/${props.activity.question.id}`}
            >
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  asked a new question
                </div>
                <time
                  title={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  dateTime={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {formatDistance(
                    secondsToMilliseconds(props.activity.createdAt),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                </time>
              </div>
              <p className="text-sm leading-6 text-gray-700 font-medium">
                {props.activity.question.title}
              </p>
            </Link>
          ) : (
            <Link
              className="w-full"
              to={`/questions/${props.activity.question.id}`}
            >
              <div className="flex justify-between w-full items-center">
                <div className="flex items-center  gap-x-2 -py-0.5">
                  <p className=" text-sm leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {props.activity.account.name}
                    </span>{" "}
                    asked a new question
                  </p>
                </div>
                <time
                  title={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  dateTime={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  className="flex-none text-xs leading-5 text-gray-500"
                >
                  {formatDistance(
                    secondsToMilliseconds(props.activity.createdAt),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                </time>
              </div>
            </Link>
          )}
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.QuestionAnswered) {
    if (!props.activity.question) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-concrete-50">
            <CheckCircleIcon className="w-4 h-4 text-green-500/80" />
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="flex items-center  gap-x-2 -py-0.5">
              {props.mode === "verbose" ? (
                <p className=" text-sm leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  answered{" "}
                  <Link to={`/questions/${props.activity.question.id}`}>
                    <span className="font-semibold hover:text-gray-700">
                      {props.activity.question.title}
                    </span>
                  </Link>
                </p>
              ) : (
                <p className=" text-sm leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  answered the question
                </p>
              )}
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt)
              )}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.Comment) {
    if (!props.activity.comment) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h-6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className={props.isFirst ? "mt-0" : "mt-2"}>
            <Avatar account={props.activity.account} />
          </div>
          <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white/50">
            <div className="flex justify-between gap-x-4">
              <div className="py-0.5 text-xs leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                commented
                {props.mode === "verbose" ? (
                  <span>
                    {" "}
                    on <CommentNode activity={props.activity} />
                  </span>
                ) : null}
              </div>
              <time
                title={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                dateTime={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {formatDistance(
                  secondsToMilliseconds(props.activity.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  }
                )}
              </time>
            </div>
            <p className="text-sm leading-6 text-gray-500">
              {props.activity.comment.comment}
            </p>

            <Pills>
              {props.activity.comment.files.map((file) => (
                <FilePill
                  id={file.id}
                  key={file.id}
                  name={file.name}
                  type={file.fileType}
                />
              ))}
            </Pills>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.FileViewed) {
    if (!props.activity.file) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <EyeIcon className="w-4 h-4 text-gray-500/80" />
          </div>

          <div className="flex justify-between w-full items-center">
            <div className="flex items-center  gap-x-2 ">
              <p className=" text-sm leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                viewed
              </p>
              <FilePill
                id={props.activity.file.id}
                name={props.activity.file.name}
                type={props.activity.file.fileType}
              />
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt)
              )}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.DataRoomFileErrorResolved) {
    if (!props.activity.dataRoomFileError) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <CheckCircleIcon className="w-5 h-5 text-green-500/80" />
          </div>

          {props.activity.dataRoomFileError.resolvedMessage ? (
            <Link
              className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 bg-white/50"
              to={`/errors/${props.activity.dataRoomFileError.id}`}
            >
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  marked{" "}
                  <span className="font-medium">
                    {props.activity.dataRoomFileError.key}
                  </span>{" "}
                  as resolved
                </div>
                <time
                  title={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  dateTime={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {formatDistance(
                    secondsToMilliseconds(props.activity.createdAt),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                </time>
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-sm leading-6 text-gray-700 font-medium">
                  {props.activity.dataRoomFileError.resolvedMessage}
                </p>
              </div>
            </Link>
          ) : (
            <Link
              className="flex-auto"
              to={`/errors/${props.activity.dataRoomFileError.id}`}
            >
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-sm leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  marked{" "}
                  <span className="font-medium">
                    {props.activity.dataRoomFileError.key}
                  </span>{" "}
                  as resolved
                </div>
                <time
                  title={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  dateTime={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {formatDistance(
                    secondsToMilliseconds(props.activity.createdAt),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                </time>
              </div>
            </Link>
          )}
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.DataRoomFileErrorDismissed) {
    if (!props.activity.dataRoomFileError) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
            <NoSymbolIcon className="w-5 h-5 text-gray-700/80" />
          </div>

          <Link
            className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 bg-white/50"
            to={`/errors/${props.activity.dataRoomFileError.id}`}
          >
            <div className="flex justify-between gap-x-4">
              <div className="py-0.5 text-xs leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                ignored the error for{" "}
                <span className="font-medium">
                  {props.activity.dataRoomFileError.key}
                </span>
              </div>
              <time
                title={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                dateTime={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {formatDistance(
                  secondsToMilliseconds(props.activity.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  }
                )}
              </time>
            </div>
            <div className="flex items-center gap-x-2">
              <p className="text-sm leading-6 text-gray-700 font-medium">
                {props.activity.dataRoomFileError.dismissalReason}
              </p>
            </div>
          </Link>
        </>
      </li>
    );
  }

  if (props.activity.type === DealActivityType.DataRoomFileErrorCreated) {
    if (!props.activity.dataRoomFileError) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          {props.mode === "verbose" ? (
            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
              <ExclamationTriangleIcon className="w-5 h-5 text-orange-700/80" />
            </div>
          ) : (
            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            </div>
          )}

          {props.mode === "verbose" ? (
            <Link
              className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 bg-white/50"
              to={`/errors/${props.activity.dataRoomFileError.id}`}
            >
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  detected an error
                </div>
                <time
                  title={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  dateTime={formatISO(
                    secondsToMilliseconds(props.activity.createdAt)
                  )}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {formatDistance(
                    secondsToMilliseconds(props.activity.createdAt),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                </time>
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-sm leading-6 text-gray-700 font-medium">
                  {props.activity.dataRoomFileError.key} in
                </p>
                <FilePill
                  id={props.activity.dataRoomFileError.files[0].file.id}
                  name={props.activity.dataRoomFileError.files[0].file.name}
                  type={props.activity.dataRoomFileError.files[0].file.fileType}
                />
                <div className="bg-white shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
                  <p className="text-xs text-gray-800">
                    + {props.activity.dataRoomFileError.files.length - 1} other
                    files
                  </p>
                </div>
              </div>
            </Link>
          ) : (
            <div className="flex justify-between w-full items-center">
              <div className="flex items-center  gap-x-2 -py-0.5">
                <p className=" text-sm leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  detected an error
                </p>
              </div>
              <time
                title={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                dateTime={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                className="flex-none text-xs leading-5 text-gray-500"
              >
                {formatDistance(
                  secondsToMilliseconds(props.activity.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  }
                )}
              </time>
            </div>
          )}
        </>
      </li>
    );
  }

  return (
    <div key={props.activity.id}>
      <p className="inline text-xs text-gray-500">
        {format(fromUnixTime(props.activity.createdAt), "HH:mm")}
        {formatActivityType(props.activity.type)} by:{" "}
        {props.activity.account.name}
      </p>
    </div>
  );
}

function CommentNode(props: { activity: DealActivityFragmentFragment }) {
  if (props.activity.question) {
    return (
      <Link to={`/questions/${props.activity.question.id}`}>
        <span className="font-semibold hover:text-gray-700">
          {props.activity.question.title}
        </span>
      </Link>
    );
  }

  return null;
}
