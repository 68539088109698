import { useSelector } from "react-redux";
import {
  DealRole,
  DealRunner,
  useActiveDealAccountQuery,
} from "../graphql/generated";
import { authSelectors } from "../store/auth/selector";
import useGqlClient from "../hooks/useGqlClient";
import { Option } from "./tailwind/Dropdown";
import { formatDealRole } from "../utils/enums";
import { AppState } from "../store";

export const sellSideAdmins = [
  DealRole.DealOwner,
  DealRole.DealAdmin,
  DealRole.SellerAdmin,
];

export const adminRoles = [DealRole.DealOwner, DealRole.DealAdmin];

export const sellerRoles = [DealRole.SellerAdmin, DealRole.SellerStaff];

export const buyerRoles = [DealRole.BuyerAdmin, DealRole.BuyerStaff];

export const dealAdmins = [DealRole.DealOwner, DealRole.DealAdmin];

export const buyerAdmins = [
  DealRole.BuyerAdmin,
  DealRole.DealOwner,
  DealRole.DealAdmin,
];

export const allAdmins = [...sellSideAdmins, ...buyerAdmins];

export const allRoles = [
  DealRole.BuyerAdmin,
  DealRole.BuyerStaff,
  DealRole.SellerAdmin,
  DealRole.SellerStaff,
  DealRole.DealOwner,
  DealRole.DealAdmin,
];

export function DealRoleRestricted(props: {
  sellerRunDealRoles: DealRole[];
  buyerRunDealRoles: DealRole[];
  children: React.ReactNode;
  dealId?: string;
}) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const activeDeal = useSelector((state: AppState) =>
    authSelectors.deal(
      state,
      props.dealId ? props.dealId : activeDealId ? activeDealId : ""
    )
  );
  const client = useGqlClient();
  const activeDealAccount = useActiveDealAccountQuery(client, {
    id: props.dealId ? props.dealId : activeDealId ? activeDealId : "",
  });

  if (!activeDeal) {
    return null;
  }

  if (activeDeal.runner === DealRunner.Buyer && activeDealAccount.data) {
    const activeRole = activeDealAccount.data.deal.activeDealAccount.role;
    const hasAccess = props.buyerRunDealRoles.includes(activeRole);

    if (!hasAccess) {
      return null;
    }

    return <>{props.children}</>;
  }

  if (activeDealAccount.data) {
    const activeRole = activeDealAccount.data.deal.activeDealAccount.role;
    const hasAccess = props.sellerRunDealRoles.includes(activeRole);
    if (!hasAccess) {
      return null;
    }

    return <>{props.children}</>;
  }

  return null;
}

export const sellerRoleOptions: Option[] = [
  {
    value: DealRole.SellerAdmin,
    label: formatDealRole(DealRole.SellerAdmin),
    description: "Can manage users in their group and see everything",
  },
  {
    value: DealRole.SellerStaff,
    label: formatDealRole(DealRole.SellerStaff),
    description: "Can see everything but can't manage users",
  },
];

export const buyerRoleOptions: Option[] = [
  {
    value: DealRole.BuyerAdmin,
    label: formatDealRole(DealRole.BuyerAdmin),
    description: "Can manage users in their Buyer group",
  },
  {
    value: DealRole.BuyerStaff,
    label: formatDealRole(DealRole.BuyerStaff),
    description: "Can view documents and ask questions",
  },
];

export const dealRoleOptions: Option[] = [
  {
    value: DealRole.DealAdmin,
    label: formatDealRole(DealRole.DealAdmin),
    description: "Complete control over the deal",
  },
  {
    value: DealRole.DealOwner,
    label: formatDealRole(DealRole.DealOwner),
    description: "Complete control over the deal.",
  },
];

export const allRoleOptions = [
  ...sellerRoleOptions,
  ...buyerRoleOptions,
  ...dealRoleOptions,
];
