import { useState } from "react";
import {
  AccessQuery,
  DealFirmGroupQuery,
  DealFirmGroupType,
  DealFirmQuery,
  DealRole,
  useUpdateDealAccountMutation,
} from "../../graphql/generated";
import { useQueryClient } from "@tanstack/react-query";
import useGqlClient from "../../hooks/useGqlClient";
import {
  GranularAccess,
  granularAccessToFileInput,
  granularAccessToFolderInput,
} from ".";
import { AnimatedModal } from "../../components/AnimatedModal";
import { H4 } from "../../components/Heading";
import { CloseIcon } from "../../components/CloseIcon";
import { classNames } from "../../utils/cn";
import { Button } from "../../components/tailwind/Button";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { EditDataRoomPermissions } from "./EditDataRoomPermissions";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import {
  allRoleOptions,
  buyerRoleOptions,
  sellerRoleOptions,
} from "../../components/DealRoleRestricted";
import { formatDealRole } from "../../utils/enums";

const tabs = ["Data Room access"];

export function EditDealAccount(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  dealAccount: DealFirmQuery["dealFirm"]["dealAccounts"][0] | null;
  dealFirmGroupId: string;
  dealFirmId: string;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  open: boolean;
  onClose: () => void;
  firmGroupType: DealFirmGroupType;
}) {
  if (!props.dealAccount) {
    return null;
  }

  return (
    <EditDealAccountContent
      dealFirmGroup={props.dealFirmGroup}
      dealAccount={props.dealAccount}
      dealFirmGroupId={props.dealFirmGroupId}
      dealFirmId={props.dealFirmId}
      permissions={props.permissions}
      open={props.open}
      onClose={props.onClose}
      firmGroupType={props.firmGroupType}
    />
  );
}

function EditDealAccountContent(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  dealAccount: DealFirmQuery["dealFirm"]["dealAccounts"][0];
  dealFirmGroupId: string;
  dealFirmId: string;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  open: boolean;
  onClose: () => void;
  firmGroupType: DealFirmGroupType;
}) {
  const initialRole = allRoleOptions.find(
    (r) => r.value === props.dealAccount.role
  );

  const [role, setRole] = useState<Option>(
    initialRole ? initialRole : buyerRoleOptions[0]
  );

  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const client = useGqlClient();
  const updateDealAccount = useUpdateDealAccountMutation(client);

  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(
    props.dealAccount.overrideDataRoomAccess
  );
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });
  return (
    <AnimatedModal
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      size="xxl"
    >
      <div>
        <div className="flex items-center justify-between">
          <H4 margin="0">Edit {props.dealAccount.account.name}</H4>
          <CloseIcon onClose={props.onClose} />
        </div>
        <p className="text-sm text-gray-500/80 leading-tight">
          Joined{" "}
          {formatDistanceToNow(fromUnixTime(props.dealAccount.createdAt), {
            addSuffix: true,
          })}
        </p>
        <div>
          <div className="">
            <p className="mt-3 block text-sm font-medium leading-6 text-gray-900">
              Deal role
            </p>
            <div className="flex">
              {[DealRole.DealAdmin, DealRole.DealOwner].includes(
                props.dealAccount.role
              ) ? (
                <div className="rounded-md px-3 py-2 border border-gray-300">
                  <p className="font-semibold text-gray-700 text-sm">
                    {formatDealRole(props.dealAccount.role)}
                  </p>
                  <p className="text-sm text-gray-500/80">
                    This role can't be changed
                  </p>
                </div>
              ) : (
                <Dropdown
                  size="s"
                  onSelect={(o) => {
                    setRole(o);
                  }}
                  options={
                    props.firmGroupType === DealFirmGroupType.Buyer
                      ? buyerRoleOptions
                      : sellerRoleOptions
                  }
                  selectedOption={role}
                />
              )}
            </div>
            <div className="mt-3 border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => {
                      setSelectedTab(tab);
                    }}
                    className={classNames(
                      tab === selectedTab
                        ? "border-gray-600 text-gray-700"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab === selectedTab ? "page" : undefined}
                  >
                    {tab}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        {role.value === DealRole.DealAdmin ? (
          <div className="mt-3">
            <p>Deal Admins have full access to everything in the Data Room. </p>
          </div>
        ) : (
          <EditDataRoomPermissions
            firmGroupId={props.dealFirmGroup.id}
            showPermissions={true}
            key={props.dealAccount.id}
            initialDataRoomPermission={props.dealFirmGroup.dataRoomPermission}
            firmGroupType={props.firmGroupType}
            initialGranularAccess={props.dealAccount.granularAccess}
            dataRoomPermissions={props.permissions}
            overrideDataRoomAccess={overrideDataRoomAccess}
            onOverrideDataRoomAccessChange={(checked) => {
              setOverrideDataRoomAccess(checked);
            }}
            granularAccess={granularAccess}
            onGranularAccessChange={(granularAccess) => {
              setGranularAccess(granularAccess);
            }}
          />
        )}

        <div className="w-full h-px bg-gray-200 my-4"></div>
        <div className="flex justify-end mt-5">
          <Button
            text="Save"
            variant="positive"
            isLoading={updateDealAccount.isPending}
            loadingText="Saving..."
            onClick={() => {
              updateDealAccount.mutate(
                {
                  input: {
                    id: props.dealAccount.id,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                    overrideDataRoomAccess,
                    role: role.value as DealRole,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: [
                        "DealFirmGroup",
                        { id: props.dealFirmGroupId },
                      ],
                    });
                    props.onClose();
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
