import { useParams } from "react-router-dom";
import { H3, H4 } from "../../components/Heading";
import useGqlClient from "../../hooks/useGqlClient";
import {
  DataRoomFileErrorQuery,
  DataRoomFileErrorStatus,
  DealActivityType,
  ErrorCheckingStatus,
  QueryDealActivityArgs,
  useCreateIgnoreErrorRuleMutation,
  useDataRoomFileErrorQuery,
  useIgnoreFileForErrorMutation,
} from "../../graphql/generated";
import {
  formatDistanceToNow,
  formatDistanceToNowStrict,
  fromUnixTime,
} from "date-fns";
import { UseQueryResult, useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/Loading";
import { Menu, RadioGroup } from "@headlessui/react";
import { classNames } from "../../utils/cn";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../utils/formatCurrency";
import { Card } from "../../components/Card";
import { FileIcon } from "../../components/FileIcon";
import { Button } from "../../components/tailwind/Button";
import { DismissErrorModal } from "./DismissErrorModal";
import { Activity } from "../../components/activity/Activity";
import { CommentInput } from "../../components/activity/CommentInput";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import {
  BellSnoozeIcon,
  CheckCircleIcon,
  DocumentArrowUpIcon,
  NoSymbolIcon,
} from "@heroicons/react/20/solid";
import { NewVersionUploader } from "../../components/data_room/file_uploaders/NewVersionUploader";
import { ResolveErrorModal } from "./ResolveErrorModal";
import { useWebSocket } from "../../contexts/websockets";
import { Spinner } from "../../components/icons/Spinner";
import { TripleDotMenu } from "../../components/TripleDotMenu";
import { toasts } from "../../components/toasts/toasts";

export function ErrorDetails() {
  const { id } = useParams<{ id: string }>();
  const client = useGqlClient();

  const [dismissErrorModalOpen, setDismissErrorModalOpen] = useState(false);
  const [resolveErrorModalOpen, setResolveErrorModalOpen] = useState(false);
  const [isResolved, setIsResolved] = useState(false);

  const errorQuery = useDataRoomFileErrorQuery(client, {
    id,
  });

  useEffect(() => {
    if (!errorQuery.data) {
      return;
    }

    if (getValues(errorQuery.data).length <= 1) {
      setIsResolved(true);
    }
  }, [errorQuery.data]);

  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white sticky top-0 px-8 py-5 z-20 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>
              {errorQuery.data
                ? errorQuery.data.dataRoomFileError.title
                : "Loading..."}
            </H3>
            <p className="text-sm text-gray-500/80 leading-none">
              {errorQuery.data
                ? `Created ${formatDistanceToNow(
                    fromUnixTime(errorQuery.data.dataRoomFileError.createdAt),
                    {
                      addSuffix: true,
                    }
                  )}`
                : ""}
            </p>
          </div>
          {errorQuery.data &&
          errorQuery.data.dataRoomFileError.status ===
            DataRoomFileErrorStatus.Pending ? (
            <div className="gap-x-2 flex items-center">
              <Button
                variant="neutral"
                text="Ignore"
                onClick={() => {
                  setDismissErrorModalOpen(true);
                }}
              />
              <Button
                variant="positive"
                text="Mark as resolved"
                isDisabled={!isResolved}
                onClick={() => {
                  if (!isResolved) {
                    return;
                  }

                  setResolveErrorModalOpen(true);
                }}
              />
            </div>
          ) : errorQuery.data ? (
            <ErrorStatus status={errorQuery.data.dataRoomFileError.status} />
          ) : null}
        </div>
      </div>
      <div className="flex-1 p-8 flex flex-col">
        <ErrorContent query={errorQuery} />
      </div>
      {errorQuery.data ? (
        <DismissErrorModal
          open={dismissErrorModalOpen}
          onClose={() => setDismissErrorModalOpen(false)}
          dataRoomFileError={errorQuery.data.dataRoomFileError}
        />
      ) : null}
      {errorQuery.data ? (
        <ResolveErrorModal
          open={resolveErrorModalOpen}
          onClose={() => setResolveErrorModalOpen(false)}
          dataRoomFileError={errorQuery.data.dataRoomFileError}
        />
      ) : null}
    </div>
  );
}

function ErrorContent(props: {
  query: UseQueryResult<DataRoomFileErrorQuery, unknown>;
}) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!props.query.data) {
      return;
    }

    if (getValues(props.query.data).length === 1) {
      setSelectedOption(getValues(props.query.data)[0]);
    }
  }, [props.query.data]);

  if (props.query.error) {
    return (
      <div>
        <p className="font-semibold text-gray-700">Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return <Loading />;
  }

  const dataRoomFileError = props.query.data.dataRoomFileError;

  return (
    <div>
      <p className="font-semibold text-gray-800">Select the correct value</p>
      {props.query.data.dataRoomFileError.status ===
      DataRoomFileErrorStatus.Pending ? (
        <SelectCorrectValue
          options={getValues(props.query.data)}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <>
          <p className="text-gray-500/80 text-sm">
            Error check has been{" "}
            {props.query.data.dataRoomFileError.status ===
            DataRoomFileErrorStatus.Dismissed
              ? "ignored"
              : "resolved"}
          </p>
        </>
      )}

      <div className="mt-8">
        <p className="font-semibold text-gray-700">
          {dataRoomFileError.files.length} files affected
        </p>
        <div className="grid grid-cols-3 gap-4 mt-2 pb-2 overflow-visible">
          {dataRoomFileError.files
            .sort((a, b) => {
              if (
                a.status === ErrorCheckingStatus.Ignored &&
                b.status !== ErrorCheckingStatus.Ignored
              ) {
                return 1;
              }

              if (
                a.status !== ErrorCheckingStatus.Ignored &&
                b.status === ErrorCheckingStatus.Ignored
              ) {
                return -1;
              }

              return 0;
            })
            .map((file) => {
              return (
                <ErrorFile
                  key={file.file.id}
                  fileError={dataRoomFileError}
                  file={file}
                  selectedOption={selectedOption}
                />
              );
            })}
        </div>
        <div className="mt-6 w-full xl:w-1/2">
          <H4>Activity</H4>
          <Activity
            mode="compact"
            input={{
              startDate: dataRoomFileError.createdAt,
              dataRoomFileErrorID: dataRoomFileError.id,
              fileActivitiesInput: {
                dataRoomFileIDs: dataRoomFileError.files.map((f) => f.file.id),
                activityTypes: [
                  DealActivityType.FileCreated,
                  DealActivityType.FileVersionCreated,
                ],
              },
              count: 30,
              cursor: "",
              ascending: true,
            }}
          />
          <CommentInput
            onCommented={() => {
              const activityQueries = queryClient
                .getQueryCache()
                .findAll({ queryKey: ["DealActivity"] });
              for (const query of activityQueries) {
                if (query.queryKey.length > 1) {
                  const dealId = (query.queryKey[1] as QueryDealActivityArgs)[
                    "dealId"
                  ];
                  if (dealId === activeDealId) {
                    queryClient.invalidateQueries({
                      queryKey: [query.queryKey],
                    });
                  }
                }
              }
            }}
            input={{
              dataRoomFileErrorId: dataRoomFileError.id,
              comment: "",
              files: [],
            }}
          />
        </div>
      </div>
    </div>
  );
}

function getValues(data: DataRoomFileErrorQuery): number[] {
  let values: number[] = [];
  for (const v of data.dataRoomFileError.files) {
    if (values.includes(v.value)) {
      continue;
    }

    if (v.status === ErrorCheckingStatus.Ignored) {
      continue;
    }

    values.push(v.value);
  }

  return values;
}

function SelectCorrectValue(props: {
  selectedOption: number | null;
  setSelectedOption: (value: number) => void;
  options: number[];
}) {
  console.log(props.options);
  return (
    <RadioGroup
      value={props.selectedOption}
      onChange={props.setSelectedOption}
      className="mt-2"
    >
      <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
        {props.options.map((option) => (
          <RadioGroup.Option
            key={option.toString()}
            value={option}
            className={({ active, checked }) =>
              classNames(
                active ? "ring-2 ring-indigo-500 ring-offset-2" : "",
                checked
                  ? "bg-indigo-500 text-white hover:bg-indigo-400"
                  : "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1 cursor-pointer focus:outline-none"
              )
            }
          >
            <RadioGroup.Label as="span">
              {formatCurrency(option)}
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

function ErrorStatus(props: { status: DataRoomFileErrorStatus }) {
  if (props.status === DataRoomFileErrorStatus.Dismissed) {
    return (
      <div className="flex items-center p-1.5 px-2.5 border select-none border-gray-400 gap-x-1.5 rounded-full">
        <NoSymbolIcon className="w-4 h-4 text-gray-600" />
        <p className="text-sm text-gray-700">Ignored</p>
      </div>
    );
  }

  if (props.status === DataRoomFileErrorStatus.Resolved) {
    return (
      <div className="flex items-center p-1.5 px-2.5 border select-none border-green-400 gap-x-1.5 rounded-full">
        <CheckCircleIcon className="w-4 h-4 text-green-600" />
        <p className="text-sm text-green-700">Resolved</p>
      </div>
    );
  }

  return null;
}

function ErrorFile(props: {
  fileError: DataRoomFileErrorQuery["dataRoomFileError"];
  file: DataRoomFileErrorQuery["dataRoomFileError"]["files"][0];
  selectedOption: number | null;
}) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const file = props.file;
  const fileError = props.fileError;
  const selectedOption = props.selectedOption;

  const [showUploadModal, setShowUploadModal] = useState(false);
  const { subscribeToTopic, unsubscribeFromTopic } = useWebSocket();

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const createIgnoreErrorRule = useCreateIgnoreErrorRuleMutation(client);
  const ignoreFileForError = useIgnoreFileForErrorMutation(client);

  return (
    <div
      key={file.file.id}
      className={`${
        fileError.status !== DataRoomFileErrorStatus.Pending ||
        selectedOption === null ||
        file.status === ErrorCheckingStatus.Ignored
          ? "opacity-70 hover:opacity-100"
          : ""
      }`}
    >
      <Card padding="m">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <FileIcon fileType={file.file.fileType} />
            <p className="text-gray-700 text-sm font-semibold ml-2">
              {file.file.name}
            </p>
          </div>
          <TripleDotMenu width="w-52">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={(e) => {
                    ignoreFileForError.mutate(
                      {
                        dataRoomFileID: file.file.id,
                        dataRoomFileErrorID: fileError.id,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries({
                            queryKey: [
                              "DataRoomFileError",
                              { id: fileError.id },
                            ],
                          });
                          toasts.success("File ignored for this error");
                        },
                        onError: () => {
                          toasts.error("Failed to ignore file");
                        },
                      }
                    );
                  }}
                  className={classNames(
                    active ? "bg-gray-50 text-gray-700" : "",
                    "px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer flex items-center"
                  )}
                >
                  <BellSnoozeIcon className="w-4 h-4 text-gray-600 mr-2" />
                  <p className="truncate">Ignore file for this error</p>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={(e) => {
                    createIgnoreErrorRule.mutate(
                      {
                        input: {
                          dataRoomFileID: file.file.id,
                          dealID: activeDealId ?? "",
                          keyFiles: [],
                        },
                      },
                      {
                        onSuccess: () => {
                          toasts.success("File ignored for all errors");
                        },
                        onError: () => {
                          toasts.error("Failed to ignore file");
                        },
                      }
                    );
                  }}
                  className={classNames(
                    active ? "bg-gray-50 text-gray-700" : "",
                    "px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer flex items-center"
                  )}
                >
                  <NoSymbolIcon className="w-4 h-4 text-gray-600 mr-2" />
                  <p className="truncate">Ignore file for all errors</p>
                </div>
              )}
            </Menu.Item>
          </TripleDotMenu>
        </div>
        <div className="mt-2 ml-1">
          <div className="flex items-center">
            <div
              className={classNames(
                `rounded-full w-2 h-2 `,
                selectedOption
                  ? file.value !== selectedOption
                    ? "bg-orange-400"
                    : "bg-green-400"
                  : "bg-gray-400"
              )}
            ></div>
            <div className="ml-3">
              <p className="font-semibold text-sm text-gray-600">
                {file.file.versions[0] ? file.file.versions[0].summary : ""}
              </p>
              <p className="text-xs text-gray-500/80">
                Uploaded by {file.file.versions[0].createdBy.name} {`\u2022`}{" "}
                {formatDistanceToNowStrict(
                  fromUnixTime(file.file.versions[0].createdAt),
                  {
                    addSuffix: true,
                  }
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="my-3 bg-gray-300 w-full h-px"></div>
        <div className="flex justify-between">
          {file.status === ErrorCheckingStatus.Checking ? (
            <>
              <div className="flex items-center ml-1">
                <Spinner color="gray" size="s" />
                <p className="font-bold text-sm  text-gray-500">
                  Reprocessing file...
                </p>
              </div>
            </>
          ) : file.status === ErrorCheckingStatus.Ignored ? (
            <>
              <div className="flex items-center ml-1">
                <NoSymbolIcon className="w-4 h-4 text-gray-600" />
                <p className="font-bold text-sm  text-gray-500 ml-1.5">
                  Ignored
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <p className="font-bold text-sm  text-gray-500">{file.key}:</p>
              </div>

              <div>
                <p
                  className={`${
                    selectedOption !== null
                      ? file.value === selectedOption
                        ? "text-green-600"
                        : "text-orange-600"
                      : "text-gray-600"
                  } font-semibold text-sm`}
                >
                  {formatCurrency(file.value)}
                </p>
              </div>
            </>
          )}
        </div>
      </Card>
      {file.status === ErrorCheckingStatus.Success &&
      selectedOption !== null &&
      file.value !== selectedOption ? (
        <div className="mt-2">
          <Card>
            <div className="flex justify-between items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowUploadModal(true);
                }}
                className="py-3 hover:bg-gray-100 cursor-pointer rounded-md group justify-center flex flex-1"
              >
                <DocumentArrowUpIcon className="w-5 h-5 text-gray-500 group-hover:text-gray-600" />
                <p className="select-none font-bold text-sm text-gray-500 ml-1 group-hover:text-gray-600">
                  Upload new version
                </p>
              </button>
            </div>
          </Card>
        </div>
      ) : null}
      <NewVersionUploader
        open={showUploadModal}
        fileName={file.file.name}
        dataRoomFileId={file.file.id}
        onClose={() => {
          setShowUploadModal(false);
        }}
        initialSummary={`Fix ${fileError.key} error`}
      />
    </div>
  );
}
