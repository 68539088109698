import { useState } from "react";
import { AnimatedModal } from "../../../components/AnimatedModal";
import { CloseIcon } from "../../../components/CloseIcon";
import { H3 } from "../../../components/Heading";
import { TextInput } from "../../../components/tailwind/TextInput";
import {
  Account,
  DueDiligenceChecklistItemType,
  useCreateDueDiligenceChecklistItemMutation,
} from "../../../graphql/generated";
import Datepicker from "tailwind-datepicker-react";
import { Button } from "../../../components/tailwind/Button";
import useGqlClient from "../../../hooks/useGqlClient";
import { getUnixTime } from "date-fns";
import { toasts } from "../../../components/toasts/toasts";
import { SelectUserPill } from "../../../components/SelectUserPill";
import { UserPill } from "../../../components/UserPill";
import { Pills } from "../../../components/Pills";

interface AddChecklistItemModalProps {
  open: boolean;
  onClose: () => void;
  type: DueDiligenceChecklistItemType;
  dealFirmGroupId: string;
  dealFirmGroupName: string;
}

export function AddChecklistItemModal(props: AddChecklistItemModalProps) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dueDate, setDueDate] = useState<Date | null>(null);

  const client = useGqlClient();
  const createItem = useCreateDueDiligenceChecklistItemMutation(client);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [assignTo, setAssignTo] = useState<Pick<
    Account,
    "id" | "name" | "avatar" | "avatarColor"
  > | null>(null);

  const [error, setError] = useState("");

  function clearForm() {
    setTitle("");
    setAssignTo(null);
    setDueDate(null);
  }

  return (
    <AnimatedModal
      open={props.open}
      onClose={() => {
        clearForm();
        props.onClose();
      }}
      size="lg"
    >
      <div>
        <div className="flex items-center justify-between">
          <H3>
            Add condition{" "}
            {props.type === DueDiligenceChecklistItemType.ConditionPrecedent
              ? "precedent"
              : "subsequent"}
          </H3>
          <CloseIcon
            onClose={() => {
              clearForm();
              props.onClose();
            }}
          />
        </div>

        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();

            setError("");
            if (!title) {
              setTitleError("Title is required");
            }

            createItem.mutate(
              {
                input: {
                  dealFirmGroupID: props.dealFirmGroupId,
                  type: props.type,
                  title,
                  dueDate: dueDate ? getUnixTime(dueDate) : undefined,
                  assignedToID: assignTo ? assignTo.id : undefined,
                },
              },
              {
                onSuccess: () => {
                  toasts.success("Condition item added");
                  clearForm();
                  props.onClose();
                },
                onError: () => {
                  setError("Something went wrong");
                },
              }
            );
          }}
        >
          <TextInput
            label="Title"
            placeholder="e.g. Audit reports, compliance certificates"
            onChange={(e) => {
              setTitleError("");
              setTitle(e.currentTarget.value);
            }}
            value={title}
            error={titleError}
          />

          <div className="flex mt-3">
            <div className="flex-1">
              <label className="mt-3 block text-sm font-medium leading-6 text-gray-900">
                Assign to
              </label>
              <Pills>
                {assignTo ? (
                  <UserPill
                    account={assignTo}
                    onRemove={() => {
                      setAssignTo(null);
                    }}
                  />
                ) : (
                  <SelectUserPill
                    onUserSelected={(acc) => {
                      setAssignTo(acc as Account);
                    }}
                  />
                )}
              </Pills>
            </div>

            <div className="flex-1">
              <label className="mt-3 mb-2 block text-sm font-medium leading-6 text-gray-900">
                Due date
              </label>
              <Datepicker
                options={{
                  defaultDate: null,
                  clearBtn: true,
                }}
                value={dueDate ?? undefined}
                onChange={(e) => {
                  setDueDate(e);
                }}
                show={showDatePicker}
                setShow={setShowDatePicker}
              />
            </div>
          </div>
          <div className="mt-12 justify-end flex gap-x-3">
            <Button variant="neutral" text="Cancel" onClick={props.onClose} />
            <Button
              variant="positive"
              text="Add"
              type="submit"
              loadingText="Adding..."
              isLoading={createItem.isPending}
              isDisabled={createItem.isPending}
            />
          </div>
          {error ? (
            <p className="text-right mt-2 font-semibold text-red-600">
              {error}
            </p>
          ) : null}
        </form>
      </div>
    </AnimatedModal>
  );
}
