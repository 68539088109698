import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type DeepSearchState = {
  showSidebar: {
    [deepSearchId: string]: boolean;
  };
};

const initialState: DeepSearchState = {
  showSidebar: {},
};

export const { actions, reducer } = createSlice({
  name: "dataRoomFile",
  initialState,
  reducers: {
    setShowSidebar: (
      state,
      action: PayloadAction<{ deepSearchId: string; show: boolean }>
    ) => {
      state.showSidebar[action.payload.deepSearchId] = action.payload.show;
    },
  },
});
