import { AppState } from "..";
import { DealThreadMessage } from "../../graphql/generated";

function dealThreadMessages(
  state: AppState,
  id: string
): Partial<DealThreadMessage>[] {
  if (!id) {
    return [];
  }

  return state.assistant.dealThreads[id];
}

function activeDealThreadId(state: AppState): string | undefined {
  return state.assistant.activeDealThreadId;
}

export const assistantSelectors = {
  dealThreadMessages,
  activeDealThreadId,
};
