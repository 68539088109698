import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type DeepDiveState = {
  selectedFileVersionId: {
    [fileId: string]: string;
  };
};

const initialState: DeepDiveState = {
  selectedFileVersionId: {},
};

export const { actions, reducer } = createSlice({
  name: "dataRoomFile",
  initialState,
  reducers: {
    setSelectedFileVersionId: (
      state,
      action: PayloadAction<{ fileId: string; versionId: string }>
    ) => {
      state.selectedFileVersionId[action.payload.fileId] =
        action.payload.versionId;
    },
  },
});
