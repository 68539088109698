import { Menu } from "@headlessui/react";
import { TripleDotMenu } from "../../components/TripleDotMenu";
import {
  AccessQuery,
  DealFirmGroupQuery,
  DealFirmQuery,
  useRemoveDealAccountMutation,
} from "../../graphql/generated";
import { Card } from "../../components/Card";
import { classNames } from "../../utils/cn";
import { formatDealRole } from "../../utils/enums";
import { Avatar } from "../../components/account/Avatar";
import { useState } from "react";
import { EditDealAccount } from "./EditDealAccount";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { useQueryClient } from "@tanstack/react-query";
import useGqlClient from "../../hooks/useGqlClient";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { buyerRoles } from "../../components/DealRoleRestricted";
import { Tooltip } from "../../components/Tooltip";

export function DealAccountDetails(props: {
  dealAccount: DealFirmQuery["dealFirm"]["dealAccounts"][0];
  dealFirmId: string;
  firmGroup: DealFirmGroupQuery["dealFirmGroup"];
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
}) {
  const dealAccount = props.dealAccount;
  const [openModal, setOpenModal] = useState<
    "edit_account" | "remove_account" | ""
  >("");

  const account = useSelector(authSelectors.account);

  const isCurrentBuyer =
    account &&
    account.id === dealAccount.account.id &&
    buyerRoles.includes(dealAccount.role);

  return (
    <div className="w-full">
      <Card padding="m">
        <div className="">
          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Avatar account={dealAccount.account} size="l" />
                <div className="ml-2">
                  <div className="flex">
                    <p className="font-semibold text-sm text-gray-600 leading-tight">
                      {dealAccount.account.name}
                    </p>
                  </div>
                  <p className="text-xs leading-tight text-gray-500/80">
                    {dealAccount.account.email}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <span className="hidden xl:block ml-2 font-medium text-xs text-gray-700 px-1.5 py-0.5 rounded-md border border-gray-300 bg-gray-100">
                  {formatDealRole(dealAccount.role)}
                </span>
                {isCurrentBuyer ? null : (
                  <TripleDotMenu>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={(e) => {
                            setOpenModal("edit_account");
                          }}
                          className={classNames(
                            active ? "bg-gray-50 text-indigo-700" : "",
                            "block px-3 py-1 text-sm leading-6 text-indigo-600 cursor-pointer"
                          )}
                        >
                          Edit
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={(e) => {
                            setOpenModal("remove_account");
                          }}
                          className={classNames(
                            active ? "bg-gray-50 text-red-700" : "",
                            "block px-3 py-1 truncate text-sm leading-6 text-red-600 cursor-pointer"
                          )}
                        >
                          Remove
                        </div>
                      )}
                    </Menu.Item>
                  </TripleDotMenu>
                )}
              </div>
            </div>

            {!isCurrentBuyer && dealAccount.overrideDataRoomAccess ? (
              <div className="ml-9 mt-0.5 flex items-center">
                <Tooltip text="Only has access to a limited number of files and folders" />
                <p className="text-sm text-gray-800 mt-0.5 ml-1 font-semibold">
                  Restricted Data Room access
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      <EditDealAccount
        dealFirmId={props.dealFirmId}
        dealFirmGroup={props.firmGroup}
        open={openModal === "edit_account"}
        onClose={() => {
          setOpenModal("");
        }}
        dealAccount={props.dealAccount}
        permissions={props.permissions}
        firmGroupType={props.firmGroup.type}
        dealFirmGroupId={props.firmGroup.id}
      />
      <RemoveDealAccount
        dealFirmGroupId={props.firmGroup.id}
        dealFirmId={props.dealFirmId}
        dealAccount={props.dealAccount}
        open={openModal === "remove_account"}
        onClose={() => {
          setOpenModal("");
        }}
      />
    </div>
  );
}

function RemoveDealAccount(props: {
  dealAccount: DealFirmQuery["dealFirm"]["dealAccounts"][0];
  dealFirmId: string;
  dealFirmGroupId: string;
  open: boolean;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const removeDealAccount = useRemoveDealAccountMutation(client);

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold text-gray-800">
          Remove {props.dealAccount.account.name} from the deal
        </h2>
        <CloseIcon onClose={props.onClose} />
      </div>
      <p className="text-sm text-gray-600 mt-2">
        Are you sure you want to remove this user from the deal?
      </p>
      <div className="flex justify-end mt-4">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onClose();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            removeDealAccount.mutate(
              {
                id: props.dealAccount.id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["DealFirm", { id: props.dealFirmId }],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["DealFirmGroup", { id: props.dealFirmGroupId }],
                  });
                  props.onClose();
                },
              }
            );
          }}
        >
          Remove
        </button>
      </div>
    </AnimatedModal>
  );
}
