import { useState } from "react";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { TextArea } from "../../components/tailwind/TextArea";
import {
  DataRoomFileErrorQuery,
  useResolveDataRoomFileErrorMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Button } from "../../components/tailwind/Button";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { H3 } from "../../components/Heading";

export function ResolveErrorModal(props: {
  dataRoomFileError: DataRoomFileErrorQuery["dataRoomFileError"];
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const [resolveMessage, setResolveMessage] = useState<string>("");
  const resolveError = useResolveDataRoomFileErrorMutation(client);

  return (
    <AnimatedModal size="lg" open={props.open} onClose={props.onClose}>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-2">
            <CheckCircleIcon className="h-8 w-8 -ml-1 text-green-500" />
            <div>
              <H3>Resolve error</H3>
              <p className="text-sm text-gray-500/80 leading-tight">
                Mark{" "}
                <span className="font-medium">
                  {props.dataRoomFileError.key}
                </span>{" "}
                as resolved
              </p>
            </div>
          </div>
          <CloseIcon onClose={props.onClose} />
        </div>
        <form
          className="mt-6"
          onSubmit={(e) => {
            e.preventDefault();

            resolveError.mutate(
              {
                input: {
                  id: props.dataRoomFileError.id,
                  message: resolveMessage,
                },
              },
              {
                onSuccess: () => {
                  props.onClose();
                },
              }
            );
          }}
        >
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="Message"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Message
              </label>
              <span className="text-sm text-gray-500/80">Optional</span>
            </div>
            <TextArea
              label="Message"
              rows={2}
              placeholder="Include any more information you think is important"
              name="Message"
              value={resolveMessage}
              onChange={(e) => {
                setResolveMessage(e.currentTarget.value);
              }}
            />

            <div className="flex justify-end mt-12">
              <Button
                variant="positive"
                text="Resolve"
                isLoading={resolveError.isPending}
                loadingText="Resolving..."
                type="submit"
              />
            </div>
          </div>
        </form>
        {resolveError.error ? (
          <div>
            <p>Failed to resolve error</p>
          </div>
        ) : null}
      </div>
    </AnimatedModal>
  );
}
