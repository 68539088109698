import {
  ArrowPathIcon,
  CheckCircleIcon,
  DocumentPlusIcon,
  FolderPlusIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { FolderIcon as FolderIconOutline } from "@heroicons/react/24/outline";
import { H3, H4 } from "../Heading";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AnimatedModal } from "../AnimatedModal";
import {
  CreateDataRoomFileDocument,
  CreateDataRoomFileMutation,
  CreateDataRoomFileMutationVariables,
  DataRoomFile,
  DataRoomFolderFragmentFragment,
  DataRoomFolderQuery,
  DataRoomPermission,
  S3MultipartUploadCompleteDocument,
  S3MultipartUploadCompleteMutation,
  S3MultipartUploadCompleteMutationVariables,
  useCreateDataRoomFolderMutation,
  useDataRoomFolderQuery,
  useSearchFilesQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { CloseIcon } from "../CloseIcon";
import { TextInput } from "../tailwind/TextInput";
import Dropdown, { Option } from "../tailwind/Dropdown";
import { Button } from "../tailwind/Button";
import { useQueryClient } from "@tanstack/react-query";
import { Combobox } from "@headlessui/react";
import { classNames } from "../../utils/cn";
import Loading from "../Loading";
import { FileIcon } from "../FileIcon";
import { Alert } from "../Alert";
import { LocalFileIcon } from "../icons/LocalFileIcon";
import { formatFileSize } from "../../utils/formatFileSize";
import { Uploader, getNumberOfParts } from "../../utils/multipartUploader";
import { Spinner } from "../icons/Spinner";
import {
  DealRoleRestricted,
  adminRoles,
  allRoles,
  sellerRoles,
} from "../DealRoleRestricted";
import { useInvalidateQueryKeys } from "../../hooks/useInvalidateQueryKeys";

const STORAGE_KEY = "recentFileSearches";

// Function to get recent searches from local storage
function getRecentSearches() {
  const searches = localStorage.getItem(STORAGE_KEY);
  return searches ? (JSON.parse(searches) as DataRoomFile[]) : [];
}

// Function to save a new search to local storage
function saveSearch(newSearch: DataRoomFile) {
  const searches = getRecentSearches();
  // Add new search at the beginning of the array
  searches.unshift(newSearch);
  // Keep only the 3 most recent searches
  const recentSearches = searches.slice(0, 3);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recentSearches));
}

let shortcutHintTimeout: NodeJS.Timeout | null = null;

export function DataRoomHeader(props: {
  folderId: string;
  permissions: DataRoomPermission[];
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const location = useLocation();

  const [openModal, setOpenModal] = useState<"search" | "file" | "folder" | "">(
    ""
  );

  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [metaPressed, setMetaPressed] = useState(false);

  const [specialKeyPressed, setSpecialKeyPressed] = useState(false);

  useEffect(() => {
    const keyPressed = ctrlPressed || metaPressed;

    if (keyPressed) {
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
      shortcutHintTimeout = setTimeout(() => {
        setSpecialKeyPressed(true);
      }, 700);
    } else {
      setSpecialKeyPressed(false);
    }

    return () => {
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
    };
  }, [ctrlPressed, metaPressed]);

  const [searchTerm, setSearchTerm] = useState("");

  const [recentSearches, setRecentSearches] = useState<DataRoomFile[]>([]);

  useEffect(() => {
    // Load recent searches from local storage on component mount
    const searches = getRecentSearches();
    setRecentSearches(searches);
  }, []);

  // Example function to handle a new search
  // This should be called with the actual search result when a search is performed
  function handleNewSearch(searchResult: DataRoomFile) {
    saveSearch(searchResult);
    // Optionally, update the state to immediately reflect the new search without reloading
    setRecentSearches(getRecentSearches());
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    // Check if CMD+F on macOS or CTRL+F on Windows/Linux
    if (event.metaKey || event.ctrlKey) {
      if (!["d", "f", "s", "Meta", "Control"].includes(event.key)) {
        setSpecialKeyPressed(false);
        return;
      }

      if (event.metaKey) {
        setMetaPressed(true);
      }

      if (event.ctrlKey) {
        setCtrlPressed(true);
      }

      if (event.key === "d") {
        event.preventDefault();
        setOpenModal("folder");
        return;
      }

      if (event.key === "f") {
        event.preventDefault();
        setOpenModal("file");
        return;
      }

      if (event.key === "s") {
        event.preventDefault();
        setOpenModal("search");
      }
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Meta") {
      event.preventDefault();
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
      setMetaPressed(false);
    }

    if (event.key === "Control") {
      event.preventDefault();
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
      setCtrlPressed(false);
    }
  };

  function handleFocusOut() {
    setSpecialKeyPressed(false);
  }

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    window.addEventListener("blur", handleFocusOut);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("blur", handleFocusOut);
    };
  }, []);

  const client = useGqlClient();
  const { data, isLoading, error } = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  return (
    <div className="bg-white sticky top-0 z-20 px-8 py-4 w-full shadow-sm border-b border-gray-300/80">
      <div className="flex items-center justify-between">
        <div>
          <H4 margin="0 0 0 0">Files</H4>
        </div>
        <div className="w-1/3">
          <div
            ref={inputRef}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModal("search");
              if (inputRef.current) {
                inputRef.current.blur();
              }
            }}
            onFocus={() => {}}
            className="h-8 hidden xl:flex cursor-text items-center w-full border-0 bg-gray-100 rounded-md shadow-sm pl-2 pr-3 text-gray-900 placeholder:text-gray-400  sm:text-sm focus:outline-none"
          >
            <MagnifyingGlassIcon className="pointer-events-none mr-2 h-5 w-5 text-gray-400" />
            <p className="text-gray-500">Search...</p>
            {specialKeyPressed ? (
              <kbd className="pointer-events-none font-mono text-xs justify-center border border-gray-200 bg-white rounded-lg text-gray-500 ml-auto flex items-center px-2 py-1.5 font-semibold">
                s
              </kbd>
            ) : null}
          </div>
        </div>
        <ReadAccessOnly folderId={props.folderId}>
          <div className="xl:w-44 ml-1 w-20"></div>
        </ReadAccessOnly>
        <WriteAccessOnly folderId={props.folderId}>
          <div className="space-x-2 flex items-center">
            <div className="hidden xl:block">
              <Button
                icon={FolderPlusIcon}
                text="Folder"
                variant="neutral"
                size="s"
                keyboardShortcut={specialKeyPressed ? "d" : undefined}
                onClick={() => {
                  setOpenModal("folder");
                }}
              />
            </div>
            <div className="block xl:hidden">
              <Button
                icon={FolderPlusIcon}
                text=""
                variant="neutral"
                size="s"
                keyboardShortcut={specialKeyPressed ? "d" : undefined}
                onClick={() => {
                  setOpenModal("folder");
                }}
              />
            </div>

            <div className="hidden xl:block">
              <Button
                icon={DocumentPlusIcon}
                text="Files"
                size="s"
                variant="positive"
                keyboardShortcut={specialKeyPressed ? "f" : undefined}
                onClick={() => {
                  setOpenModal("file");
                }}
              />
            </div>
            <div className="block xl:hidden">
              <Button
                icon={DocumentPlusIcon}
                text=""
                size="s"
                variant="positive"
                keyboardShortcut={specialKeyPressed ? "f" : undefined}
                onClick={() => {
                  setOpenModal("file");
                }}
              />
            </div>
          </div>
        </WriteAccessOnly>
      </div>
      <WriteAccessOnly folderId={props.folderId}>
        <AnimatedModal
          size="md"
          open={openModal === "folder"}
          onClose={() => {
            setOpenModal("");
          }}
        >
          <NewFolder
            data={data}
            permissions={props.permissions}
            isLoading={isLoading}
            error={error}
            onClose={() => setOpenModal("")}
          />
        </AnimatedModal>

        <FileUploader
          open={openModal === "file"}
          onClose={() => setOpenModal("")}
          permissions={props.permissions}
          data={data}
          isLoading={isLoading}
          error={error}
        />
      </WriteAccessOnly>
      <AnimatedModal
        open={openModal === "search"}
        onClose={() => {
          setOpenModal("");
        }}
        position="top"
        size="xl"
        padding="p-0"
      >
        <Combobox
          onChange={(v: any) => {
            if (v.shortcut) {
              return;
            }

            handleNewSearch(v);
            history.push(
              `/dataroom/files/folder/${props.folderId}/file/${v.id}`
            );
          }}
        >
          <div className="flex flex-col h-full ">
            <div className="relative">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Combobox.Input
                className="h-12 w-full border-b select-none border-gray-100 rounded-t-md focus:outline-none focus:select-none focus:border-0 focus:border-b focus:border-gray-100 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                onChange={(event) => {
                  setSearchTerm(event.currentTarget.value);
                }}
              />
            </div>
            <div className="flex-1">
              <SearchResults
                searchTerm={searchTerm}
                folderId={props.folderId}
                recentSearches={recentSearches}
              />
            </div>
          </div>
        </Combobox>
      </AnimatedModal>
    </div>
  );
}

function NewFolder(props: {
  data: DataRoomFolderQuery | undefined;
  isLoading: boolean;
  error?: unknown;
  onClose: () => void;
  permissions: DataRoomPermission[];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const createFolder = useCreateDataRoomFolderMutation(client);

  const createButtonRef = useRef<HTMLButtonElement>(null);
  const [folderName, setFolderName] = useState("");
  const [nameError, setNameError] = useState("");

  const { invalidateDealActivities } = useInvalidateQueryKeys();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (createButtonRef.current) {
          createButtonRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [folders, setFolders] = useState<
    DataRoomFolderFragmentFragment["parentFolders"]
  >([]);

  useEffect(() => {
    if (props.isLoading || props.error || !props.data) {
      return;
    }

    setFolders(props.data.dataRoomFolder.parentFolders);
  }, [props.data, props.isLoading, props.error]);

  const crumbs = [
    "Home",
    ...folders
      .slice(0, folders.length - 1)
      .map((f) => f.name)
      .reverse(),
  ];

  if (props.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <H3>New folder</H3>
          <p className="text-sm text-gray-500 leading-none">
            In {`${crumbs.join(" / ")}`}
          </p>
        </div>
        <CloseIcon onClose={props.onClose} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!props.data) {
            return;
          }

          if (!folderName) {
            setNameError("Name is required");
            return;
          }

          createFolder.mutate(
            {
              dataRoomFolderID: props.data.dataRoomFolder.id,
              name: folderName,
            },
            {
              onSuccess: (data) => {
                queryClient.invalidateQueries({
                  queryKey: [
                    "DataRoomFolder",
                    { id: `${props.data?.dataRoomFolder.id}` },
                  ],
                });

                invalidateDealActivities();

                setFolderName("");

                props.onClose();
              },
            }
          );
        }}
      >
        <div className="mt-4">
          <TextInput
            name="folder name"
            id="folder_name"
            error={nameError}
            placeholder="The name of your new folder..."
            label="Name"
            value={folderName}
            onChange={(e) => {
              setNameError("");
              setFolderName(e.currentTarget.value);
            }}
          />

          <div className="mt-12 flex justify-end">
            <Button
              ref={createButtonRef}
              type="submit"
              text="Create folder"
              variant="positive"
              isLoading={createFolder.isPending}
              isDisabled={folderName === ""}
              loadingText="Creating folder..."
            />
          </div>
        </div>
      </form>
    </div>
  );
}

interface SearchResultsProps {
  folderId: string;
  searchTerm: string;
  recentSearches: DataRoomFile[];
}

let searchTimeout: NodeJS.Timeout | null = null;

const quickActions = [
  { name: "Add new file...", icon: DocumentPlusIcon, shortcut: "f", url: "#" },
  { name: "Add new folder...", icon: FolderPlusIcon, shortcut: "d", url: "#" },
];

function SearchResults(props: SearchResultsProps) {
  const [files, setFiles] = useState<DataRoomFile[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const client = useGqlClient();
  const searchQuery = useSearchFilesQuery(
    client,
    {
      query: searchTerm,
    },
    {
      enabled: searchTerm.length > 2,
    }
  );

  useEffect(() => {
    const term = props.searchTerm;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setSearchTerm(term);
    }, 1000);
  }, [props.searchTerm]);

  useEffect(() => {
    if (searchTerm.length < 2) {
      setFiles([]);
      return;
    }

    if (!searchQuery.data) {
      return;
    }

    setFiles(searchQuery.data.searchFiles as DataRoomFile[]);
  }, [searchTerm, searchQuery.data]);

  if (searchQuery.isError) {
    return <div>Something went wrong</div>;
  }

  if (searchQuery.isLoading) {
    return (
      <div className="h-full  justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (searchTerm === "") {
    return (
      <Combobox.Options static className="max-h-80 scroll-py-2 overflow-y-auto">
        <h2 className="mb-2 mt-4 px-6 text-xs font-semibold text-gray-500">
          Recent searches
        </h2>
        {props.recentSearches.length === 0 ? (
          <p className="text-sm px-6 text-gray-400">No recent searches</p>
        ) : (
          <div className="px-2">
            {props.recentSearches.map((file, i) => {
              return (
                <Combobox.Option
                  key={i}
                  value={file}
                  className={({ active }) =>
                    classNames(
                      "flex cursor-default select-none items-center rounded-md px-3 py-2",
                      active ? "bg-gray-700 text-white" : ""
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <div className="bg-white rounded-full h-7 w-7 justify-center items-center flex p-1">
                        <FileIcon fileType={file.fileType} />
                      </div>
                      <span className="ml-3 flex-auto truncate text-sm">
                        {file.parentFolders
                          .map((pf, i) => {
                            return `${
                              pf.name === "" ? "Home" : pf.name
                            }${" / "}`;
                          })
                          .reverse()}
                        <span className="font-bold">{file.name}</span>
                      </span>
                      {active && (
                        <span className="ml-3 flex-none text-gray-100 text-sm">
                          Jump to...
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              );
            })}
          </div>
        )}
        <WriteAccessOnly folderId={props.folderId}>
          <div className="w-full h-px mt-4 bg-gray-100"></div>
        </WriteAccessOnly>

        <WriteAccessOnly folderId={props.folderId}>
          <li className="p-2">
            <h2 className="sr-only">Quick actions</h2>
            <ul className="text-sm text-gray-700">
              {quickActions.map((action) => (
                <Combobox.Option
                  key={action.shortcut}
                  value={action}
                  className={({ active }) =>
                    classNames(
                      "flex cursor-default select-none items-center rounded-md px-3 py-2",
                      active ? "bg-gray-200" : ""
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <action.icon
                        className={classNames(
                          "h-6 w-6 flex-none",
                          "text-gray-400"
                        )}
                        aria-hidden="true"
                      />
                      <span className="ml-3 flex-auto truncate">
                        {action.name}
                      </span>
                      <span
                        className={classNames(
                          "ml-3 flex-none text-xs font-semibold",
                          "text-gray-400"
                        )}
                      >
                        <kbd className="font-sans">⌘</kbd>
                        <kbd className="font-sans">{action.shortcut}</kbd>
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))}
            </ul>
          </li>
        </WriteAccessOnly>
      </Combobox.Options>
    );
  }

  if (!searchQuery.data || files.length === 0) {
    return (
      <div className="flex py-8 h-full justify-center items-center flex-col">
        <FolderIconOutline className="w-6 h-6 text-gray-400" />
        <p className="text-gray-600 text-sm">
          We couldn't find any files with that search term
        </p>
      </div>
    );
  }

  return (
    <>
      <Combobox.Options
        static
        className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
      >
        <li className="p-2">
          <ul
            className="text-sm text-gray-700"
            key={files.map((f) => f.id).join("-")}
          >
            {files.map((file) => (
              <Combobox.Option
                key={file.id}
                value={file}
                className={({ active }) =>
                  classNames(
                    "flex cursor-default select-none items-center rounded-md px-3 py-2",
                    active ? "bg-gray-700 text-white" : ""
                  )
                }
              >
                {({ active }) => (
                  <>
                    <div className="bg-white rounded-full h-7 w-7 justify-center items-center flex p-1">
                      <FileIcon fileType={file.fileType} />
                    </div>
                    <span className="ml-3 flex-auto truncate">
                      {file.parentFolders
                        .map((pf, i) => {
                          return `${pf.name === "" ? "Home" : pf.name}${" / "}`;
                        })
                        .reverse()}
                      <span className="font-bold">{file.name}</span>
                    </span>
                    {active && (
                      <span className="ml-3 flex-none text-gray-100">
                        Jump to...
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </ul>
        </li>
      </Combobox.Options>
    </>
  );
}

interface FileUploaderProps {
  open: boolean;
  data: DataRoomFolderQuery | undefined;
  isLoading: boolean;
  error?: unknown;
  onClose: () => void;
  permissions: DataRoomPermission[];
}

interface FileWrapper {
  id: number;
  file: File;
  name: string;
  dataRoomPermissionId: string;
  uploadProgress: number;
  status: "creating" | "uploading" | "complete" | "error" | "pending";
  selectedPermission: Option;
}

function FileUploader(props: FileUploaderProps) {
  const [dragActive, setDragActive] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [files, setFiles] = useState<FileWrapper[]>([]);
  const [fileError, setFileError] = useState("");

  const createButtonRef = useRef<HTMLButtonElement>(null);

  const {
    invalidateDealActivities,
    invalidateDataRoomFolders,
    invalidateDataRooms,
  } = useInvalidateQueryKeys();
  const client = useGqlClient();

  const [uploadingFile, setUploadingFile] = useState(false);

  const [selectedPermission, setSelectedPermission] = useState<Option>({
    label: props.permissions[0].name,
    value: props.permissions[0].id,
    description: props.permissions[0].description,
  });

  const [folders, setFolders] = useState<
    DataRoomFolderFragmentFragment["parentFolders"]
  >([]);

  useEffect(() => {
    if (props.isLoading || props.error || !props.data) {
      return;
    }

    setFolders(props.data.dataRoomFolder.parentFolders);
  }, [props.data, props.isLoading, props.error]);

  const crumbs = [
    "Home",
    ...folders
      .slice(0, folders.length - 1)
      .map((f) => f.name)
      .reverse(),
  ];

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (createButtonRef.current) {
          createButtonRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleDrag = function (e: any) {
    setFileError("");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files: FileWrapper[] = Array.from(
        e.dataTransfer.files as File[]
      ).map((f: File, i) => {
        // remove extension from file name
        const name = f.name.replace(/\.[^/.]+$/, "");
        return {
          id: i,
          file: f,
          name,
          dataRoomPermissionId:
            selectedPermission.value ?? props.permissions[0].id,
          uploadProgress: 0,
          status: "pending",
          selectedPermission: {
            value: props.permissions[0].id,
            label: props.permissions[0].name,
            description: props.permissions[0].description,
          },
        };
      });

      setFiles(files);
    }
  };

  function uploadFile(fileWrap: FileWrapper): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (!props.data) {
          return;
        }

        setFiles((prev) => {
          return prev.map((f) => {
            if (f.id === fileWrap.id) {
              return {
                ...f,
                status: "creating",
              };
            }

            return f;
          });
        });

        client
          .request<
            CreateDataRoomFileMutation,
            CreateDataRoomFileMutationVariables
          >(CreateDataRoomFileDocument, {
            name: fileWrap.name,
            description,
            dataRoomFolderID: props.data.dataRoomFolder.id,
            dataRoomPermissionID: fileWrap.selectedPermission.value as string,
            fileName: fileWrap.file.name,
            fileType: fileWrap.file.type,
            parts: getNumberOfParts(fileWrap.file),
          })
          .catch((e) => {
            setFiles((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "error",
                  };
                }

                return f;
              });
            });
          })
          .then((data) => {
            if (!data) {
              return;
            }

            setFiles((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "uploading",
                  };
                }

                return f;
              });
            });

            const uploader = new Uploader({
              file: fileWrap.file,
              uploadId: data.createDataRoomFile.multiPartUploadID,
              parts: data.createDataRoomFile.parts.map((p) => {
                return {
                  PartNumber: p.partNumber,
                  signedUrl: p.presignedUrl,
                };
              }),
            });

            uploader.start();

            uploader.onError(() => {
              setFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      status: "error",
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onProgress((progress) => {
              setFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      uploadProgress: progress.percentage,
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onComplete((uploadedParts) => {
              client
                .request<
                  S3MultipartUploadCompleteMutation,
                  S3MultipartUploadCompleteMutationVariables
                >(S3MultipartUploadCompleteDocument, {
                  dataRoomFileID: data.createDataRoomFile.dataRoomFile.id,
                  dataRoomFileVersionID:
                    data.createDataRoomFile.initialVersion.id,
                  multiPartUploadID: data.createDataRoomFile.multiPartUploadID,
                  parts: uploadedParts.map((up) => {
                    return {
                      partNumber: up.PartNumber,
                      etag: up.ETag,
                    };
                  }),
                })
                .then((d) => {
                  if (!d) {
                    return;
                  }
                  setFiles((prev) => {
                    return prev.map((f) => {
                      if (f.id === fileWrap.id) {
                        return {
                          ...f,
                          status: "complete",
                        };
                      }

                      return f;
                    });
                  });
                });
            });
          });
      } finally {
        resolve();
      }
    });
  }

  function clearForm() {
    setFile(null);
    setFiles([]);
    setError("");
    setName("");
    setNameError("");
    setFileError("");
    setSelectedPermission({
      label: props.permissions[0].name,
      value: props.permissions[0].id,
      description: props.permissions[0].description,
    });
  }

  return (
    <AnimatedModal
      padding="p-0"
      open={props.open}
      onClose={() => {
        clearForm();
        props.onClose();
      }}
      size="xl"
      key={files.length}
    >
      <div key={files.length}>
        <div className="p-4 flex justify-between items-center">
          <div>
            <H3>New files</H3>
            <p className="text-sm text-gray-500 leading-none">
              In {`${crumbs.join(" / ")}`}
            </p>
          </div>
          <CloseIcon
            onClose={() => {
              clearForm();
              props.onClose();
            }}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!props.data) {
              return;
            }

            if (
              files.length === 0 ||
              files.some((f) => !f.name) ||
              !selectedPermission.value
            ) {
              setFileError(
                files.length === 0 ? "At least 1 file is required" : ""
              );
              return;
            }

            Promise.all(files.map((f) => uploadFile(f)));
          }}
        >
          {files.length > 0 ? (
            <div className="px-4">
              <div>
                <DealRoleRestricted
                  buyerRunDealRoles={allRoles}
                  sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
                >
                  <div>
                    <p className="font-semibold text-gray-600">
                      Permission level
                    </p>
                    <div className="flex">
                      <Dropdown
                        hideSelectedOptionDescription
                        variant="light"
                        selectedOption={selectedPermission}
                        onSelect={(option) => {
                          setSelectedPermission(option);
                          setFiles((prev) => {
                            return prev.map((f) => {
                              return {
                                ...f,
                                selectedPermission: option,
                              };
                            });
                          });
                        }}
                        options={props.permissions.map((permission) => {
                          return {
                            value: permission.id,
                            label: permission.name,
                            description: permission.description,
                          };
                        })}
                      />
                    </div>
                    <p className="text-xs mt-1.5 text-gray-500/80 leading-none">
                      You can override the permission level for individual files
                    </p>
                  </div>
                </DealRoleRestricted>
                <p className="font-semibold mt-3 text-gray-600">
                  Files ({files.length})
                </p>
              </div>
            </div>
          ) : null}
          <div
            className="px-3 h-72 overflow-y-scroll no-scrollbar"
            key={files.length}
          >
            {files.length > 0 ? (
              <div key={files.length}>
                <div key={files.length}>
                  {files
                    .sort((a, b) => {
                      if (a.status === "complete" && b.status !== "complete") {
                        return 1;
                      }

                      if (a.status !== "complete" && b.status === "complete") {
                        return -1;
                      }

                      return 0;
                    })
                    .map((file, i) => {
                      return (
                        <div
                          key={i}
                          className={classNames(
                            "relative px-2 flex-wrap py-3 my-2 group gap-x-2 justify-between items-center flex border-b border-gray-200",
                            file.status === "complete" ? "opacity-80" : ""
                          )}
                        >
                          <div className="flex items-start">
                            <LocalFileIcon fileType={file.file.type} />
                            <div className="-mt-1.5 ml-2">
                              {file.status === "pending" ? (
                                <div className="w-80">
                                  <TextInput
                                    value={file.name}
                                    inputSize="s"
                                    onChange={(e) => {
                                      const value = e.currentTarget.value;
                                      setFiles((prev) => {
                                        return prev.map((f) => {
                                          if (f.id === file.id) {
                                            return {
                                              ...f,
                                              name: value,
                                            };
                                          }

                                          return f;
                                        });
                                      });
                                    }}
                                  />
                                </div>
                              ) : (
                                <p className="font-semibold text-sm text-gray-700 truncate">
                                  {file.name}
                                </p>
                              )}

                              {/* <PencilIcon className="h-4 w-4 ml-1 text-gray-500" /> */}
                              <p className="text-xs text-gray-500 mt-1.5 leading-none">
                                {formatFileSize(file.file.size)}
                              </p>
                            </div>
                          </div>
                          {file.status === "pending" ? (
                            <div className="flex items-center overflow-visible gap-x-2">
                              <DealRoleRestricted
                                sellerRunDealRoles={[
                                  ...sellerRoles,
                                  ...adminRoles,
                                ]}
                                buyerRunDealRoles={allRoles}
                              >
                                <Dropdown
                                  hideSelectedOptionDescription
                                  variant="light"
                                  selectedOption={file.selectedPermission}
                                  onSelect={(option) => {
                                    setFiles((prev) => {
                                      return prev.map((f) => {
                                        if (f.id === file.id) {
                                          return {
                                            ...f,
                                            selectedPermission: option,
                                          };
                                        }

                                        return f;
                                      });
                                    });
                                  }}
                                  options={props.permissions.map(
                                    (permission) => {
                                      return {
                                        value: permission.id,
                                        label: permission.name,
                                        description: permission.description,
                                      };
                                    }
                                  )}
                                />
                              </DealRoleRestricted>

                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => {
                                  setFiles((prev) => {
                                    return prev.filter((f) => f.id !== file.id);
                                  });
                                }}
                              >
                                <TrashIcon className="h-5 w-5 " />
                              </button>
                            </div>
                          ) : (
                            <FileWrapStatus
                              fileWrap={file}
                              onRetry={() => {
                                uploadFile(file);
                              }}
                            />
                          )}
                        </div>
                      );
                    })}

                  <div>
                    <button
                      type="button"
                      className="mt-2 inline-flex items-center gap-x-1 bg-white text-blue-500 hover:text-blue-600  rounded-full font-semibold text-sm"
                      onClick={() => {
                        setFileError("");
                        if (inputRef.current) {
                          inputRef.current.click();
                        }
                      }}
                    >
                      <PlusIcon className="w-5 h-5" />
                      <p>Add more files</p>
                    </button>
                    <input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                          const f: FileWrapper[] = Array.from(
                            e.target.files
                          ).map((f, i) => {
                            // remove extension from file name
                            const name = f.name.replace(/\.[^/.]+$/, "");
                            return {
                              id: files.length + 1,
                              file: f,
                              name,
                              dataRoomPermissionId:
                                selectedPermission.value ??
                                props.permissions[0].id,
                              uploadProgress: 0,
                              status: "pending",
                              selectedPermission: {
                                value: props.permissions[0].id,
                                label: props.permissions[0].name,
                                description: props.permissions[0].description,
                              },
                            };
                          });

                          setFiles((prevFiles) => {
                            return [...prevFiles, ...f];
                          });
                        }
                      }}
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={classNames(
                    "p-2 h-full gap-x-2 bg-gray-50 border-2 transition-all duration-300 ease-in-out rounded-md border-dashed border-gray-300 flex flex-col items-center justify-center",
                    dragActive
                      ? "border-indigo-500 bg-indigo-100/80 shadow"
                      : "",
                    fileError ? "border-red-500" : ""
                  )}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <p className="text-sm mb-1 text-gray-500 font-medium">
                    Drop files here or
                  </p>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1 bg-white text-blue-500 border px-2 py-1 rounded-full font-semibold hover:border-blue-400 hover:shadow text-sm"
                      onClick={() => {
                        setFileError("");
                        if (inputRef.current) {
                          inputRef.current.click();
                        }
                      }}
                    >
                      <DocumentPlusIcon className="w-5 h-5" />
                      <p>Upload</p>
                    </button>
                    <input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                          const f: FileWrapper[] = Array.from(
                            e.target.files
                          ).map((f, i) => {
                            // remove extension from file name
                            const name = f.name.replace(/\.[^/.]+$/, "");
                            return {
                              id: i,
                              file: f,
                              name,
                              dataRoomPermissionId:
                                selectedPermission.value ??
                                props.permissions[0].id,
                              uploadProgress: 0,
                              status: "pending",
                              selectedPermission: {
                                value: props.permissions[0].id,
                                label: props.permissions[0].name,
                                description: props.permissions[0].description,
                              },
                            };
                          });

                          setFiles(f);
                        }
                      }}
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                {fileError ? (
                  <p className="text-red-500 font-medium mt-2 text-sm">
                    {fileError}
                  </p>
                ) : null}
              </>
            )}
          </div>

          <div className=" bg-gray-200 mt-2 w-full shadow-inner h-px" />
          {files.filter(
            (f) =>
              f.file.type.match(
                /application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet/
              ) || f.file.type.match(/application\/vnd\.ms-excel/)
          ).length > 0 ? (
            <div className="mt-2 px-2 py-1 rounded-md mx-3 border border-blue-500 flex items-start gap-x-2 bg-blue-50">
              <InformationCircleIcon className="h-5 w-5 text-blue-500" />
              <p className="text-sm font-semibold text-blue-500">
                Spreadsheets are in beta and may not work as expected with error
                checking, suggested answers and the assistant.
              </p>
            </div>
          ) : null}
          {error ? <Alert margin="m 0 0 0" type="error" text={error} /> : null}
          <div className="p-4 flex justify-end">
            {files.length > 0 &&
            files.every((f) => ["complete", "error"].includes(f.status)) ? (
              <FilesSummary
                files={files}
                onRetry={() => {
                  Promise.all(
                    files
                      .filter((f) => f.status === "error")
                      .map((f) => uploadFile(f))
                  );
                }}
                onCompleted={() => {
                  invalidateDealActivities();
                  invalidateDataRoomFolders();
                  invalidateDataRooms();
                  clearForm();
                  props.onClose();
                }}
              />
            ) : (
              <Button
                ref={createButtonRef}
                type="submit"
                variant="positive"
                text={files.length > 0 ? "Create files" : "Create file"}
                isLoading={
                  uploadingFile ||
                  files.some((f) =>
                    ["creating", "uploading"].includes(f.status)
                  )
                }
                loadingText="Creating..."
                onClick={() => {}}
                isDisabled={
                  files.some((file) => !file.name) || files.length === 0
                }
              />
            )}
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

function FilesSummary(props: {
  files: FileWrapper[];
  onRetry: () => void;
  onCompleted: () => void;
}) {
  if (props.files.some((f) => f.status === "error")) {
    return (
      <div className="flex items-center gap-x-2">
        <p className="text-sm text-gray-500/80">
          Failed to upload{" "}
          {props.files.filter((f) => f.status === "error").length} files
        </p>
        <Button
          text="Retry"
          variant="neutral"
          onClick={() => {
            props.onRetry();
          }}
        />
        <Button
          text="Done"
          variant="positive"
          onClick={() => {
            props.onCompleted();
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-2">
      <p className="text-sm text-gray-500/80">
        {props.files.length} files uploaded successfully
      </p>
      <Button
        text="Done"
        variant="positive"
        type="button"
        onClick={() => {
          props.onCompleted();
        }}
      />
    </div>
  );
}

function ProgressBar(props: { progress: number }) {
  return (
    <div className="h-1 w-full bg-blue-500/50 rounded-md">
      <div
        className="h-1 bg-blue-500 rounded-md transition-all duration-300 ease-in-out"
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}

function FileWrapStatus(props: { fileWrap: FileWrapper; onRetry: () => void }) {
  if (props.fileWrap.status === "creating") {
    return (
      <div className="flex items-center gap-x-1.5">
        <Spinner color="gray" size="s" />
        <p className="text-sm text-gray-500/80 font-semibold">
          Creating file...
        </p>
      </div>
    );
  }

  if (props.fileWrap.status === "uploading") {
    // retunr a progress bar

    return (
      <div className="w-32">
        <ProgressBar progress={props.fileWrap.uploadProgress} />
      </div>
    );
  }

  if (props.fileWrap.status === "error") {
    return (
      <div className="">
        <p className="text-xs text-orange-500">Error uploading file</p>
        <button
          className="flex items-center gap-x-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => {
            props.onRetry();
          }}
        >
          <ArrowPathIcon className="h-5 w-5" />
          <p className="text-sm font-semibold">Try again</p>
        </button>
      </div>
    );
  }

  if (props.fileWrap.status === "complete") {
    return (
      <div>
        <CheckCircleIcon className="h-5 w-5 text-green-500" />
      </div>
    );
  }

  return null;
}

function ReadAccessOnly(props: {
  children: React.ReactNode;
  folderId: string;
}) {
  const client = useGqlClient();
  const dataRoomFolderQuery = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  if (
    dataRoomFolderQuery.isPending ||
    !dataRoomFolderQuery.data ||
    dataRoomFolderQuery.error
  ) {
    return null;
  }

  if (!dataRoomFolderQuery.data.dataRoomFolder.hasFolderWriteAccess) {
    return <>{props.children}</>;
  }

  return null;
}

function WriteAccessOnly(props: {
  children: React.ReactNode;
  folderId: string;
}) {
  const client = useGqlClient();
  const dataRoomFolderQuery = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  if (
    dataRoomFolderQuery.isPending ||
    !dataRoomFolderQuery.data ||
    dataRoomFolderQuery.error
  ) {
    return null;
  }

  if (dataRoomFolderQuery.data.dataRoomFolder.hasFolderWriteAccess) {
    return <>{props.children}</>;
  }

  return null;
}
