import { Link } from "react-router-dom";
import { Card } from "../../../components/Card";
import { H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import {
  DdChecklistsQuery,
  DueDiligenceChecklistItemType,
  useDdChecklistsQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import { useSelector } from "react-redux";

export function DDChecklists() {
  const activeDealId = useSelector(authSelectors.activeDealId);

  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white sticky top-0 z-20 px-8 py-5 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Due Diligence Checklists</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <div className="p-8 flex-1 flex">
        {activeDealId ? <DdChecklistsContent dealId={activeDealId} /> : null}
      </div>
    </div>
  );
}

function DdChecklistsContent(props: { dealId: string }) {
  const client = useGqlClient();

  const ddCheckListsQuery = useDdChecklistsQuery(client, {
    dealId: props.dealId,
  });

  if (ddCheckListsQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (ddCheckListsQuery.isPending || !ddCheckListsQuery.data) {
    return <Loading />;
  }

  return (
    <div className="w-full xl:w-1/2">
      <Card>
        {ddCheckListsQuery.data.deal.allGroups.map((group) => {
          return (
            <Group
              group={group}
              isFirst={group === ddCheckListsQuery.data.deal.allGroups[0]}
              isLast={
                group ===
                ddCheckListsQuery.data.deal.allGroups[
                  ddCheckListsQuery.data.deal.allGroups.length - 1
                ]
              }
            />
          );
        })}
      </Card>
    </div>
  );
}

interface GroupProps {
  group: DdChecklistsQuery["deal"]["allGroups"][0];
  isFirst: boolean;
  isLast: boolean;
}

function Group(props: GroupProps) {
  return (
    <div
      key={props.group.id}
      className={`w-full mb-3 3xl:w-4/5 hover:bg-gray-200/70 cursor-pointer ${
        props.isFirst ? "rounded-t-md" : ""
      } ${props.isLast ? "rounded-b-md" : ""}`}
    >
      <Link to={`/buyers/dd-checklists/${props.group.id}`}>
        <div className="flex px-3 py-2 items-center justify-between space-x-3">
          <div className="flex-1">
            <p className="text-gray-600 text-sm font-bold">
              {props.group.name}
            </p>
            <p className="text-xs text-gray-500/80 leading-tight text-ellipsis">
              {
                props.group.ddChecklistItems.filter(
                  (dd) =>
                    dd.type === DueDiligenceChecklistItemType.ConditionPrecedent
                ).length
              }{" "}
              CPs
              {` \u2022 `}
              {
                props.group.ddChecklistItems.filter(
                  (dd) =>
                    dd.type ===
                    DueDiligenceChecklistItemType.ConditionSubsequent
                ).length
              }{" "}
              CSs
            </p>
          </div>

          <div className="w-52">
            <p className="text-xs mb-0.5 font-semibold text-gray-500">
              Precedents
            </p>
            <ProgressBar
              progress={
                (props.group.ddChecklistItems.filter(
                  (dd) =>
                    dd.type ===
                      DueDiligenceChecklistItemType.ConditionPrecedent &&
                    dd.completedAt
                ).length /
                  props.group.ddChecklistItems.filter(
                    (dd) =>
                      dd.type ===
                      DueDiligenceChecklistItemType.ConditionPrecedent
                  ).length ===
                0
                  ? 1
                  : props.group.ddChecklistItems.filter(
                      (dd) =>
                        dd.type ===
                        DueDiligenceChecklistItemType.ConditionPrecedent
                    ).length) * 100
              }
            />

            <p className="mt-3 mb-0.5 text-xs font-semibold text-gray-500">
              Subsequents
            </p>
            <ProgressBar
              progress={
                (props.group.ddChecklistItems.filter((dd) => dd.completedAt)
                  .length /
                  props.group.ddChecklistItems.length ===
                0
                  ? 1
                  : props.group.ddChecklistItems.filter(
                      (dd) =>
                        dd.type ===
                        DueDiligenceChecklistItemType.ConditionSubsequent
                    ).length) * 100
              }
            />
          </div>
        </div>
      </Link>
    </div>
  );
}

function ProgressBar(props: { progress: number }) {
  return (
    <div className="h-1 w-full bg-green-500/20 rounded-md">
      <div
        className="h-1 bg-green-500 rounded-md transition-all duration-300 ease-in-out"
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
