import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DealRunner,
  useAccountQuery,
  useCreateDealMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { TextInput } from "../../components/tailwind/TextInput";
import { TextArea } from "../../components/tailwind/TextArea";
import { Button } from "../../components/tailwind/Button";
import { actions } from "../../store/auth/slice";
import { H2, H4 } from "../../components/Heading";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../utils/cn";
import { useWebSocket } from "../../contexts/websockets";

export function CreateDeal() {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [website, setWebsite] = useState("");

  const { subscribeToTopic } = useWebSocket();

  const client = useGqlClient();
  const createDeal = useCreateDealMutation(client);

  const dispatch = useDispatch();
  const history = useHistory();
  const accountQuery = useAccountQuery(client);

  const [dealRunner, setDealRunner] = useState<DealRunner>(DealRunner.Seller);

  return (
    <div className="flex-1">
      <div className="flex justify-center">
        <div className="hidden xl:block">
          <Steps currentStep={1} />
        </div>

        <div className="w-full xl:w-1/3">
          <H2>Create deal</H2>
          <H4 margin="s 0">Deal side</H4>
          <RadioGroup value={dealRunner} onChange={setDealRunner}>
            <RadioGroup.Label className="sr-only">Deal Type</RadioGroup.Label>
            <div className="-space-y-px rounded-md bg-white">
              <RadioGroup.Option
                value={DealRunner.Seller}
                className={({ checked }) =>
                  classNames(
                    "rounded-tl-md rounded-tr-md",
                    checked
                      ? "z-10 border-indigo-200 bg-indigo-50"
                      : "border-gray-200",
                    "relative flex cursor-pointer border p-4 focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span
                      className={classNames(
                        checked
                          ? "bg-indigo-600 border-transparent"
                          : "bg-white border-gray-300",
                        active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="ml-3 flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-indigo-900" : "text-gray-900",
                          "block text-sm font-medium"
                        )}
                      >
                        Sell Side
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={classNames(
                          checked ? "text-indigo-700" : "text-gray-500",
                          "block text-sm"
                        )}
                      >
                        You are selling a company and will invite potential
                        buyers to the deal.
                      </RadioGroup.Description>
                    </span>
                  </>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option
                value={DealRunner.Buyer}
                className={({ checked }) =>
                  classNames(
                    "rounded-bl-md rounded-br-md",
                    checked
                      ? "z-10 border-persian-200 bg-persian-50"
                      : "border-gray-200",
                    "relative flex cursor-pointer border p-4 focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span
                      className={classNames(
                        checked
                          ? "bg-persian-600 border-transparent"
                          : "bg-white border-gray-300",
                        active ? "ring-2 ring-offset-2 ring-persian-600" : "",
                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="ml-3 flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-persian-900" : "text-gray-900",
                          "block text-sm font-medium"
                        )}
                      >
                        Buy side
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={classNames(
                          checked ? "text-persian-700" : "text-gray-500",
                          "block text-sm"
                        )}
                      >
                        You are buying a company and will invite the seller to
                        the deal.{" "}
                      </RadioGroup.Description>
                    </span>
                  </>
                )}
              </RadioGroup.Option>
            </div>
          </RadioGroup>

          <H4 margin="m 0 0 0">Company information</H4>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (!name) {
                if (!name) {
                  setNameError("Name is required");
                }
                return;
              }

              createDeal.mutate(
                {
                  input: {
                    company: {
                      name,
                      description,
                      website,
                    },
                    runner: dealRunner,
                  },
                },
                {
                  onSuccess: (data) => {
                    if (data.createDeal) {
                      accountQuery.refetch().then((accountData) => {
                        if (!accountData.data) {
                          return;
                        }

                        dispatch(
                          actions.refreshAccount({
                            account: accountData.data.account,
                          })
                        );

                        dispatch(
                          actions.setActiveDealId({
                            id: data.createDeal.id,
                          })
                        );

                        history.push(
                          `/create-deal/${data.createDeal.id}/data-room`
                        );

                        subscribeToTopic(`deal:${data.createDeal.id}`);
                      });
                    }
                  },
                }
              );
            }}
          >
            <div className="mt-2">
              <TextInput
                ignore1p
                error={nameError}
                onChange={(e) => {
                  setNameError("");
                  setName(e.currentTarget.value);
                }}
                value={name}
                label="Name"
                placeholder="Name of the company"
              />
              <div className="mt-3">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Description
                </label>
                <TextArea
                  error={descriptionError}
                  label="Description"
                  rows={2}
                  name="description"
                  onChange={(e) => {
                    setDescriptionError("");
                    setDescription(e.currentTarget.value);
                  }}
                  value={description}
                  placeholder="A little bit about the company..."
                />
              </div>
              {dealRunner === DealRunner.Seller ? (
                <TextInput
                  onChange={(e) => {
                    setWebsite(e.currentTarget.value);
                  }}
                  value={website}
                  secondaryLabel="Optional"
                  label="Website"
                  placeholder="https://www.liquidacquire.com"
                  margin="m 0 0 0"
                />
              ) : null}
            </div>
            <div className="flex justify-end mt-8 gap-x-3">
              <Button
                type="button"
                onClick={() => {
                  history.goBack();
                }}
                variant="neutral"
                text="Cancel"
              />
              <Button
                variant="positive"
                text="Next"
                isLoading={createDeal.isPending}
                loadingText="Loading..."
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const steps = [
  {
    id: "01",
    name: "Create deal",
  },
  {
    id: "02",
    name: "Data Room permissions",
  },
  {
    id: "03",
    name: "People",
  },
];

export function Steps(props: { currentStep: number }) {
  return (
    <div className="px-4 py-12 sm:px-6 lg:px-8">
      <nav className="flex justify-center" aria-label="Progress">
        <ol className="space-y-6">
          {steps.map((step, i) => (
            <li key={step.name}>
              {i + 1 < props.currentStep ? (
                <div className="group">
                  <span className="flex items-start">
                    <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-persian-600 group-hover:text-persian-800"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-persian-500 line-through group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : i + 1 === props.currentStep ? (
                <div className="flex items-start" aria-current="step">
                  <span
                    className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                    aria-hidden="true"
                  >
                    <span className="absolute h-4 w-4 rounded-full bg-persian-200" />
                    <span className="relative block h-2 w-2 rounded-full bg-persian-600" />
                  </span>
                  <span className="ml-3 text-sm font-medium text-persian-600">
                    {step.name}
                  </span>
                </div>
              ) : (
                <div className="group">
                  <div className="flex items-start">
                    <div
                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                      aria-hidden="true"
                    >
                      <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </p>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}
