import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import { QueryDealActivityArgs } from "../graphql/generated";

export function useInvalidateQueryKeys() {
  const queryClient = useQueryClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  function invalidateDealActivities() {
    const activityQueries = queryClient
      .getQueryCache()
      .findAll({ queryKey: ["DealActivity"] });
    for (const query of activityQueries) {
      if (query.queryKey.length > 1) {
        const dealId = (query.queryKey[1] as QueryDealActivityArgs)["dealId"];
        if (dealId === activeDealId) {
          queryClient.invalidateQueries({ queryKey: query.queryKey });
        }
      }
    }
  }

  function invalidateDataRoomFolders() {
    const folderQueries = queryClient
      .getQueryCache()
      .findAll({ queryKey: ["DataRoomFolder"] });
    for (const query of folderQueries) {
      queryClient.invalidateQueries({ queryKey: query.queryKey });
    }
  }

  function invalidateDataRooms() {
    const dataRoomQueries = queryClient
      .getQueryCache()
      .findAll({ queryKey: ["DataRoom"] });
    for (const query of dataRoomQueries) {
      if (query.queryKey.length > 1) {
        const dealId = (query.queryKey[1] as QueryDealActivityArgs)["dealId"];
        if (dealId === activeDealId) {
          queryClient.invalidateQueries({ queryKey: query.queryKey });
        }
      }
    }
  }

  return {
    invalidateDealActivities,
    invalidateDataRoomFolders,
    invalidateDataRooms,
  };
}
