import { withMarginTw } from "../styles/withMargin";

// export const H1 = tw.h1<{ margin?: string; margin2?: string }>`
//   ${withMarginTw}
//   ${(p) => (p.margin2 ? p.margin2 : "")}
//   text-2xl
//   font-bold
//   text-gray-700
// `;

export function H1(props: { margin?: string; children: React.ReactNode }) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <h1 className={`${margin} text-2xl font-bold text-persian-950/95`}>
      {props.children}
    </h1>
  );
}

export function H2(props: { margin?: string; children: React.ReactNode }) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <h2 className={`${margin} text-xl font-bold text-persian-950/95`}>
      {props.children}
    </h2>
  );
}

export function H3(props: {
  margin?: string;
  mode?: "dark" | "light";
  children: React.ReactNode;
}) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <h3
      className={`${margin} text-lg font-bold ${
        props.mode && props.mode === "dark"
          ? `text-white`
          : `text-persian-950/95`
      }`}
    >
      {props.children}
    </h3>
  );
}

export function H4(props: { margin?: string; children: React.ReactNode }) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <h4 className={`${margin} text-base font-bold text-persian-950/95`}>
      {props.children}
    </h4>
  );
}
