import { useQueryClient } from "@tanstack/react-query";
import { DealFirm, useRemoveDealFirmMutation } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";

export function RemoveDealFirm(props: {
  dealFirmGroupId: string;
  dealFirm: DealFirm;
  open: boolean;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const removeDealFirm = useRemoveDealFirmMutation(client);
  const activeDealId = useSelector(authSelectors.activeDealId);

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold text-gray-800">
          Remove {props.dealFirm.name} from the deal
        </h2>
        <CloseIcon onClose={props.onClose} />
      </div>
      <p className="text-sm text-gray-600 mt-2">
        Are you sure you want to remove this sub group from the deal? All users
        in the sub group will be removed.
      </p>
      <div className="flex justify-end mt-4">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onClose();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            removeDealFirm.mutate(
              {
                id: props.dealFirm.id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["DealFirmGroup", { id: props.dealFirmGroupId }],
                  });
                  props.onClose();
                },
              }
            );
          }}
        >
          Remove
        </button>
      </div>
    </AnimatedModal>
  );
}
