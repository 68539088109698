import { useParams } from "react-router-dom";
import useGqlClient from "../../../hooks/useGqlClient";
import { UseQueryResult, useQueryClient } from "@tanstack/react-query";
import {
  DdChecklistQuery,
  DueDiligenceChecklistItemFragment,
  DueDiligenceChecklistItemType,
  useDdChecklistQuery,
  useUpdateDueDiligenceChecklistItemMutation,
} from "../../../graphql/generated";
import Loading from "../../../components/Loading";
import { H3 } from "../../../components/Heading";
import { Card } from "../../../components/Card";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import { AddChecklistItemModal } from "./AddChecklistItemModal";
import { useState } from "react";
import { FilePill } from "../../../components/FilePill";
import { AddFilePill } from "../../../components/AddFilePill";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import { toasts } from "../../../components/toasts/toasts";
import { Pills } from "../../../components/Pills";
import { UserPill } from "../../../components/UserPill";
import { SelectUserPill } from "../../../components/SelectUserPill";

export function DDChecklist() {
  const { id } = useParams<{ id: string }>();

  const client = useGqlClient();

  const ddChecklistQuery = useDdChecklistQuery(client, {
    dealFirmGroupId: id,
  });

  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white sticky top-0 z-20 px-8 py-5 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>
              {!ddChecklistQuery.data || ddChecklistQuery.isPending
                ? "Loading..."
                : ddChecklistQuery.error
                ? "Error"
                : ddChecklistQuery.data.dealFirmGroup.name}
            </H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <div className="p-8 flex-1 flex">
        <DdChecklistContent query={ddChecklistQuery} />
      </div>
    </div>
  );
}

const headings = ["#", "Task", "Assigned to", "Deadline", "File", "Completed"];

function DdChecklistContent(props: {
  query: UseQueryResult<DdChecklistQuery, unknown>;
}) {
  const [openModal, setOpenModal] = useState<
    "add_precedent" | "add_subsequent" | ""
  >("");

  const query = props.query;
  const queryClient = useQueryClient();
  if (query.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (query.isPending || !query.data) {
    return <Loading />;
  }

  const conditionPrecedents = query.data.dealFirmGroup.ddChecklistItems.filter(
    (dd) => dd.type === DueDiligenceChecklistItemType.ConditionPrecedent
  );
  const conditionSubsequents = query.data.dealFirmGroup.ddChecklistItems.filter(
    (dd) => dd.type === DueDiligenceChecklistItemType.ConditionSubsequent
  );

  return (
    <div className="w-full xl:w-2/3">
      <div className="flex items-center justify-between">
        <p className="font-semibold text-gray-700 text-sm">
          Condition Precedents
        </p>
        <div className="w-52 flex items-center gap-x-1.5">
          <ProgressBar
            progress={
              conditionPrecedents.length === 0
                ? 0
                : (conditionPrecedents.filter((dd) => dd.completedAt).length /
                    conditionPrecedents.length) *
                  100
            }
          />
          <p className="text-xs text-gray-500/80">
            {conditionPrecedents.filter((dd) => dd.completedAt).length}/
            {conditionPrecedents.length}
          </p>
        </div>
      </div>
      <Card margin="m 0 0 0">
        <div className="p-2 px-1">
          <table>
            <thead>
              {headings.map((h, i) => {
                return (
                  <th
                    className={`text-xs truncate text-gray-500 px-4 pb-2 text-left ${
                      i === 1 ? "w-full" : ""
                    }`}
                  >
                    {h}
                  </th>
                );
              })}
            </thead>
            <tbody>
              {conditionPrecedents.map((cs, i) => {
                return (
                  <ChecklistItem
                    item={cs}
                    index={i + 1}
                    dealFirmGroupId={query.data.dealFirmGroup.id}
                  />
                );
              })}
            </tbody>
          </table>
          <div className="px-3">
            <button
              onClick={() => {
                setOpenModal("add_precedent");
              }}
              className="text-xs text-blue-500 hover:text-blue-600 font-semibold"
            >
              <PlusIcon className="w-4 h-4 inline-block -mt-1" />
              Add condition precedent
            </button>
          </div>
        </div>
      </Card>

      <div className="mt-12 flex items-center justify-between">
        <p className="font-semibold text-gray-700 text-sm">
          Condition Subsequents
        </p>
        <div className="w-52 flex items-center gap-x-1.5">
          <ProgressBar
            progress={
              conditionSubsequents.length === 0
                ? 0
                : (conditionSubsequents.filter((dd) => dd.completedAt).length /
                    conditionSubsequents.length) *
                  100
            }
          />
          <p className="text-xs text-gray-500/80">
            {conditionSubsequents.filter((dd) => dd.completedAt).length}/
            {conditionSubsequents.length}
          </p>
        </div>
      </div>
      <Card margin="m 0 0 0">
        <div className="p-2 px-1">
          <table>
            <thead>
              {headings.map((h, i) => {
                return (
                  <th
                    className={`text-xs truncate text-gray-500 px-4 pb-2 text-left ${
                      i === 1 ? "w-full" : ""
                    }`}
                  >
                    {h}
                  </th>
                );
              })}
            </thead>
            <tbody>
              {conditionSubsequents.map((cs, i) => {
                return (
                  <ChecklistItem
                    item={cs}
                    index={i + 1}
                    dealFirmGroupId={query.data.dealFirmGroup.id}
                  />
                );
              })}
            </tbody>
          </table>
          <div className="px-3">
            <button
              onClick={() => {
                setOpenModal("add_subsequent");
              }}
              className="text-xs text-blue-500 hover:text-blue-600 font-semibold"
            >
              <PlusIcon className="w-4 h-4 inline-block -mt-1" />
              Add condition subsequent
            </button>
          </div>
        </div>
      </Card>

      <AddChecklistItemModal
        dealFirmGroupId={query.data.dealFirmGroup.id}
        dealFirmGroupName={query.data.dealFirmGroup.name}
        type={
          openModal === "add_precedent"
            ? DueDiligenceChecklistItemType.ConditionPrecedent
            : DueDiligenceChecklistItemType.ConditionSubsequent
        }
        open={openModal === "add_precedent" || openModal === "add_subsequent"}
        onClose={() => {
          setOpenModal("");
          query.refetch();
        }}
      />
    </div>
  );
}

function ChecklistItem(props: {
  item: DueDiligenceChecklistItemFragment;
  index: number;
  dealFirmGroupId: string;
}) {
  const client = useGqlClient();
  const updateItem = useUpdateDueDiligenceChecklistItemMutation(client);
  const queryClient = useQueryClient();

  const item = props.item;

  function invalidateList() {
    queryClient.invalidateQueries({
      queryKey: [
        "DdChecklist",
        {
          dealFirmGroupId: props.dealFirmGroupId,
        },
      ],
    });
  }

  return (
    <tr>
      <td className="px-4 py-2 text-sm font-bold text-gray-400">
        {props.index}
      </td>
      <td className="px-4 py-2">
        <p className="text-gray-600 text-sm">{item.title}</p>
      </td>
      <td className="px-4 py-2">
        {item.assignedTo ? (
          <UserPill
            account={item.assignedTo}
            onRemove={() => {
              updateItem.mutate(
                {
                  input: {
                    id: props.item.id,
                    assignedTo: "",
                  },
                },
                {
                  onSuccess: () => {
                    invalidateList();
                    toasts.info("User removed");
                  },
                  onError: () => {
                    toasts.error("Failed to assign user");
                  },
                }
              );
            }}
          />
        ) : (
          <SelectUserPill
            onUserSelected={(acc) => {
              updateItem.mutate(
                {
                  input: {
                    id: props.item.id,
                    assignedTo: acc.id,
                  },
                },
                {
                  onSuccess: () => {
                    invalidateList();
                    toasts.success("User assigned");
                  },
                  onError: () => {
                    toasts.error("Failed to assign user");
                  },
                }
              );
            }}
          />
        )}
      </td>
      <td className={`px-4 py-2 ${"text-gray-500"} text-xs truncate`}>
        {item.dueDate ? (
          <p>{format(fromUnixTime(item.dueDate), "MMM dd")}</p>
        ) : (
          <p>No deadline</p>
        )}
      </td>

      <td className="px-4 py-2">
        <>
          {item.dataRoomFile ? (
            <Pills>
              <FilePill
                id={item.dataRoomFile.id}
                name={item.dataRoomFile.name}
                type={item.dataRoomFile.fileType}
                showDetailsCard={false}
                onRemove={() => {
                  updateItem.mutate(
                    {
                      input: {
                        id: item.id,
                        dataRoomFileID: "",
                      },
                    },
                    {
                      onSuccess: () => {
                        invalidateList();
                        toasts.success("File removed");
                      },
                      onError: () => {
                        toasts.error("Something went wrong");
                      },
                    }
                  );
                }}
              />
            </Pills>
          ) : (
            <AddFilePill
              onFileSelected={(f) => {
                updateItem.mutate(
                  {
                    input: {
                      id: item.id,
                      dataRoomFileID: f.id,
                    },
                  },
                  {
                    onSuccess: () => {
                      invalidateList();
                      toasts.success("File added");
                    },
                    onError: () => {
                      toasts.error("Something went wrong");
                    },
                  }
                );
              }}
            />
          )}
        </>
      </td>
      <td className="px-4 py-2">
        <button
          onClick={() => {
            updateItem.mutate(
              {
                input: {
                  id: item.id,
                  completedAt: item.completedAt ? 0 : getUnixTime(new Date()),
                },
              },
              {
                onSuccess: (data) => {
                  invalidateList();
                  if (data.updateDueDiligenceChecklistItem.completedAt) {
                    toasts.success("Item completed");
                  } else {
                    toasts.info("Item un-completed");
                  }
                },
                onError: () => {
                  if (item.completedAt) {
                    toasts.error("Failed to un-complete item");
                  } else {
                    toasts.error("Failed to complete item");
                  }
                },
              }
            );
          }}
        >
          <CheckCircleIcon
            className={`w-4 h-4 ${
              item.completedAt ? "text-green-400" : "text-gray-300"
            }`}
          />
        </button>
      </td>
    </tr>
  );
}

function ProgressBar(props: { progress: number; color?: "orange" | "green" }) {
  const color = props.color || "green";
  return (
    <div className="h-2 w-full bg-green-500/20 rounded-md">
      <div
        className={`h-2 ${
          color === "green" ? "bg-green-500" : "bg-orange-500"
        } rounded-md transition-all duration-300 ease-in-out`}
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
