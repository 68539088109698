import { useSelector } from "react-redux";
import {
  AllFoldersQuery,
  DealExportStatus,
  useAllFoldersQuery,
  useCreateDealExportMutation,
  useDealExportsQuery,
} from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import { authSelectors } from "../../../../store/auth/selector";
import Loading from "../../../../components/Loading";
import { H4 } from "../../../../components/Heading";
import { Card } from "../../../../components/Card";
import {
  ArrowDownTrayIcon,
  FolderIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { AnimatedModal } from "../../../../components/AnimatedModal";
import { CloseIcon } from "../../../../components/CloseIcon";
import { useEffect, useState } from "react";
import { UseQueryResult, useQueryClient } from "@tanstack/react-query";
import { classNames } from "../../../../utils/cn";
import { FileIcon } from "../../../../components/FileIcon";
import { Button } from "../../../../components/tailwind/Button";
import { toasts } from "../../../../components/toasts/toasts";
import { Spinner } from "../../../../components/icons/Spinner";
import { TextInput } from "../../../../components/tailwind/TextInput";
import { format, formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import saveAs from "file-saver";

export function DealExports() {
  const [createDealExportModalOpen, setCreateDealExportModalOpen] =
    useState(false);
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dealExportsQuery = useDealExportsQuery(client, {
    dealId: activeDealId ?? "",
  });

  if (dealExportsQuery.error) {
    return (
      <div className="flex flex-1 w-full h-full justify-center items-center">
        <p>Error loading deal exports</p>
      </div>
    );
  }

  if (!dealExportsQuery.data || dealExportsQuery.isPending) {
    return <Loading />;
  }

  return (
    <div className="w-full xl:w-1/2">
      <div className="flex items-center justify-between">
        <H4>Deal Exports</H4>
        <button
          onClick={() => {
            setCreateDealExportModalOpen(true);
          }}
          className="text-sm text-indigo-500 hover:text-indigo-600 font-semibold flex items-center"
        >
          <PlusIcon className="h-4 w-4" />
          New export
        </button>
      </div>
      <Card padding="m" margin="s 0 0 0">
        {dealExportsQuery.data.deal.dealExports.length === 0 ? (
          <p className="text-sm text-gray-500 text-center">No exports</p>
        ) : null}
        {dealExportsQuery.data.deal.dealExports.map((dealExport) => (
          <div
            key={dealExport.id}
            className="flex items-center justify-between py-2"
          >
            <div>
              <p className="text-sm">
                {dealExport.name
                  ? dealExport.name
                  : format(fromUnixTime(dealExport.createdAt), "dd/MM/yyyy")}
              </p>
              <p className="text-xs text-gray-500">
                Created by {dealExport.createdBy.email}
                {" \u2022 "}
                {formatDistanceToNowStrict(fromUnixTime(dealExport.createdAt), {
                  addSuffix: true,
                })}
              </p>
            </div>
            {dealExport.status === DealExportStatus.Complete ? (
              <div className="flex items-center gap-x-2">
                <p className="text-green-700 text-xs font-semibold">Complete</p>
                <button
                  disabled={!dealExport.s3PresignedUrl}
                  onClick={async () => {
                    if (!dealExport.s3PresignedUrl) {
                      return;
                    }

                    const fileRes = await fetch(dealExport.s3PresignedUrl);

                    const blob = await fileRes.blob();

                    saveAs(blob, `${dealExport.name}`);
                  }}
                  className=" inline-flex items-center gap-x-2 rounded-md bg-white px-2 py-1.5 text-sm font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  <ArrowDownTrayIcon className="h-5 w-5" />
                </button>
              </div>
            ) : dealExport.status === DealExportStatus.Failed ? (
              <p className="text-red-700 text-xs font-semibold">Failed</p>
            ) : (
              <div className="flex items-center -space-x-1.5">
                <Spinner color="gray" size="s" />
                <p className="text-gray-500 text-xs font-semibold">
                  Processing
                </p>
              </div>
            )}
          </div>
        ))}
      </Card>

      <CreateDealExportModal
        open={createDealExportModalOpen}
        onClose={() => setCreateDealExportModalOpen(false)}
        dealId={activeDealId ?? ""}
      />
    </div>
  );
}

export interface SelectedExportFiles {
  folders: {
    [key: string]: {
      selected: boolean;
      parentFolders: string[];
    };
  };

  files: {
    [key: string]: {
      selected: boolean;
      folderId: string;
    };
  };
}

function CreateDealExportModal(props: {
  open: boolean;
  onClose: () => void;
  dealId: string;
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const allFoldersQuery = useAllFoldersQuery(client, {
    dealId: props.dealId,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const createDealExport = useCreateDealExportMutation(client);

  const [selectedFiles, setSelectedFiles] = useState<SelectedExportFiles>({
    folders: {},
    files: {},
  });

  useEffect(() => {
    if (!allFoldersQuery.data || !props.open) {
      return;
    }

    const selectedFiles: SelectedExportFiles = {
      folders: {},
      files: {},
    };

    allFoldersQuery.data.deal.dataRoom.allFolders.forEach((folder) => {
      selectedFiles.folders[folder.id] = {
        selected: false,
        parentFolders: folder.parentFolders.map((f) => f.id),
      };

      folder.files.forEach((file) => {
        selectedFiles.files[file.id] = {
          selected: false,
          folderId: folder.id,
        };
      });
    });

    setSelectedFiles(selectedFiles);
  }, [allFoldersQuery.data, props.open]);

  function handleFileToggle(
    folderId: string,
    fileId: string,
    selected: boolean
  ) {
    const newSelectedFiles = { ...selectedFiles };

    newSelectedFiles.files[fileId].selected = selected;

    if (selected) {
      newSelectedFiles.folders[folderId].selected = true;
      newSelectedFiles.folders[folderId].parentFolders.forEach(
        (parentFolderId) => {
          newSelectedFiles.folders[parentFolderId].selected = true;
        }
      );
    }

    setSelectedFiles(newSelectedFiles);
  }

  function handleFolderToggle(folderId: string, selected: boolean) {
    const newSelectedFiles = { ...selectedFiles };
    newSelectedFiles.folders[folderId].selected = selected;

    if (selected) {
      newSelectedFiles.folders[folderId].parentFolders.forEach(
        (parentFolderId) => {
          newSelectedFiles.folders[parentFolderId].selected = true;
        }
      );
    } else {
      Object.keys(newSelectedFiles.folders).forEach((folderId) => {
        if (
          newSelectedFiles.folders[folderId].parentFolders.includes(folderId)
        ) {
          newSelectedFiles.folders[folderId].selected = false;
          Object.keys(newSelectedFiles.files).forEach((fileId) => {
            if (newSelectedFiles.files[fileId].folderId === folderId) {
              newSelectedFiles.files[fileId].selected = false;
            }
          });
        }
      });

      Object.keys(newSelectedFiles.files).forEach((fileId) => {
        if (newSelectedFiles.files[fileId].folderId === folderId) {
          newSelectedFiles.files[fileId].selected = false;
        }
      });
    }

    setSelectedFiles(newSelectedFiles);
  }

  function isAllFilesSelected() {
    return Object.keys(selectedFiles.files).every((fileId) =>
      selectedFiles.files[fileId] ? selectedFiles.files[fileId].selected : false
    );
  }

  function clearForm() {
    setName("");
    setNameError("");
    setSelectedFiles({
      folders: {},
      files: {},
    });
  }

  return (
    <AnimatedModal
      open={props.open}
      onClose={() => {
        clearForm();
        props.onClose();
      }}
      size="xxl"
    >
      <div>
        <div className="flex justify-between items-center">
          <H4>Create Export</H4>
          <CloseIcon
            onClose={() => {
              clearForm();
              props.onClose();
            }}
          />
        </div>

        <TextInput
          margin="l 0 0 0"
          label="Name"
          value={name}
          onChange={(e) => {
            setNameError("");
            setName(e.currentTarget.value);
          }}
          error={nameError}
        />

        <div className="mt-4 rounded-md border-gray-200 border overflow-y-hidden">
          <div className="w-full">
            <input
              type="text"
              className="px-3 py-1.5 text-sm focus:ring-0 border-b border-x-0 border-t-0 border-gray-200 focus:border-gray-200"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
            />
          </div>
          <div
            onClick={() => {
              if (isAllFilesSelected()) {
                return;
              }

              const newSelectedFiles = { ...selectedFiles };
              Object.keys(newSelectedFiles.files).forEach((fileId) => {
                handleFileToggle(
                  newSelectedFiles.files[fileId].folderId,
                  fileId,
                  true
                );
              });

              setSelectedFiles(newSelectedFiles);
            }}
            className="overflow-x-visible relative overflow-y-scroll scrollbar-thin h-80"
          >
            <div className="px-2 py-1 cursor-pointer flex items-center justify-between">
              <p className="text-sm font-semibold text-gray-700">All files </p>
              <input
                type="checkbox"
                className="rounded-md"
                checked={isAllFilesSelected()}
              />
            </div>
            <CreateDealExportContent
              query={allFoldersQuery}
              onFileSelected={(fileId, folderId) => {
                handleFileToggle(
                  folderId,
                  fileId,
                  !selectedFiles.files[fileId].selected
                );
              }}
              onFolderSelected={(folderId) => {
                handleFolderToggle(
                  folderId,
                  !selectedFiles.folders[folderId].selected
                );
              }}
              onAllFilesSelected={() => {}}
              searchTerm={searchTerm}
              selectedFiles={selectedFiles}
            />
          </div>
        </div>
        <div className="mt-3 flex justify-end items-center gap-x-4">
          <p className="text-sm text-gray-500">
            {
              Object.keys(selectedFiles.files).filter(
                (fileId) => selectedFiles.files[fileId].selected
              ).length
            }{" "}
            files
          </p>
          <Button
            isLoading={createDealExport.isPending}
            onClick={() => {
              if (
                Object.keys(selectedFiles.files).filter(
                  (fileId) => selectedFiles.files[fileId].selected
                ).length === 0
              ) {
                return;
              }

              if (!name) {
                setNameError("Name is required");
                return;
              }

              createDealExport.mutate(
                {
                  input: {
                    dealID: props.dealId,
                    dataRoomFileIDs: Object.keys(selectedFiles.files).filter(
                      (fileId) => selectedFiles.files[fileId].selected
                    ),
                    keepFolderStructure: true,
                    name: name,
                  },
                },
                {
                  onSuccess: () => {
                    clearForm();
                    queryClient.invalidateQueries({
                      queryKey: [
                        "DealExports",
                        {
                          dealId: props.dealId,
                        },
                      ],
                    });
                    props.onClose();
                    toasts.info("Creating export...");
                  },
                }
              );
            }}
            isDisabled={
              Object.keys(selectedFiles.files).filter(
                (fileId) => selectedFiles.files[fileId].selected
              ).length === 0 || !name
            }
            variant="positive"
            text="Create export"
          />
        </div>
        {createDealExport.error ? (
          <p className="text-sm text-red-700">Failed to create export</p>
        ) : null}
      </div>
    </AnimatedModal>
  );
}

function CreateDealExportContent(props: {
  query: UseQueryResult<AllFoldersQuery, unknown>;
  onFileSelected: (fileId: string, folderId: string) => void;
  onFolderSelected: (folderId: string) => void;
  onAllFilesSelected: () => void;
  searchTerm: string;
  selectedFiles: SelectedExportFiles;
}) {
  if (props.query.error) {
    return (
      <div>
        <p className="font-semibold text-gray-700">Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return <Loading />;
  }

  return (
    <div>
      {props.query.data.deal.dataRoom.allFolders.map((folder) => {
        return (
          <div key={folder.id}>
            <FolderContent
              folder={folder}
              selectedFiles={props.selectedFiles}
              onToggle={() => {
                props.onFolderSelected(folder.id);
              }}
              onToggleFile={(fileId: string) => {
                props.onFileSelected(fileId, folder.id);
              }}
              searchTerm={props.searchTerm}
            />
          </div>
        );
      })}
    </div>
  );
}

function FolderContent(props: {
  folder: AllFoldersQuery["deal"]["dataRoom"]["allFolders"][0];
  selectedFiles: SelectedExportFiles;
  onToggle: () => void;
  onToggleFile: (fileId: string) => void;
  searchTerm: string;
}) {
  const [showChildren, setShowChildren] = useState(true);
  const selected = props.selectedFiles.folders[props.folder.id]
    ? props.selectedFiles.folders[props.folder.id].selected
    : false;

  if (props.searchTerm.length > 2) {
    if (
      !props.folder.name
        .toLowerCase()
        .includes(props.searchTerm.toLowerCase()) &&
      props.folder.files.filter((file) =>
        file.name.toLowerCase().includes(props.searchTerm.toLowerCase())
      ).length === 0
    ) {
      return null;
    }
  }

  return (
    <div className="">
      <div
        className={classNames(
          "items-center  hover:bg-gray-200/70 px-2 flex py-1 justify-between cursor-pointer",
          selected ? "" : "opacity-50 hover:opacity-100"
        )}
        onClick={(e) => {
          e.stopPropagation();
          props.onToggle();
        }}
      >
        <div className="flex items-center">
          <FolderIcon className="w-5 h-5 text-blue-700/70" />
          <p className="font-semibold mt-1 ml-1 select-none text-gray-800 text-sm">
            {props.folder.parentFolders.length > 0
              ? `${props.folder.parentFolders
                  .map((f) => (f.name ? f.name : "Home"))
                  .reverse()
                  .join(" / ")}`
              : "Home /"}
          </p>
        </div>
        <input type="checkbox" className="rounded-md " checked={selected} />
      </div>
      {showChildren ? (
        <div className="">
          <div>
            {props.folder.files
              .filter((file) =>
                props.searchTerm.length > 2
                  ? file.name
                      .toLowerCase()
                      .includes(props.searchTerm.toLowerCase())
                  : true
              )
              .map((file) => {
                const fileSelected = props.selectedFiles.files[file.id]
                  ? props.selectedFiles.files[file.id].selected
                  : false;

                return (
                  <div
                    key={file.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onToggleFile(file.id);
                    }}
                    className={`cursor-pointer px-2 justify-between hover:bg-gray-200/70  items-center flex  py-1 ${
                      !fileSelected ? "opacity-50 hover:opacity-100" : ""
                    }`}
                  >
                    <div className="ml-2 flex items-center">
                      <FileIcon fileType={file.fileType} />
                      <p className="ml-1 select-none font-semibold text-sm text-gray-600">
                        {file.name}
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      className="rounded-md "
                      checked={fileSelected}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
