import {
  ExclamationCircleIcon,
  XCircleIcon,
  ChartBarIcon as ChartBarIconSolid,
} from "@heroicons/react/20/solid";
import { TaskPriority } from "../graphql/generated";
import { ChartBarIcon } from "@heroicons/react/24/outline";

export function TaskPriorityPill(props: {
  priority: TaskPriority;
  onRemove?: () => void;
}) {
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="cursor-pointer  bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
        <TaskPriorityIcon priority={props.priority} />
        <p className="text-xs ml-1 truncate">
          {getTaskPriorityText(props.priority)}
        </p>
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}

export function getTaskPriorityText(priority: TaskPriority) {
  switch (priority) {
    case TaskPriority.Low:
      return "Low";
    case TaskPriority.Medium:
      return "Medium";
    case TaskPriority.High:
      return "High";
    case TaskPriority.Urgent:
      return "Urgent";
    default:
      return "";
  }
}

export function TaskPriorityIcon(props: { priority?: TaskPriority }) {
  if (!props.priority || props.priority === TaskPriority.None) {
    return <ChartBarIcon className="w-4 h-4 text-gray-400 opacity-50" />;
  }

  switch (props.priority) {
    case TaskPriority.Low:
      return <ChartBarIcon className="w-4 h-4 text-gray-400" />;
    case TaskPriority.Medium:
      return <ChartBarIconSolid className="w-4 h-4 text-gray-400" />;
    case TaskPriority.High:
      return <ChartBarIconSolid className="w-4 h-4 text-gray-700" />;
    case TaskPriority.Urgent:
      return <ExclamationCircleIcon className="w-4 h-4 text-orange-500" />;
    default:
      return null;
  }
}
