import { useEffect, useState } from "react";
import {
  DataRoomFileQuery,
  DataRoomPermission,
  useDataRoomFileQuery,
  useDataRoomQuery,
  useUpdateDataRoomFileMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { AnimatedModal } from "../AnimatedModal";
import { CloseIcon } from "../CloseIcon";
import { H3 } from "../Heading";
import Loading from "../Loading";
import { TextInput } from "../tailwind/TextInput";
import { TextArea } from "../tailwind/TextArea";
import Dropdown, { Option } from "../tailwind/Dropdown";
import { Button } from "../tailwind/Button";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdated?: () => void;
  dataRoomFileId: string;
}

export function EditFile(props: Props) {
  const client = useGqlClient();

  const activeDealId = useSelector(authSelectors.activeDealId);

  const [fileId, setFileId] = useState(props.dataRoomFileId);
  useEffect(() => {
    if (props.dataRoomFileId) {
      setFileId(props.dataRoomFileId);
    }
  }, [props.dataRoomFileId]);

  const { data, isLoading, error } = useDataRoomFileQuery(client, {
    id: fileId,
  });

  const dataRoomQuery = useDataRoomQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <EditFileHeader
        onClose={props.onClose}
        data={data}
        isLoading={isLoading || dataRoomQuery.isPending}
        error={error || dataRoomQuery.error}
      />
      <EditFileContent
        data={data}
        isLoading={isLoading || dataRoomQuery.isPending}
        error={error || dataRoomQuery.error}
        onClose={props.onClose}
        onUpdated={props.onUpdated}
        permissions={
          dataRoomQuery.data ? dataRoomQuery.data.deal.dataRoom.permissions : []
        }
      />
    </AnimatedModal>
  );
}

interface EditFileProps {
  isLoading: boolean;
  error: any;
  data: DataRoomFileQuery | undefined;
  onClose: () => void;
}

function EditFileHeader(props: EditFileProps) {
  let title = "Edit File";

  if (props.error) {
    title = "Error";
  }

  if (props.data) {
    title = `Edit ${props.data.dataRoomFile.name}`;
  }

  return (
    <div className="flex items-center justify-between">
      <H3>{title}</H3>
      <CloseIcon onClose={props.onClose} />
    </div>
  );
}

function EditFileContent(
  props: EditFileProps & {
    onUpdated?: () => void;
    permissions: DataRoomPermission[];
  }
) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const [fileName, setName] = useState("");
  const [description, setDescription] = useState("");
  const [permission, setPermission] = useState<Option>({
    label: "Loading...",
    description: "...",
    value: "",
  });

  const updateDataRoomFile = useUpdateDataRoomFileMutation(client);

  useEffect(() => {
    if (props.data) {
      setName(props.data.dataRoomFile.name);
      setDescription(props.data.dataRoomFile.description);
      setPermission({
        value: props.data.dataRoomFile.permission.id,
        label: props.data.dataRoomFile.permission.name,
        description: props.data.dataRoomFile.permission.description,
      });
    }
  }, [props.data, props.isLoading, props.error]);

  if (props.error) {
    return (
      <div className="flex justify-center items-center p-4">
        <p className="text-gray-700 font-bold">Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.data) {
    return <Loading />;
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (!props.data) {
            return;
          }

          updateDataRoomFile.mutate(
            {
              input: {
                id: props.data.dataRoomFile.id,
                name: fileName,
                permissionID: permission.value,
                description,
              },
            },
            {
              onSuccess: () => {
                if (props.onUpdated) {
                  props.onUpdated();
                }

                if (props.data) {
                  queryClient.invalidateQueries({
                    queryKey: [
                      "DataRoomFile",
                      { id: props.data.dataRoomFile.id },
                    ],
                  });
                  queryClient.invalidateQueries({
                    queryKey: [
                      "DataRoomFolder",
                      {
                        id: props.data.dataRoomFile.folderId,
                      },
                    ],
                  });
                }

                props.onClose();
              },
            }
          );
        }}
      >
        <TextInput
          margin="m 0 0 0"
          ignore1p
          label="Name"
          placeholder="Name of the file"
          value={fileName}
          onChange={(e) => setName(e.currentTarget.value)}
        />

        <p className="mt-3 block text-sm font-medium leading-6 text-gray-900">
          Description
        </p>
        <TextArea
          label="Description"
          rows={2}
          placeholder="A description of the file..."
          name="description"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
        />

        <div className="mt-3">
          <Dropdown
            label="Permission level"
            defaultOption={{
              label: props.permissions[0].name,
              description: props.permissions[0].description,
              value: props.permissions[0].id,
            }}
            selectedOption={permission ?? undefined}
            onSelect={(o) => {
              setPermission(o);
            }}
            options={props.permissions.map((p) => {
              return {
                value: p.id,
                label: p.name,
                description: p.description,
              };
            })}
          />
        </div>

        <div className="mt-12 flex justify-end">
          <Button
            type="submit"
            text="Save"
            variant="positive"
            isLoading={updateDataRoomFile.isPending}
            isDisabled={fileName === ""}
            loadingText="Saving..."
          />
        </div>
      </form>
    </div>
  );
}
