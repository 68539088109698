import { useRef, useEffect, useState } from "react";
import PSPDFKit, { Instance, ToolbarItem } from "pspdfkit";
import { FileType } from "../graphql/generated";
import { pspdfkitEndpoint } from "../utils/endpoints";

export function PdfViewer(props: { url: string; highlights?: string[] }) {
  const containerRef = useRef(null);
  const [instance, setInstance] = useState<Instance | null>(null);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.
    if (!container) {
      return;
    }

    (async function () {
      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      const i = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.url,
        serverUrl: "http://localhost:5001",
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        initialViewState: PSPDFKit.viewStateFromOpenParameters(
          new PSPDFKit.ViewState({
            showToolbar: false,
          })
        ),
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
      });

      setInstance(i);

      if (props.highlights && i) {
        let annotations = [];
        for (const h of props.highlights) {
          if (!h) {
            continue;
          }
          const results = await i.search(h);

          const a = results.map((result) => {
            return new PSPDFKit.Annotations.HighlightAnnotation({
              pageIndex: result.pageIndex,
              rects: result.rectsOnPage,
              boundingBox: PSPDFKit.Geometry.Rect.union(result.rectsOnPage),
            });
          });

          annotations.push(...a);
        }

        for (const a of annotations) {
          i.create(a);
        }
      }
    })();

    return () => {
      PSPDFKit.unload(container);
    };
  }, [props.url]);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
}

export function PdfViewerDocumentId(props: {
  documentId: string;
  token: string;
  fileType: FileType;
  highlights?: string[];
  pageIndex?: number;
  rectsOnPage?: number[][];
  showToolbar?: boolean;
  search?: string;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [instance, setInstance] = useState<Instance | null>(null);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.
    if (!container) {
      return;
    }

    const toolbarItems: ToolbarItem[] = [
      {
        type: "sidebar-thumbnails",
      },
      {
        type: "pager",
      },
      {
        type: "spacer",
      },
      {
        type: "search",
      },
    ];

    (async function () {
      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      const i = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        autoSaveMode: PSPDFKit.AutoSaveMode.DISABLED,
        // The document to open.
        documentId: props.documentId,
        authPayload: {
          jwt: props.token,
        },
        instant: false,
        serverUrl: pspdfkitEndpoint(),
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        toolbarItems: toolbarItems,

        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
      });

      setInstance(i);

      if (props.rectsOnPage && i) {
        const rects = props.rectsOnPage.map((rect) => {
          return new PSPDFKit.Geometry.Rect({
            left: rect[0],
            top: rect[1],
            width: rect[2],
            height: rect[3],
          });
        });

        const rcts = PSPDFKit.Immutable.List(rects);

        const a = new PSPDFKit.Annotations.HighlightAnnotation({
          pageIndex: props.pageIndex || 0,
          rects: rcts,
          boundingBox: PSPDFKit.Geometry.Rect.union(rcts),
          color: PSPDFKit.Color.LIGHT_YELLOW,
        });

        i.create(a);
      }

      if (props.highlights && i) {
        let annotations = [];
        for (const h of props.highlights) {
          if (!h) {
            continue;
          }
          const results = await i.search(h);

          const a = results.map((result) => {
            return new PSPDFKit.Annotations.HighlightAnnotation({
              pageIndex: result.pageIndex,
              rects: result.rectsOnPage,
              boundingBox: PSPDFKit.Geometry.Rect.union(result.rectsOnPage),
            });
          });

          annotations.push(...a);
        }

        for (const a of annotations) {
          i.create(a);
        }
      }

      if (props.showToolbar === false) {
        i.setViewState(i.viewState.set("showToolbar", props.showToolbar));
      }

      if (props.pageIndex) {
        if (
          !Number.isNaN(props.pageIndex) &&
          props.pageIndex < i.totalPageCount
        ) {
          i.setViewState(i.viewState.set("currentPageIndex", props.pageIndex));
        }
      }
    })();

    return () => {
      PSPDFKit.unload(container);
    };
  }, [props.documentId]);

  useEffect(() => {
    if (!instance || !props.search) {
      return;
    }

    async function addAnnotions() {
      const i = instance;
      let annotations = [];
      if (!props.search || !i || props.search.length < 3) {
        return;
      }

      const results = await i.search(props.search);

      const a = results.map((result) => {
        return new PSPDFKit.Annotations.HighlightAnnotation({
          pageIndex: result.pageIndex,
          rects: result.rectsOnPage,
          boundingBox: PSPDFKit.Geometry.Rect.union(result.rectsOnPage),
        });
      });

      annotations.push(...a);

      for (const a of annotations) {
        i.create(a);
      }
    }
    addAnnotions();
  }, [props.search, instance]);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
}
