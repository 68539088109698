import { useQueryClient } from "@tanstack/react-query";
import {
  AccessQuery,
  DealFirmGroupQuery,
  useUpdateDealFirmGroupMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useState } from "react";
import {
  GranularAccess,
  granularAccessToFileInput,
  granularAccessToFolderInput,
} from ".";
import { AnimatedModal } from "../../components/AnimatedModal";
import { EditDataRoomPermissions } from "./EditDataRoomPermissions";
import { Button } from "../../components/tailwind/Button";
import { H4 } from "../../components/Heading";
import { CloseIcon } from "../../components/CloseIcon";
import { TextInput } from "../../components/tailwind/TextInput";
import { classNames } from "../../utils/cn";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const tabs = ["Data Room access"];

export function EditDealFirmGroup(props: {
  dealFirmGroup: DealFirmGroupQuery["dealFirmGroup"];
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  open: boolean;
  onClose: () => void;
}) {
  const [name, setName] = useState(props.dealFirmGroup.name);
  const [nameError, setNameError] = useState("");
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const updateDealFirmGroup = useUpdateDealFirmGroupMutation(client);

  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(
    props.dealFirmGroup.overrideDataRoomAccess
  );

  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [selectedDataRoomPermission, setSelectedDataRoomPermission] = useState<
    DealFirmGroupQuery["dealFirmGroup"]["dataRoomPermission"]
  >(props.dealFirmGroup.dataRoomPermission);

  return (
    <AnimatedModal
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      size="xxl"
    >
      <div>
        <div className="flex items-center justify-between">
          <H4 margin="0">Edit {props.dealFirmGroup.name}</H4>
          <CloseIcon onClose={props.onClose} />
        </div>
        <div>
          <div className="">
            <div className="mt-3 w-2/3">
              <TextInput
                margin="0"
                label="Name"
                value={name}
                onChange={(e) => {
                  setNameError("");
                  setName(e.currentTarget.value);
                }}
                error={nameError}
              />
            </div>
            <div className="mt-3 border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => {
                      setSelectedTab(tab);
                    }}
                    className={classNames(
                      tab === selectedTab
                        ? "border-gray-600 text-gray-700"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab === selectedTab ? "page" : undefined}
                  >
                    {tab}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="font-semibold text-gray-600 text-sm">
            Permission level
          </p>
          <p className="text-sm text-gray-500 leading-tight">
            This is set at the group level
          </p>
          <div className="mt-2 flex gap-x-3">
            {props.permissions.map((permission) => {
              return (
                <button
                  key={permission.id}
                  onClick={() => {
                    setSelectedDataRoomPermission(permission);
                  }}
                  className={classNames(
                    "p-2 rounded-md border-2 relative  group",
                    overrideDataRoomAccess ? "border-gray-300 opacity-60" : "",
                    !overrideDataRoomAccess &&
                      permission.id === selectedDataRoomPermission.id
                      ? "border-indigo-500"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel <
                        selectedDataRoomPermission.accessLevel
                      ? "border-indigo-500 opacity-60"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel >
                        selectedDataRoomPermission.accessLevel
                      ? "opacity-50 "
                      : ""
                  )}
                >
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-semibold text-gray-800">
                      {permission.name}
                    </p>
                    {permission.id === selectedDataRoomPermission.id ||
                    permission.accessLevel <
                      selectedDataRoomPermission.accessLevel ? (
                      <CheckCircleIcon
                        className={`w-4 h-4 ${
                          overrideDataRoomAccess
                            ? "text-gray-500"
                            : "text-indigo-500"
                        }`}
                      />
                    ) : null}
                  </div>
                  <p className="leading-tight mt-1 text-sm text-gray-500">
                    {permission.description}
                  </p>
                </button>
              );
            })}
          </div>
        </div>
        <EditDataRoomPermissions
          firmGroupId={props.dealFirmGroup.id}
          showPermissions={false}
          initialDataRoomPermission={props.dealFirmGroup.dataRoomPermission}
          firmGroupType={props.dealFirmGroup.type}
          initialGranularAccess={props.dealFirmGroup.granularAccess}
          dataRoomPermissions={props.permissions}
          overrideDataRoomAccess={overrideDataRoomAccess}
          onOverrideDataRoomAccessChange={(checked) => {
            setOverrideDataRoomAccess(checked);
          }}
          granularAccess={granularAccess}
          onGranularAccessChange={(granularAccess) => {
            setGranularAccess(granularAccess);
          }}
        />
        <div className="w-full h-px bg-gray-200 my-4"></div>
        <div className="flex justify-end mt-5">
          <Button
            text="Save"
            variant="positive"
            isLoading={updateDealFirmGroup.isPending}
            loadingText="Saving..."
            onClick={() => {
              if (!name) {
                setNameError("Name is required");
                return;
              }

              updateDealFirmGroup.mutate(
                {
                  input: {
                    id: props.dealFirmGroup.id,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                    name,
                    overrideDataRoomAccess,
                    dataRoomPermissionId: selectedDataRoomPermission.id,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: [
                        "DealFirmGroup",
                        { id: props.dealFirmGroup.id },
                      ],
                    });
                    props.onClose();
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
