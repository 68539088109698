import { Deal, useFirmQuery } from "../graphql/generated";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Combobox, Popover, Transition } from "@headlessui/react";
import { useRef, useState } from "react";
import useGqlClient from "../hooks/useGqlClient";
import { classNames } from "../utils/cn";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import CompanyLogo from "./CompanyLogo";

export function SelectDealPill(props: {
  onDealSelected: (deal: Partial<Deal>) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="relative cursor-pointer  hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1"
      >
        <PlusIcon className="h-5 w-5 text-gray-600/80" />
        <p className="text-xs select-none text-gray-600 ml-1 truncate">
          Select deal
        </p>
        <Transition
          enter="ease-in-out duration-50"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Popover.Panel className="z-20 absolute mt-5 w-96 -translate-x-1/3 transform px-4">
            <PopOut
              onDealSelected={(d) => {
                props.onDealSelected(d);
                buttonRef.current?.click();
              }}
            />
          </Popover.Panel>
        </Transition>
      </Popover.Button>
    </Popover>
  );
}

const PopOut = ({
  onDealSelected,
}: {
  onDealSelected: (deal: Partial<Deal>) => void;
}) => {
  // Adjust the style below based on the exact positioning you need

  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");

  return (
    <div className="border rounded-md ml-10">
      <Combobox
        onChange={(f: Partial<Deal>) => {
          onDealSelected(f);
        }}
      >
        <div className="">
          <div className="relative w-full cursor-default overflow-hidden rounded-t-md bg-white text-left border-b border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder="Search deals..."
              autoFocus
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>

          <div className="bg-white rounded-b-md">
            <SearchResults searchTerm={query} />
          </div>
        </div>
      </Combobox>
    </div>
  );
};

interface SearchResultsProps {
  searchTerm: string;
}

function SearchResults(props: SearchResultsProps) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const account = useSelector(authSelectors.account);

  const firmQuery = useFirmQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  });

  if (!account) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <Combobox.Options
        static
        className="max-h-80 scroll-py-2 divide-y  divide-gray-100 overflow-y-auto"
      >
        <li>
          <ul className="text-sm text-gray-700">
            {account.deals
              .filter((f) =>
                f.company.name
                  .toLowerCase()
                  .includes(props.searchTerm.toLowerCase())
              )
              .map((deal) => {
                return (
                  <DealContent
                    deal={deal as Deal}
                    searchTerm={props.searchTerm}
                  />
                );
              })}
          </ul>
        </li>
      </Combobox.Options>
    </>
  );
}

function DealContent(props: { deal: Partial<Deal>; searchTerm: string }) {
  const deal = props.deal;

  return (
    <Combobox.Option
      key={deal.id}
      value={deal}
      className={({ active }) =>
        classNames(
          "cursor-pointer select-none last:rounded-b-md px-3 py-2",
          active ? "bg-gray-200/70" : ""
        )
      }
    >
      {({ active }) => (
        <div className="">
          <div className="flex items-center gap-x-2">
            <CompanyLogo
              logo={deal.company?.logo}
              withShadow={false}
              borderSize={3}
              size={24}
              name={deal.company ? deal.company.name : ""}
              bgColor={deal.company ? deal.company.logoColor : "#000"}
            />
            <p className=" font-bold leading-tight  text-sm text-left">
              {deal.company ? deal.company.name : ""}
            </p>
          </div>
        </div>
      )}
    </Combobox.Option>
  );
}
