import { useQueryClient } from "@tanstack/react-query";
import {
  DealFirmGroup,
  useRemoveDealFirmGroupMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { useHistory } from "react-router-dom";

export function RemoveGroup(props: {
  dealFirmGroup: DealFirmGroup;
  open: boolean;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const removeDealFirmGroup = useRemoveDealFirmGroupMutation(client);
  const activeDealId = useSelector(authSelectors.activeDealId);
  const history = useHistory();

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold text-gray-800">
          Remove {props.dealFirmGroup.name} from the deal
        </h2>
        <CloseIcon onClose={props.onClose} />
      </div>
      <p className="text-sm text-gray-600 mt-2">
        Are you sure you want to remove this group from the deal? All users in
        the group will be removed.
      </p>
      <div className="flex justify-end mt-4">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onClose();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            removeDealFirmGroup.mutate(
              {
                id: props.dealFirmGroup.id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: [
                      "Access",
                      { dealId: activeDealId ? activeDealId : "" },
                    ],
                  });
                  history.push(`/access`);
                  props.onClose();
                },
              }
            );
          }}
        >
          Remove
        </button>
      </div>
    </AnimatedModal>
  );
}
