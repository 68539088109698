import { useRef } from "react";
import { TaskPriority } from "../graphql/generated";
import { Combobox, Popover, Transition } from "@headlessui/react";
import { getTaskPriorityText, TaskPriorityIcon } from "./TaskPriorityPill";
import { classNames } from "../utils/cn";
import { PlusIcon } from "@heroicons/react/20/solid";

export function SelectTaskPriority(props: {
  onPriorityChanged: (priority: TaskPriority) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="relative cursor-pointer  hover:border-gray-400 bg-white hover:shadow-sm flex items-center rounded-2xl border px-2 py-1"
      >
        <PlusIcon className="w-4 h-4 text-gray-500" />
        <p className="text-sm select-none text-gray-600 ml-1 truncate">
          Select priority
        </p>
        <Transition
          enter="ease-in-out duration-50"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Popover.Panel className="z-20 absolute mt-5 w-52 -translate-x-3/4 transform px-4">
            <PopOut
              onPriorityChanged={(acc) => {
                props.onPriorityChanged(acc);
                buttonRef.current?.click();
              }}
            />
          </Popover.Panel>
        </Transition>
      </Popover.Button>
    </Popover>
  );
}

const priorities = [
  TaskPriority.Low,
  TaskPriority.Medium,
  TaskPriority.High,
  TaskPriority.Urgent,
];

const PopOut = ({
  onPriorityChanged,
}: {
  onPriorityChanged: (priority: TaskPriority) => void;
}) => {
  // Adjust the style below based on the exact positioning you need

  return (
    <div className="border rounded-md ml-10">
      <Combobox
        onChange={(f: TaskPriority) => {
          onPriorityChanged(f);
        }}
      >
        <div className="">
          <div className="bg-white rounded-b-md">
            <Combobox.Options
              static
              className="max-h-80 scroll-py-2 divide-y  divide-gray-100 overflow-y-auto"
            >
              {priorities.map((p) => {
                return (
                  <Combobox.Option
                    key={p}
                    value={p}
                    className={({ active }) =>
                      classNames(
                        "cursor-pointer select-none last:rounded-b-md px-3 py-2",
                        active ? "bg-gray-200/70" : ""
                      )
                    }
                  >
                    {({ active }) => (
                      <div className="">
                        <div className="flex items-center gap-x-2">
                          <TaskPriorityIcon priority={p} />
                          <p className=" font-bold leading-tight  text-sm text-left">
                            {getTaskPriorityText(p)}
                          </p>
                        </div>
                      </div>
                    )}
                  </Combobox.Option>
                );
              })}
            </Combobox.Options>
          </div>
        </div>
      </Combobox>
    </div>
  );
};
