import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ClerkProvider } from "@clerk/clerk-react";
import { TokenProvider } from "./contexts/token";

import * as Sentry from "@sentry/react";

const CLERK_PUBLISHABLE_KEY =
  process.env.REACT_APP_PUBLIC_CLERK_PUBLISHABLE_KEY;

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [],
});

const queryClient = new QueryClient();

const render = () => {
  let persistor = persistStore(store);

  ReactDOM.render(
    <React.StrictMode>
      <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
          }}
        >
          <TokenProvider>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <PersistGate loading={null} persistor={persistor}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </PersistGate>
              </QueryClientProvider>
            </Provider>
          </TokenProvider>
        </PostHogProvider>
      </ClerkProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}
