import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import {
  DealAccessStatus,
  useActiveDealAccountQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { Card } from "../components/Card";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";
import Loading from "../components/Loading";

export function DealProtection(props: { children: React.ReactNode }) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const location = useLocation();

  const client = useGqlClient();
  const activeDealAccountQuery = useActiveDealAccountQuery(client, {
    id: activeDealId ? activeDealId : "",
  });

  if (
    location.pathname.startsWith("/home") ||
    location.pathname.startsWith("/firm") ||
    location.pathname.startsWith("/settings") ||
    location.pathname.startsWith("/create-deal") ||
    location.pathname.startsWith("/tasks")
  ) {
    return null;
  }

  if (!activeDealId) {
    return <>{props.children}</>;
  }

  if (activeDealAccountQuery.isPending) {
    return (
      <div className="flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (activeDealAccountQuery.error || !activeDealAccountQuery.data) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (
    activeDealAccountQuery.data.deal.activeDealAccount.dealFirmGroup &&
    activeDealAccountQuery.data.deal.activeDealAccount.dealFirmGroup
      .dealAccessStatus === DealAccessStatus.Granted
  ) {
    return <>{props.children}</>;
  }

  if (
    activeDealAccountQuery.data.deal.activeDealAccount.dealFirmGroup &&
    (activeDealAccountQuery.data.deal.activeDealAccount.dealFirmGroup
      .dealAccessStatus === DealAccessStatus.PendingManualApproval ||
      activeDealAccountQuery.data.deal.activeDealAccount.dealFirmGroup
        .dealAccessStatus === DealAccessStatus.Blocked ||
      activeDealAccountQuery.data.deal.activeDealAccount.dealFirmGroup
        .dealAccessStatus === DealAccessStatus.PendingNda)
  ) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <Card padding="m">
          <div className="flex items-center">
            <LockClosedIcon className="w-5 h-5 text-gray-700" />
            <p className="font-semibold mt-1 ml-1 text-gray-800">Deal locked</p>
          </div>
          <p className="text-gray-500 text-sm mt-2">
            The deal admin needs to grant you access to the deal
          </p>
        </Card>
      </div>
    );
  }

  return <>{props.children}</>;
}
