import { NavLink, useParams } from "react-router-dom";
import useGqlClient from "../../../hooks/useGqlClient";
import {
  useDdqlQuery,
  Question as GqlQuestion,
  DdqlStatus,
} from "../../../graphql/generated";
import { Button } from "../../../components/tailwind/Button";
import Loading from "../../../components/Loading";
import { H3, H4 } from "../../../components/Heading";
import { PageContent } from "../../../components/PageContent";
import { Card } from "../../../components/Card";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { StatusPill } from "../Group";
import { NewQuestionModal, Question } from "..";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

export function Ddql() {
  const { id } = useParams<{ id: string }>();

  const [openModal, setOpenModal] = useState<
    "" | "import_ddql" | "new_question"
  >("");

  const client = useGqlClient();
  const ddqlQuery = useDdqlQuery(client, {
    id,
  });

  if (ddqlQuery.error) {
    return (
      <div className="flex flex-col flex-1 justify-center items-center h-full w-full">
        <p className="font-semibold text-gray-600 text-sm">
          Something went wrong
        </p>
        <p className="text-gray-500/80 text-xs">
          Sorry about that. Try again or contact support.
        </p>
        <Button
          margin="m 0 0 0"
          variant="neutral"
          text="Try again"
          onClick={() => {
            ddqlQuery.refetch();
          }}
        />
      </div>
    );
  }

  if (ddqlQuery.isLoading || !ddqlQuery.data) {
    return <Loading />;
  }

  const questions = ddqlQuery.data.ddql.questions;

  return (
    <div className="flex-1 flex-col flex">
      <div className="bg-white sticky right-0 z-20 top-0 px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            <NavLink
              to={`/questions/group/${ddqlQuery.data.ddql.dealFirmGroup.id}`}
            >
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                {ddqlQuery.data.ddql.dealFirmGroup.name}
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            <H4>{ddqlQuery.data.ddql.name}</H4>
          </div>
          <div></div>
        </div>
      </div>
      <PageContent>
        <div className="flex justify-between items-center lg:w-1/2">
          <div>
            <H3>{ddqlQuery.data.ddql.name}</H3>
            <p className="text-xs text-gray-500/80">
              Created by {ddqlQuery.data.ddql.createdBy.name}{" "}
              {formatDistanceToNow(
                fromUnixTime(ddqlQuery.data.ddql.createdAt),
                {
                  addSuffix: true,
                }
              )}
            </p>
          </div>
          <StatusPill ddql={ddqlQuery.data.ddql} />
        </div>
        <div className="mt-5 lg:w-1/2">
          <div className="flex items-center justify-between">
            <H4>Questions</H4>
            {ddqlQuery.data.ddql.status === DdqlStatus.Initial ? (
              <button
                onClick={() => {
                  setOpenModal("new_question");
                }}
                className="text-indigo-500 text-sm font-semibold flex items-center gap-x-1 hover:text-indigo-600"
              >
                <PlusIcon className="w-4 h-4 " />
                Add question
              </button>
            ) : null}
          </div>
          {questions.length === 0 ? (
            <Card margin="s 0 0 0" padding="m">
              <div className="flex flex-col items-center">
                <p className="font-semibold text-gray-700 text-sm">
                  No questions yet
                </p>
                <p className="text-xs text-gray-500/80">
                  Add a question or import a DDQL file
                </p>
              </div>
            </Card>
          ) : null}
          <Card margin="s 0 0 0">
            {questions
              .sort((a, b) => {
                if (a.answer && !b.answer) {
                  return 1;
                }

                if (!a.answer && b.answer) {
                  return -1;
                }

                return 0;
              })
              .map((q, i) => {
                return (
                  <Question
                    isFirst={i === 0}
                    isLast={i === questions.length - 1}
                    question={q as GqlQuestion}
                    key={q.id}
                  />
                );
              })}
          </Card>
        </div>
      </PageContent>
      <NewQuestionModal
        open={openModal === "new_question"}
        onClose={() => setOpenModal("")}
        ddqlId={id}
      />
    </div>
  );
}
