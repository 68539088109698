import Loading from "../../../components/Loading";
import { Button } from "../../../components/tailwind/Button";
import {
  DataRoomFileQuery,
  DataRoomFileUploadStatus,
  FileType,
  useDataRoomFileVersionQuery,
  useFileVersionDownloadUrlQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import React, { useEffect } from "react";
import { PdfViewerDocumentId } from "../../../components/PdfViewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Spinner } from "../../../components/icons/Spinner";

interface FileViewProps {
  file: DataRoomFileQuery["dataRoomFile"];
  versionId: string;
  highlights?: string[];
}

export function FileViewWrapper(props: FileViewProps) {
  if (
    props.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (
    props.file.fileType === FileType.Pdf ||
    props.file.fileType === FileType.Docx ||
    props.file.fileType === FileType.Pptx
  ) {
    return <FileViewPspdfkit file={props.file} versionId={props.versionId} />;
  }

  return <FileViewContent file={props.file} versionId={props.versionId} />;
}

function FileViewPspdfkit(props: FileViewProps) {
  const client = useGqlClient();

  const dataRoomFileVersion = useDataRoomFileVersionQuery(client, {
    id: props.versionId,
  });

  if (
    dataRoomFileVersion.error ||
    (dataRoomFileVersion.data &&
      !dataRoomFileVersion.data.dataRoomFileVersion.pspdfkitDocumentId)
  ) {
    return <FileViewContent file={props.file} versionId={props.versionId} />;
  }

  if (dataRoomFileVersion.isLoading || !dataRoomFileVersion.data) {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  const drfv = dataRoomFileVersion.data.dataRoomFileVersion;

  return (
    <div style={{ height: 800, width: "100%" }}>
      <PdfViewerDocumentId
        documentId={drfv.pspdfkitDocumentId}
        token={drfv.pspdfkitToken}
        fileType={props.file.fileType}
      />
    </div>
  );
}

function FileViewContent(props: FileViewProps) {
  const client = useGqlClient();
  const [url, setUrl] = React.useState<string>("");
  const [fileDownloadError, setFileDownloadError] = React.useState<string>("");

  const fileDownloadUrl = useFileVersionDownloadUrlQuery(
    client,
    {
      id: props.versionId,
    },
    {
      refetchOnWindowFocus(query) {
        if (query.isStaleByTime(1000 * 60 * 5)) {
          return true;
        }

        return false;
      },
    }
  );

  useEffect(() => {
    if (
      fileDownloadUrl.data &&
      fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl !== url
    ) {
      setFileDownloadError("");
      setUrl(fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl);
      return;
    }

    if (fileDownloadUrl.error) {
      setFileDownloadError("Unable to load file");
      return;
    }
  }, [
    fileDownloadUrl.data,
    fileDownloadUrl.isRefetching,
    fileDownloadUrl.isPending,
    fileDownloadUrl.error,
    url,
  ]);

  if (
    props.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (url === "") {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  if (fileDownloadError) {
    return (
      <div className="flex items-center justify-center">
        <p>Failed to load file</p>
        <Button
          variant="neutral"
          text="Retry"
          isLoading={fileDownloadUrl.isPending || fileDownloadUrl.isRefetching}
          loadingText="Retrying..."
          onClick={() => {
            fileDownloadUrl.refetch();
          }}
        />
      </div>
    );
  }

  return (
    <DocViewer
      key={url}
      config={{
        pdfVerticalScrollByDefault: true,
        header: {
          disableFileName: true,
          disableHeader: true,
        },
      }}
      prefetchMethod="GET"
      style={{ height: 800, width: "100%", overflowY: "scroll" }}
      documents={[
        {
          uri: url,
        },
      ]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}
