import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { H3 } from "../../../components/Heading";
import { classNames } from "../../../utils/cn";
import { PageContent } from "../../../components/PageContent";
import { ErrorCheckingSettings } from "./error_checking";
import {
  DealRoleRestricted,
  adminRoles,
  buyerRoles,
  sellerRoles,
} from "../../../components/DealRoleRestricted";
import { DealRole } from "../../../graphql/generated";
import { DealExports } from "./exports";

const tabs = [
  {
    name: "Exports",
    link: "/deal/settings/exports",
  },
];

export function DealSettings() {
  return (
    <div className="flex-1">
      <DealRoleRestricted
        sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
        buyerRunDealRoles={[...buyerRoles, ...adminRoles]}
      >
        <div className="bg-white z-20 px-8 pt-6 w-full shadow-sm border-b border-gray-300/80">
          <div className="flex items-center justify-between">
            <div>
              <H3>Deal Settings</H3>
            </div>
          </div>
          <div>
            <div className="block">
              <div className="border-b border-gray-200">
                <nav className=" flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <NavLink
                      to={tab.link}
                      key={tab.link}
                      exact={tab.link === "/deal/settings"}
                      className={(isActive) =>
                        classNames(
                          isActive
                            ? ""
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                        )
                      }
                      activeClassName={classNames(
                        "border-indigo-500 text-indigo-600"
                      )}
                    >
                      {tab.name}
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </DealRoleRestricted>
      <DealRoleRestricted
        sellerRunDealRoles={[DealRole.BuyerAdmin]}
        buyerRunDealRoles={[DealRole.SellerAdmin]}
      >
        <div className="bg-white z-20 px-8 py-6 w-full shadow-sm border-b border-gray-300/80">
          <div className="flex items-center justify-between">
            <div>
              <H3>Access</H3>
            </div>
          </div>
        </div>
      </DealRoleRestricted>
      <PageContent>
        <Switch>
          <Route path="/deal/settings" exact>
            <Redirect to="/deal/settings/exports" />
          </Route>
          <Route path="/deal/settings/error-checking">
            <ErrorCheckingSettings />
          </Route>
          <Route path="/deal/settings/exports">
            <DealExports />
          </Route>
        </Switch>
      </PageContent>
    </div>
  );
}
