import { NavLink, useHistory, useParams } from "react-router-dom";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Combobox, Popover, Transition } from "@headlessui/react";
import useGqlClient from "../../../hooks/useGqlClient";
import {
  DataRoomFileQuery,
  FileVersionTextChange,
  TextDiffType,
  useAllFoldersQuery,
  useDataRoomFileQuery,
} from "../../../graphql/generated";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import Loading, { Loader } from "../../../components/Loading";
import { FileIcon } from "../../../components/FileIcon";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { ActivityItem } from "../../../components/activity/ActivityItem";
import { actions } from "../../../store/deep-dive/slice";
import { deepDiveSelectors } from "../../../store/deep-dive/selector";
import { AppState } from "../../../store";
import { FileViewWrapper } from "./FileView";
import { Pulse } from "../../../components/Pulse";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { Avatar } from "../../../components/account/Avatar";
import Dropdown, { Option } from "../../../components/tailwind/Dropdown";
import { TextDiffs } from "../../../components/tailwind/TextDiffs";
import { classNames } from "../../../utils/cn";
import { DataRoomFileTopic, useWebSocket } from "../../../contexts/websockets";

export function DocumentDeepDive() {
  const { fileId } = useParams<{ fileId: string }>();
  const history = useHistory();

  const { subscribeToTopic, unsubscribeFromTopic } = useWebSocket();

  useEffect(() => {
    if (!fileId) {
      return;
    }

    subscribeToTopic(DataRoomFileTopic(fileId));
    return () => {
      unsubscribeFromTopic(DataRoomFileTopic(fileId));
    };
  }, [fileId]);

  return (
    <div className="flex-1 flex flex-col ">
      <div className="bg-white sticky right-0 z-20 top-0 px-8 py-4 w-full border-b border-gray-300/80">
        <div className="flex  items-center justify-between">
          <div className="flex-1 flex items-center gap-x-2">
            <NavLink to="/toolkit">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Toolkit
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            <h4 className={`truncate text-base font-bold text-gray-700`}>
              Document deep dive
            </h4>
            <p className="font-light text-gray-400">/</p>
            <SelectFileDropdown
              selectedFileId={fileId}
              onSelectFile={(id) => {
                history.push(`/toolkit/deep-dive/${id}`);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col h-full w-full ">
        <div className="lg:hidden flex  items-center justify-center flex-1">
          <p className="font-semibold text-gray-600">Not supported on mobile</p>
        </div>
        <div className="hidden lg:flex flex-1 ">
          <div className="flex flex-1 relative ">
            <DeepDiveContent fileId={fileId} />
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectedFile(props: { fileId: string }) {
  const client = useGqlClient();
  const dataRoomFile = useDataRoomFileQuery(client, {
    id: props.fileId,
  });

  if (dataRoomFile.isPending || !dataRoomFile.data) {
    return (
      <div>
        <Loader width={30} height={6} />
      </div>
    );
  }

  if (dataRoomFile.error) {
    return (
      <p className="font-semibold text-gray-700 text-sm">Failed to load file</p>
    );
  }

  return (
    <div className="flex items-center gap-x-2">
      <FileIcon fileType={dataRoomFile.data.dataRoomFile.fileType} />
      <div className="flex items-center gap-x-2 justify-between">
        <div>
          <p className="text-gray-500 text-xs font-semibold truncate">
            {dataRoomFile.data.dataRoomFile.name}
          </p>
        </div>
      </div>
    </div>
  );
}

function SelectFileDropdown(props: {
  onSelectFile: (id: string) => void;
  selectedFileId?: string;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const allFoldersQuery = useAllFoldersQuery(client, {
    dealId: activeDealId ?? "",
  });

  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState<string>("");

  if (allFoldersQuery.error) {
    return (
      <p className="font-semibold text-gray-700 text-sm">
        Something went wrong
      </p>
    );
  }

  if (allFoldersQuery.isPending || !allFoldersQuery.data) {
    return (
      <div>
        <Loader width={30} height={6} />
      </div>
    );
  }

  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="relative cursor-pointer  hover:border-gray-400  hover:shadow-sm flex items-center rounded-md border px-2 py-1"
      >
        {props.selectedFileId ? (
          <SelectedFile fileId={props.selectedFileId} />
        ) : (
          <p className="text-sm text-gray-600">No selected file</p>
        )}

        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="absolute z-30 mt-20 w-96 -translate-x-60 transform ">
            <Combobox
              onChange={(v: string) => {
                if (v) {
                  history.push(`/toolkit/deep-dive/${v}`);
                }
              }}
            >
              <div className="relative w-full cursor-default overflow-hidden rounded-t-md bg-white text-left  sm:text-sm">
                <Combobox.Input
                  placeholder="Search files..."
                  autoFocus
                  className="w-full focus:border-gray-200 border-gray-200  rounded-t-md ring-0 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                  onChange={(event) => {
                    setSearchTerm(event.currentTarget.value);
                  }}
                />
              </div>
              <Combobox.Options
                static
                className=" absolute right-0 w-full z-10 rounded-b-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  {allFoldersQuery.data.deal.dataRoom.allFolders.map(
                    (folder) => {
                      return (
                        <>
                          {folder.files
                            .filter((file) =>
                              searchTerm.length > 2
                                ? file.name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                : true
                            )
                            .map((file) => {
                              return (
                                <Combobox.Option key={file.id} value={file.id}>
                                  <div className="text-left px-1.5 hover:bg-gray-50">
                                    <button className="py-2">
                                      <p className="text-left text-xs text-gray-500">
                                        {folder.parentFolders.length > 0
                                          ? `${folder.parentFolders
                                              .map((f) =>
                                                f.name ? f.name : "Home"
                                              )
                                              .reverse()
                                              .join(" / ")}`
                                          : "Home /"}
                                      </p>
                                      <div className="flex items-center">
                                        <FileIcon fileType={file.fileType} />
                                        <p className="ml-1 font-bold leading-tight mt-0.5 text-sm text-left">
                                          {file.name}
                                        </p>
                                      </div>
                                    </button>
                                  </div>
                                </Combobox.Option>
                              );
                            })}
                        </>
                      );
                    }
                  )}
                </div>
              </Combobox.Options>
            </Combobox>
          </Popover.Panel>
        </Transition>
      </Popover.Button>
    </Popover>
  );
}

function DeepDiveContent(props: { fileId: string }) {
  const client = useGqlClient();
  const dataRoomFile = useDataRoomFileQuery(client, {
    id: props.fileId,
  });

  const selectedVersionId = useSelector((state: AppState) =>
    deepDiveSelectors.selectedFileVersionId(state, props.fileId)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataRoomFile.data) {
      return;
    }

    if (
      !selectedVersionId &&
      dataRoomFile.data.dataRoomFile.versions.length > 0
    ) {
      dispatch(
        actions.setSelectedFileVersionId({
          fileId: props.fileId,
          versionId: dataRoomFile.data.dataRoomFile.versions[0].id,
        })
      );
    }
  }, [dataRoomFile.data]);

  return (
    <div className="flex-1">
      <PanelGroup direction="horizontal">
        <Panel defaultSize={50}>
          <PanelGroup direction="vertical">
            <Panel defaultSize={50}>
              <Summary query={dataRoomFile} fileId={props.fileId} />
            </Panel>
            <PanelResizeHandle className="h-0.5 bg-gray-200 hover:bg-gray-300" />
            <Panel defaultSize={50}>
              <VersionHistory query={dataRoomFile} fileId={props.fileId} />
            </Panel>
          </PanelGroup>
        </Panel>
        <PanelResizeHandle className="w-0.5 bg-gray-200 hover:bg-gray-300" />
        <Panel defaultSize={50}>
          <DocViewer query={dataRoomFile} fileId={props.fileId} />
        </Panel>
      </PanelGroup>
    </div>
  );
}

function DocViewer(props: {
  query: UseQueryResult<DataRoomFileQuery, unknown>;
  fileId?: string;
}) {
  const selectedVersionId = useSelector((state: AppState) =>
    deepDiveSelectors.selectedFileVersionId(
      state,
      props.fileId ? props.fileId : ""
    )
  );

  const { query, fileId } = props;
  if (!fileId) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-500">No file selected</p>
      </div>
    );
  }

  if (query.error) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-500">
          Failed to load file
        </p>
      </div>
    );
  }

  if (query.isPending || !query.data) {
    return <Loading />;
  }

  return (
    <div>
      {selectedVersionId ? (
        <div>
          <CurrentVersionHeader
            file={query.data.dataRoomFile}
            versionId={selectedVersionId}
          />
          <FileViewWrapper
            file={query.data.dataRoomFile}
            versionId={selectedVersionId}
            highlights={
              query.data.dataRoomFile.versions.find(
                (v) => v.id === selectedVersionId
              )
                ? query.data.dataRoomFile.versions
                    .find((v) => v.id === selectedVersionId)
                    ?.changes.textChanges.flatMap((tc) =>
                      tc.diffs.flatMap((d) =>
                        d.type === TextDiffType.Insert ? d.text : ""
                      )
                    )
                    .filter((v) => v !== "")
                : []
            }
          />
        </div>
      ) : (
        <div className="flex w-full h-full justify-center items-center">
          <p className="font-semibold text-sm text-gray-500">
            No version selected
          </p>
        </div>
      )}
    </div>
  );
}

function CurrentVersionHeader(props: {
  file: DataRoomFileQuery["dataRoomFile"];
  versionId: string;
}) {
  const version = props.file.versions.find((v) => v.id === props.versionId);
  if (!version) {
    return null;
  }

  return (
    <div className="bg-white shadow-sm p-2  border-b border-gray-200 z-10 flex items-center justify-between">
      <div className="flex items-center gap-x-2">
        <Pulse
          color={
            props.file.latestVersion.id === props.versionId ? "green" : "gray"
          }
        />
        <div>
          <p className="font-semibold text-sm text-gray-700">
            Version {version.versionNumber}
          </p>
          <p className="text-xs text-gray-500/80">
            Uploaded{" "}
            {formatDistanceToNowStrict(fromUnixTime(version.createdAt), {
              addSuffix: true,
            })}
          </p>
        </div>
      </div>
      <Avatar account={version.createdBy} />
    </div>
  );
}

function Summary(props: {
  query: UseQueryResult<DataRoomFileQuery, unknown>;
  fileId?: string;
}) {
  const { query, fileId } = props;
  if (!fileId) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-500">No file selected</p>
      </div>
    );
  }

  if (query.error) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-500">
          Failed to load file
        </p>
      </div>
    );
  }

  if (query.isPending || !query.data) {
    return <Loading />;
  }

  return (
    <div className="h-full">
      <div className="sticky top-0 shadow-sm w-full bg-white backdrop-blur border-b border-gray-200 p-2 py-4">
        <p className="font-semibold text-sm text-gray-700">Document summary</p>
      </div>

      <div className="mt-3 p-3">
        <div className="p-3 overflow-y-scroll max-h-80 bg-white border border-gray-200 rounded-md">
          <p className="text-xs text-gray-600">
            {query.data.dataRoomFile.documentSummary.summary}
          </p>

          <p className="text-xs font-semibold text-gray-700 mt-5">Key points</p>
          {query.data.dataRoomFile.documentSummary.keyPoints.map((kp, i) => {
            return (
              <div key={i} className="my-3">
                <p className="text-xs font-semibold text-gray-600">
                  {kp.title}
                </p>
                <p className="text-xs text-gray-500">{kp.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function VersionHistory(props: {
  query: UseQueryResult<DataRoomFileQuery, unknown>;
  fileId?: string;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { query, fileId } = props;
  if (!fileId) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-500">No file selected</p>
      </div>
    );
  }

  if (query.error) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-500">
          Failed to load file
        </p>
      </div>
    );
  }

  if (query.isPending || !query.data) {
    return <Loading />;
  }

  const versions = query.data.dataRoomFile.versions;
  const versionItems: VersionHistoryItem[] = [];
  versions.forEach((v, i) => {
    versionItems.push({
      version: v,
      activity: v.activity,
    });
  });

  return (
    <div className="overflow-y-scroll h-full relative">
      <StickyHeaderList
        fileId={query.data.dataRoomFile.id}
        items={versionItems}
        onHeaderChange={(versionId) => {}}
      />
    </div>
  );
}

interface VersionHistoryItem {
  version: DataRoomFileQuery["dataRoomFile"]["versions"][0];
  activity: DataRoomFileQuery["dataRoomFile"]["activity"];
}

function StickyHeaderList(props: {
  fileId: string;
  items: VersionHistoryItem[];
  onHeaderChange: (versionId: string) => void;
}) {
  const headersRef = useRef<Array<HTMLParagraphElement | null>>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      if (!wrapperRef.current) return;

      const wrapperTop = wrapperRef.current.getBoundingClientRect().top;

      headersRef.current.forEach((header) => {
        if (header) {
          const headerTop = header.getBoundingClientRect().top;
          if (headerTop <= wrapperTop + 10 && headerTop > wrapperTop - 10) {
            dispatch(
              actions.setSelectedFileVersionId({
                fileId: props.fileId,
                versionId: header.id,
              })
            );
          }
        }
      });
    };

    const wrapperElement = wrapperRef.current;
    if (wrapperElement) {
      wrapperElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (wrapperElement) {
        wrapperElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [props.items, props.onHeaderChange, wrapperRef]);

  return (
    <div ref={wrapperRef} className="h-full overflow-y-auto">
      {props.items.map((item, index) => (
        <div
          key={index}
          className={classNames(
            `relative ${
              index === props.items.length - 1 ? "min-h-full" : "min-h-96"
            }`,
            index > 0 ? "mt-6 border-t border-gray-200" : ""
          )}
        >
          <div
            id={item.version.id}
            ref={(el) => {
              if (!el) {
                return;
              }

              headersRef.current[index] = el;
            }}
            className="w-full sticky z-10 top-0 bg-white/60  backdrop-blur shadow-md p-2"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <Pulse color={index === 0 ? "green" : "gray"} />
                <div>
                  <p className="font-semibold text-sm text-gray-700">
                    Version {item.version.versionNumber}
                  </p>
                  <p className="text-xs text-gray-500/80">
                    Uploaded{" "}
                    {formatDistanceToNowStrict(
                      fromUnixTime(item.version.createdAt),
                      { addSuffix: true }
                    )}
                  </p>
                </div>
              </div>
              <Avatar account={item.version.createdBy} />
            </div>
          </div>
          <div className="px-3">
            {item.version.changes.summary.length > 0 ? (
              <div className="mt-3">
                <p className="font-semibold text-sm text-gray-600">
                  Version summary
                </p>
                <div className="mt-2 bg-white rounded-md p-2 border border-gray-200">
                  <p className="text-xs text-gray-500">
                    {item.version.changes.summary}
                  </p>
                </div>
              </div>
            ) : null}
            {item.version.changes.textChanges.length > 0 ? (
              <div className="mt-3">
                <p className="font-semibold text-sm text-gray-600">
                  Version changes
                </p>
                <div className="">
                  {item.version.changes.textChanges.map((tc) => {
                    return (
                      <div key={tc.section} className="mt-2">
                        <VersionTextChanges textChange={tc} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className="mt-3">
              <p className="font-semibold text-sm text-gray-600">
                Version activity
              </p>
            </div>
            <ul>
              {item.activity.map((activityItem, contentIndex) => (
                <li key={contentIndex} className="p-2">
                  <ActivityItem
                    activity={activityItem}
                    isFirst={contentIndex === 0}
                    isLast={contentIndex === item.activity.length - 1}
                    mode="verbose"
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}

const versionTextChangeOptions: Option[] = [
  {
    label: "Compare",
    value: "compare",
  },
  {
    label: "New",
    value: "new",
  },
  {
    label: "Previous",
    value: "previous",
  },
];

function VersionTextChanges(props: { textChange: FileVersionTextChange }) {
  const [selectedOption, setSelectedOption] = useState<Option>(
    versionTextChangeOptions[0]
  );
  return (
    <div>
      <div className="bg-white rounded-md p-2 border border-gray-200">
        <div className="flex items-center justify-between mb-1">
          <p className="font-semibold text-gray-600 text-xs">
            {props.textChange.section}
          </p>
          <Dropdown
            variant="text"
            size="xs"
            options={versionTextChangeOptions}
            selectedOption={selectedOption}
            onSelect={(o) => {
              setSelectedOption(o);
            }}
          />
        </div>
        {selectedOption.value === "new" ? (
          <p className="text-xs  text-gray-500">{props.textChange.new}</p>
        ) : null}
        {selectedOption.value === "previous" ? (
          <p className="text-xs  text-gray-500">{props.textChange.previous}</p>
        ) : null}
        {selectedOption.value === "compare" ? (
          <TextDiffs diffs={props.textChange.diffs} />
        ) : null}
      </div>
    </div>
  );
}
