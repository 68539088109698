import { useSelector } from "react-redux";
import { H3 } from "../../components/Heading";
import { authSelectors } from "../../store/auth/selector";
import useGqlClient from "../../hooks/useGqlClient";
import {
  DataRoomFileErrorStatus,
  useDataRoomFileErrorsQuery,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { Link } from "react-router-dom";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { Pills } from "../../components/Pills";
import { FilePill } from "../../components/FilePill";
import { classNames } from "../../utils/cn";
import { useState } from "react";
import { InboxIcon } from "@heroicons/react/20/solid";

export function Errors() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const tabs = ["Open", "Resolved", "Ignored"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white z-20 sticky top-0 px-8 pt-5 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Error checking</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={selectedTab}
            >
              {tabs.map((tab) => (
                <option key={tab}>{tab}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className=" flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    onClick={() => {
                      setSelectedTab(tab);
                    }}
                    key={tab}
                    className={classNames(
                      tab === selectedTab
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                    )}
                    aria-current={tab === selectedTab ? "page" : undefined}
                  >
                    {tab}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 px-8 py-4 flex flex-col">
        {activeDealId ? (
          <ErrorsContent
            activeDealId={activeDealId}
            selectedTab={selectedTab}
          />
        ) : null}
      </div>
    </div>
  );
}

const tabStatusColor: { [key: string]: string } = {
  Open: "bg-orange-400",
  Resolved: "bg-green-400",
  Ignored: "bg-gray-400",
};

const tabStatusMap: { [key: string]: DataRoomFileErrorStatus } = {
  Open: DataRoomFileErrorStatus.Pending,
  Resolved: DataRoomFileErrorStatus.Resolved,
  Ignored: DataRoomFileErrorStatus.Dismissed,
};

function ErrorsContent(props: { activeDealId: string; selectedTab: string }) {
  const client = useGqlClient();

  const errorsQuery = useDataRoomFileErrorsQuery(client, {
    dealId: props.activeDealId,
  });

  const selectedTab = props.selectedTab;

  if (errorsQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (errorsQuery.isPending || !errorsQuery.data) {
    return <Loading />;
  }

  if (errorsQuery.data.dataRoomFileErrors.length === 0) {
    return (
      <div className="flex-1 flex justify-center items-center flex-col">
        <p className="text-sm font-semibold text-gray-700">No errors</p>
        <p className="text-xs text-gray-500/80">
          Any errors detected across the Data Room will appear here
        </p>
      </div>
    );
  }

  return (
    <div className="w-full xl:w-1/2">
      <Card margin="l 0 0 0">
        {errorsQuery.data.dataRoomFileErrors.filter(
          (fileError) => fileError.status === tabStatusMap[selectedTab]
        ).length === 0 ? (
          <div className="flex items-center justify-center flex-col p-4">
            <InboxIcon className="h-10 w-10 text-gray-300 mx-auto" />
            <p className="font-semibold text-gray-800">
              No {selectedTab} errors
            </p>
            {selectedTab === "Open" ? (
              <p className="text-gray-500/80 text-sm text-center">
                Any errors detected across the Data Room will appear here
              </p>
            ) : null}
          </div>
        ) : null}
        {errorsQuery.data.dataRoomFileErrors
          .filter((fileError) => fileError.status === tabStatusMap[selectedTab])
          .map((fileError, i) => {
            return (
              <div key={fileError.id}>
                <Link to={`/errors/${fileError.id}`}>
                  <div
                    className={`flex p-4 ${i === 0 ? "rounded-t-md" : ""} ${
                      i === errorsQuery.data.dataRoomFileErrors.length - 1
                        ? "rounded-b-md"
                        : ""
                    } hover:bg-gray-200 items-center cursor-pointer`}
                  >
                    <div
                      className={`${tabStatusColor[selectedTab]} rounded-full w-2 h-2 mr-4`}
                    ></div>
                    <div className="flex-1">
                      <p className="text-gray-600 text-sm font-bold">
                        {fileError.key}
                      </p>
                      <p className="text-xs leading-tight text-gray-500/80">
                        Created{" "}
                        {formatDistanceToNowStrict(
                          fromUnixTime(fileError.createdAt),
                          {
                            addSuffix: true,
                          }
                        )}{" "}
                      </p>
                    </div>
                    <Pills>
                      <FilePill
                        id={fileError.files[0].file.id}
                        name={fileError.files[0].file.name}
                        type={fileError.files[0].file.fileType}
                        showDetailsCard={false}
                      />
                      <div className="bg-white shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
                        <p className="text-xs text-gray-800">
                          + {fileError.files.length - 1}
                        </p>
                      </div>
                    </Pills>
                    {/* <div className="flex gap-x-4">
                    <div className="flex -space-x-0.5">
                      <img
                        src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
                        alt=""
                        className="relative ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                      />
                      <img
                        src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                        alt=""
                        className="relative ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                      />

                      <div className="relative w-6 h-6 ring-2 ring-white flex-none bg-rose-400 rounded-full items-center justify-center flex">
                        <p className="text-sm text-white">R</p>
                      </div>
                    </div>
                    <div className="flex gap-x-2">
                      <ChatBubbleLeftIcon className="w-6 h-6 text-gray-600" />
                      <p className="text-gray-600">1</p>
                    </div>
                  </div> */}
                  </div>
                </Link>
              </div>
            );
          })}
      </Card>
    </div>
  );
}
